(function () {
    'use strict';

    angular
        .module('app.reports.expiringDocuments')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.expiringDocuments',
                config: {
                    url: '/expiringDocuments',
                    templateUrl: 'app/reports/expiringDocuments/expiringDocuments.html',
                    controller: 'ExpiringDocumentsController',
                    controllerAs: 'vm',
                    title: 'ExpiringDocuments',
                    settings: {
                        navId: 2154,
                        level: 2,
                        order: 15,
                        orderTitle_hu: 'Hamarosan lejáró dokumentumok, igazolások',
                        orderTitle_en: 'Expiring documents and certificates',
                        parentId: 4,
                        content: 'ExpiringDocuments',
                        activatorPermission: ['"LEJAROMEGT"']
                    }
                }
            }
        ];
    }
})();
