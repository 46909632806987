(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage2')
        .controller('AnnualSeasonPlanReportPage2Controller', AnnualSeasonPlanReportPage2Controller);

    function AnnualSeasonPlanReportPage2Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $state, $timeout) {
        var vm = this; $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlanReport');
        vm.columns = [];
        vm.egyeni = false;
        vm.editable = true;
        vm.disable = false;
        vm.annualSeasonPlanReportId = 0;

        vm.head = {};
        vm.data = [];
        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];
        // functions
        vm.goToNewAthlete = goToNewAthlete;
        vm.refreshData = refreshData;
        vm.goToNextPage = goToNextPage;
        vm.goToPreviousPage = goToPreviousPage;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;

        initGrid();

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            vm.jumpedPage = '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast');
            vm.defaultJumpPage = '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast');
            $q.all([paramHelper.getParams([], ['AnnualSeasonPlanReport.Id']),
                authenticationService.getRight('EDZO'),
                authenticationService.getRight('ADMIN'),
                authenticationService.getRight('SZAKOSZTVEZ'),
                authenticationService.getRight('TECHIGAZGATO'),
                authenticationService.getRight('EVVERSTALAIR1'),
                authenticationService.getRight('EVVERSTALAIR2'),
                authenticationService.getRight('UJEVVERST'),
                authenticationService.getRight('MEGTSPORTLIST'),
                authenticationService.getRight('MEGTEVVERST')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.EVVERSTALAIR1 = results[5];
                    vm.EVVERSTALAIR2 = results[6];
                    vm.UJEVVERST = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTEVVERST = results[9];
                    if (!vm.MEGTEVVERST) {
                        log.permissionError(true);
                        return;
                    }
                    if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                        vm.annualSeasonPlanReportId = results[0]['AnnualSeasonPlanReport.Id'];
                        paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                        dataservice.annualSeasonPlanReportSelectById(vm.annualSeasonPlanReportId)
                            .then(function (data) {
                                vm.head = data;
                                if (data.EdzoLezarta) {
                                    vm.editable = false;
                                    vm.disable = true;
                                } else {
                                    vm.editable = true;
                                    vm.disable = false;
                                }
                                viewAnnualSeasonPlanReport(data);
                            });
                    } else {
                        $rootScope.back();
                    }
                });
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '2 ') {
                return;
            }

            if (vm.editable) {
                grid.editModule.endEdit();
                dataservice.saveAnnualSeasonPlanMembersPast(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }

            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                    case '1 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                        break;
                    case '2 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                        break;
                    case '3 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                        break;
                    case '4 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                        break;
                    case '5 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                        break;
                    case '6 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                        break;
                }
            }, 100);
        }

        function viewAnnualSeasonPlanReport() {
            dataservice.annualSeasonPlanReportMembersPastSelect(vm.annualSeasonPlanReportId)
                .then(function (data) {
                    vm.data = data.itemsList;
                    grid.dataSource = vm.data;
                    if (vm.editable && data.itemsList.length === 0) {
                        refreshData();
                    }
                });
        }

        function initGrid() {
            vm.columns = [];
            vm.columns = [
                {
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Sorszam',
                    headerText: gettextCatalog.getString('RowNumber'),
                    textAlign: 'center',
                    width: 80,
                    allowEditing: false
                },
                {
                    field: 'SportoloNeve',
                    headerText: gettextCatalog.getString('AtlethesName'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false,
                    template: '#anseplrepa2templateDataColumn1'
                },
                {
                    field: 'VersSportKezd',
                    headerText: gettextCatalog.getString('BeginningOfCompetitiveSports'),
                    textAlign: 'center',
                    width: 125,
                    allowEditing: false
                },
                {
                    field: 'EddigiEdzoi',
                    headerText: gettextCatalog.getString('HisCoachesUntilNow'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                },
                {
                    field: 'EddigiSportEgyesuletei',
                    headerText: gettextCatalog.getString('HisTeamsUntilNow'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                }
            ];
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                swal({
                    title: gettextCatalog.getString('AreYouSureGo'),
                    text: gettextCatalog.getString('AreYouSureYouWantToGoToAthlete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: gettextCatalog.getString('GoToAthlete')
                }).then(function (isConfirmed) {
                    if (isConfirmed.value) {
                        paramHelper.setParams([
                            {'athleteModify.sportoloId': Id}
                        ]);
                        $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                    }
                });
            } else {
                log.permissionError();
            }
        }

        function refreshData() {
            dataservice.annualSeasonPlanReportMembersPastDelete(vm.annualSeasonPlanReportId)
                .then(function () {
                    dataservice.annualSeasonPlanReportMembersPastRefresh(vm.annualSeasonPlanReportId,
                        vm.head.IdSzakosztaly, vm.head.IdKorosztaly, vm.head.IdEdzo)
                        .then(function (args) {
                            vm.data = [];
                            vm.data = args.itemsList;
                            grid.dataSource = vm.data;
                            log.successMsg(gettextCatalog.getString('RefreshComplete'));
                        });
                });
        }

        function goToNextPage() {
            if (vm.editable) {
                dataservice.saveAnnualSeasonPlanMembersPast(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
            }, 100);
        }

        function goToPreviousPage() {
            if (vm.editable) {
                dataservice.saveAnnualSeasonPlanMembersPast(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{
                    'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
                }]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
            }, 100);
        }
    }
})();
