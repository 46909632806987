(function () {
    'use strict';

    angular
        .module('app.business.annualEducationalFeeBySection')
        .controller('AnnualEducationalFeeBySectionController', AnnualEducationalFeeBySectionController);

    function AnnualEducationalFeeBySectionController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualEducationalFeeBySection');

        // Create variables
        vm.yearList = dataservice.range(2000, new Date().getFullYear());
        vm.year = new Date().getFullYear();

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'SzakosztalyNeve',
                    footerTemplate: gettextCatalog.getString('Sum')
                },
                {
                    type: 'Sum',
                    field: 'Elvart',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'Tenyleges',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E1',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T1',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E2',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T2',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E3',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T3',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E4',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T4',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E5',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T5',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E6',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T6',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E7',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T7',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E8',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T8',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E9',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T9',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E10',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E11',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E11',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T11',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'E12',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                },
                {
                    type: 'Sum',
                    field: 'T12',
                    footerTemplate: '${Sum}',
                    format: 'N0'
                }
            ]
        }];
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 140, headerTextAlign: 'center', textAlign: 'center'},
            {
                field: 'Elvart',
                headerText: gettextCatalog.getString('ElvartOssz'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 140,
                format: 'N0'
            },
            {
                field: 'Tenyleges',
                headerText: gettextCatalog.getString('TenylegesOssz'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 140,
                format: 'N0'
            },
            {
                field: 'E1',
                headerText: gettextCatalog.getString('January') + ' E1',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T1',
                headerText: gettextCatalog.getString('January') + ' T1',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E2',
                headerText: gettextCatalog.getString('February') + ' E2',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T2',
                headerText: gettextCatalog.getString('February') + ' T2',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E3',
                headerText: gettextCatalog.getString('March') + ' E3',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T3',
                headerText: gettextCatalog.getString('March') + ' T3',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E4',
                headerText: gettextCatalog.getString('April') + ' E4',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T4',
                headerText: gettextCatalog.getString('April') + ' T4',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E5',
                headerText: gettextCatalog.getString('May') + ' E5',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T5',
                headerText: gettextCatalog.getString('May') + ' T5',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E6',
                headerText: gettextCatalog.getString('June') + ' E6',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T6',
                headerText: gettextCatalog.getString('June') + ' T6',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E7',
                headerText: gettextCatalog.getString('July') + ' E7',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T7',
                headerText: gettextCatalog.getString('July') + ' T7',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E8',
                headerText: gettextCatalog.getString('August') + ' E8',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T8',
                headerText: gettextCatalog.getString('August') + ' T8',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E9',
                headerText: gettextCatalog.getString('September') + ' E9',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T9',
                headerText: gettextCatalog.getString('September') + ' T9',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E10',
                headerText: gettextCatalog.getString('October') + ' E10',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T10',
                headerText: gettextCatalog.getString('October') + ' T10',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E11',
                headerText: gettextCatalog.getString('November') + ' E11',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T11',
                headerText: gettextCatalog.getString('November') + ' T11',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'E12',
                headerText: gettextCatalog.getString('December') + ' E12',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            },
            {
                field: 'T12',
                headerText: gettextCatalog.getString('December') + ' T12',
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 115,
                format: 'N0'
            }
        ];

        var grid = new ej2.grids.Grid({
            columns: vm.columns,
            dataSource: [],
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            toolbar: $rootScope.toolbarItemsWithSearch,
            pageSettings: $rootScope.pageSettings,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'searching' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            console.log('args', args);
            console.log('grid', grid);
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.getDatas = getDatas;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('OKTOSSZSZAKO')];
            $q.all(promises).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getDatas();
                }
            });
        }

        function getDatas() {
            dataservice.getAnnualEducationalFeeBySection(vm.year).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }
    }
})();
