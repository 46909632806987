(function () {
    'use strict';

    angular
        .module('app.business.modifyFees')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.modifyFees',
                config: {
                    url: '/modifyFees',
                    templateUrl: 'app/business/modifyFees/modifyFees.html',
                    controller: 'modifyFeesController',
                    controllerAs: 'vm',
                    title: 'ModifyFees',
                    settings: {
                        navId: 213,
                        level: 2,
                        order: 1,
                        orderTitle_hu: 'Díjak beállítása',
                        orderTitle_en: 'Set Fees',
                        parentId: 3,
                        content: 'ModifyFees',
                        activatorPermission: ['"MEGTTAGDBEALL"']
                    }
                }
            }
        ];
    }
})();
