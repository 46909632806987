(function () {
    'use strict';

    angular
        .module('app.business.annualEducationalFeeByCoach')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.annualEducationalFeeByCoach',
                config: {
                    url: '/annualEducationalFeeByCoach',
                    templateUrl: 'app/business/annualEducationalFeeByCoach/annualEducationalFeeByCoach.html',
                    controller: 'AnnualEducationalFeeByCoachController',
                    controllerAs: 'vm',
                    title: 'AnnualEducationalFeeByCoach',
                    settings: {
                        navId: 2153,
                        level: 2,
                        order: 15,
                        orderTitle_hu: 'Oktatási díj összesítés edzőnként',
                        orderTitle_en: 'Annual Educational Fee by Coach',
                        parentId: 3,
                        content: 'AnnualEducationalFeeByCoach',
                        activatorPermission: ['"OKTOSSZEDZO"']
                    }
                }
            }
        ];
    }
})();
