(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.athletesByBirthYear')
        .controller('AthletesByBirthYearController', AthletesByBirthYearController);

    function AthletesByBirthYearController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthletesByBirthYear');
        vm.sectionList = [];

        vm.columns = [{
            field: 'SzuletesiEv',
            headerText: gettextCatalog.getString('BirthYear'),
            textAlign: 'center', width: 125
        }, {
            field: 'Szam',
            headerText: gettextCatalog.getString('NumberOfAthletes'),
            textAlign: 'center',
            template: '#atbybiyetemplateDataColumn1', width: 125
        }, {
            field: 'SzamFiu',
            headerText: gettextCatalog.getString('NumberOfBoys'),
            textAlign: 'center',
            template: '#atbybiyetemplateDataColumn2', width: 115
        }, {
            field: 'SzamLany',
            headerText: gettextCatalog.getString('NumberOfGirls'),
            textAlign: 'center',
            template: '#atbybiyetemplateDataColumn3', width: 115
        }];

        vm.Date = new Date();
        vm.selectedSection = 0;
        vm.listAthletes = listAthletes;
        var SectionName = '';

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTELETKOR').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams([], ['sectionID', 'year'])]).then(function (results) {
                        // Ha paraméter nélkül érkezik akkor tovább arra az oldalra ahol a gridben kattintva eljuthat ide újra
                        if (results[0].sectionID == null || results[0].year == null) {
                            $state.go('reports.statisticalReports.activeAthletesByAge');
                        } else {
                            vm.params = results[0];
                            vm.selectedSection = vm.params.sectionID;
                            getSections();
                            getNumbers(vm.params.year, vm.params.sectionID);
                        }
                    });
                }
            });
        }

        function listAthletes(year, gender2) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('SzuletesiEv', 'equal', year)).then(function () {
                var gender = gender2 === 0 ? 'allgender' : (gender2 === 1 ? 'male' : 'female');

                paramHelper.setParams([
                    {'state': 'activeAthletesByBirthYear'},
                    {'year': year},
                    {'sectionId': vm.selectedSection},
                    {'sectionName': SectionName},
                    {'dateFrom': null},
                    {'dateTo': null},
                    {'gender': gender}
                ]);
                $state.go('reports.statisticalReports.listAthletes');
            });
        }

        function getNumbers(year, sectionId) {
            var date = new Date(year, 0, 1); // First day of year
            dataservice.activeAthletesByAge(date, sectionId)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                    SectionName = ej.DataManager(vm.sectionList).executeLocal(new ej.Query().where('value', 'equal', vm.selectedSection))[0].text;
                    $rootScope.title = gettextCatalog.getString('activeAthletesByBirthYear') + ' (' + gettextCatalog.getString(SectionName) + ', ' + vm.params.year + ')';
                });
        }
    }
})();
