(function () {
    'use strict';

    angular .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage1')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.annualSeasonPlan.annualSeasonPlanReportPage1',
                config: {
                    url: '/annualSeasonPlanReportPage1',
                    templateUrl: 'app/administration/annualSeasonPlan/annualSeasonPlanReportPage1/annualSeasonPlanReportPage1.html',
                    controller: 'AnnualSeasonPlanReportPage1Controller',
                    controllerAs: 'vm',
                    title: 'AnnualSeasonPlan',
                    settings: {
                        navId: 252,
                        level: 2,
                        order: 5,
                        orderTitle_hu: 'Éves felkészítési és versenyeztetési terv',
                        orderTitle_en: 'Annual Preparation and Competition Plan',
                        parentId: 2,
                        content: 'AnnualSeasonPlan',
                        activatorPermission: ['"MEGTEVVERST"']
                    }
                }
            }
        ];
    }
})();
