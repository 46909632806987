(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesByMonth')
        .controller('ActiveAthletesByMonthController', ActiveAthletesByMonthController);

    function ActiveAthletesByMonthController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ActiveAthletesByMonth');
        vm.sectionList = [];

        initColumns();

        vm.DateFrom = new Date();
        vm.DateFrom.setMonth(vm.DateFrom.getMonth() - 1);
        vm.DateTo = new Date();
        vm.dateFromChanged = dateFromChanged;
        vm.dateToChanged = dateToChanged;
        vm.openSection = openSection;
        vm.openAthletes = openAthletes;
        vm.openJoined = openJoined;
        vm.openLeaving = openLeaving;

        vm.accessibleSections = [];
        vm.accessibleSectionsStr = [];
        vm.userId = -1;
        vm.isSectionLeader = false;
        vm.isAdmin = false;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTALAK').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSectionList().then(function () {
                        getNumbers(vm.DateFrom, vm.DateTo);
                        initColumns();
                    });
                }
            });
        }

        function initColumns() {
            vm.columns = [
                {field: 'SzuletesiEv', headerText: gettextCatalog.getString('Month'), textAlign: 'center', width: 115, cssClass: 'rotate90degreegridheader4'},
                {field: 'Szam', headerText: gettextCatalog.getString('NumberOfAthletes'), textAlign: 'center', width: 115, cssClass: 'rotate90degreegridheader4', template: '#aabm_allgender'},
                {field: 'SzamFiu', headerText: gettextCatalog.getString('NumberOfBoys'), textAlign: 'center', width: 100, cssClass: 'rotate90degreegridheader4', template: '#aabm_male'},
                {field: 'SzamLany', headerText: gettextCatalog.getString('NumberOfGirls'), textAlign: 'center', width: 100, cssClass: 'rotate90degreegridheader4', template: '#aabm_female'},
                {field: 'SzamErkezett', headerText: gettextCatalog.getString('Belepett'), textAlign: 'center', width: 100, cssClass: 'rotate90degreegridheader4', template: '#aabm_joined'},
                {field: 'SzamKilepett', headerText: gettextCatalog.getString('Kilepett'), textAlign: 'center', width: 100, cssClass: 'rotate90degreegridheader4', template: '#aabm_leaving'}
            ];
            for (var i = 0; i < vm.sectionList.length; i++) {
                vm.columns.push({field: 'c' + vm.sectionList[i].value, headerText: vm.sectionList[i].text, textAlign: 'center', width: 115, cssClass: 'rotate90degreegridheader4', template: '#aabm_ops' + vm.sectionList[i].value});
                if (i === (vm.sectionList.length - 1)) {
                    grid.columns = vm.columns;
                    grid.refreshColumns();
                }
            }
        }

        function getNumbers(dateFrom, dateTo) {
            dateFrom = $filter('dateToISOFilter')(dateFrom);
            dateTo = $filter('dateToISOFilter')(dateTo);
            dataservice.activeAthletesByMonth(dateFrom, dateTo)
                .then(function (data) {
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.accessibleSectionsStr = data.felhasznaloSzakosztalyaiStr;
                    vm.userId = data.userId;
                    vm.isAdmin = data.isAdmin;
                    vm.isSectionLeader = data.isSectionLeader;
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                });
        }

        function openAthletes(id, gender2) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('id', 'equal', id)).then(function (data) {
                var date = data.result[0].datum;
                var gender = gender2 === 0 ? 'allgender' : (gender2 === 1 ? 'male' : 'female');

                paramHelper.setParams([
                    {'state': 'monthlyStat'},
                    {'sectionId': 0},
                    {'sectionName': gettextCatalog.getString('All sections')},
                    {'dateFrom': date},
                    {'dateTo': date},
                    {'gender': gender}
                ]);
                $state.go('reports.statisticalReports.listAthletes');
            });
        }

        function openLeaving(id) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('id', 'equal', id)).then(function (data) {
                var date = data.result[0].datum;

                authenticationService.getRight('SPSZAMALAKRESZL').then(function (results) {
                    if (results !== true) {
                        log.permissionError();
                    } else {
                        paramHelper.setParams([
                            {'state': 'monthlyStatLeaving'},
                            {'sectionId': 0},
                            {'dateFrom': date},
                            {'dateTo': date},
                            {'gender': 'allgender'}
                        ]);
                        $state.go('reports.statisticalReports.listAthletes');
                    }
                });
            });
        }

        function openJoined(id) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('id', 'equal', id)).then(function (data) {
                var date = data.result[0].datum;

                authenticationService.getRight('SPSZAMALAKRESZL').then(function (results) {
                    if (results !== true) {
                        log.permissionError();
                    } else {
                        paramHelper.setParams([
                            {'state': 'monthlyStatJoined'},
                            {'sectionId': 0},
                            {'dateFrom': date},
                            {'dateTo': date},
                            {'gender': 'allgender'}
                        ]);
                        $state.go('reports.statisticalReports.listAthletes');
                    }
                });
            });
        }

        function openSection(sectionID, id) {
            new ej2.data.DataManager(grid.dataSource).executeQuery(new ej2.data.Query().where('id', 'equal', id)).then(function (data) {
                var date = data.result[0].datum;

                var sectionBelongsToUser = false;
                for (var i = 0; i < vm.accessibleSections.length; i++) {
                    if (vm.accessibleSections[i] == sectionID) {
                        sectionBelongsToUser = true;
                        break;
                    }
                }
                if (sectionBelongsToUser || vm.isAdmin) {
                    paramHelper.setParams([
                        {'sectionID': sectionID},
                        {'date': (date)}
                    ]);
                    $state.go('reports.statisticalReports.activeAthletesInSectionByMonth');
                } else {
                    log.warningMsg('You do not have permission to access the selected section details!');
                }
            });
        }

        function dateFromChanged(data) {
            getNumbers(data.value, vm.DateTo);
        }

        function dateToChanged(data) {
            getNumbers(vm.DateFrom, data.value);
        }

        function getSectionList() {
            return dataservice.sectionDropDownListAll()
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function ParseDate(strDate) { // "2016. június" --> Date
            var idxOfSpace = strDate.indexOf(' ');
            var strYear = strDate.slice(0, idxOfSpace - 1);
            var strMonth = strDate.slice(idxOfSpace + 1, idxOfSpace + 4);
            var year = parseInt(strYear);
            var month = 0;
            switch (strMonth) {
                case 'jan' :
                    month = 1;
                    break;
                case 'feb' :
                    month = 2;
                    break;
                case 'már' :
                    month = 3;
                    break;
                case 'ápr' :
                    month = 4;
                    break;
                case 'máj' :
                    month = 5;
                    break;
                case 'jún' :
                    month = 6;
                    break;
                case 'júl' :
                    month = 7;
                    break;
                case 'aug' :
                    month = 8;
                    break;
                case 'sze' :
                    month = 9;
                    break;
                case 'okt' :
                    month = 10;
                    break;
                case 'nov' :
                    month = 11;
                    break;
                case 'dec' :
                    month = 12;
                    break;
            }
            return new Date(year, month - 1, 1, 3);
        }
    }
})();
