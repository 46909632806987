(function () {
    'use strict';

    angular
        .module('app.reports.weeklyTrainingReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.weeklyTrainingReport',
                config: {
                    url: '/weeklyTrainingReport',
                    templateUrl: 'app/reports/weeklyTrainingReport/weeklyTrainingReport.html',
                    controller: 'weeklyTrainingReportController',
                    controllerAs: 'vm',
                    title: 'weeklyTrainingReport',
                    settings: {
                        navId: 2204,
                        level: 2,
                        order: 20,
                        orderTitle_hu: 'Heti edzésterv kimutatás',
                        orderTitle_en: 'Weekly Training Report',
                        parentId: 4,
                        content: 'weeklyTrainingReport',
                        activatorPermission: ['"MEGTHETIEDZTERVKIMUT"']
                    }
                }
            }
        ];
    }
})();
