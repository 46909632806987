(function () {
        'use strict';

        angular
            .module('app.administration.trainingPlan.trainingPlanCreate')
            .controller('TrainingPlanCreateController', TrainingPlanCreateController);

        function TrainingPlanCreateController(log, paramHelper, dataservice, DOMAIN, $filter, $scope, $state, $q, $rootScope, gettextCatalog, authenticationService, $timeout) {
            var vm = this;
            $rootScope.vm = vm;
            $rootScope.title = gettextCatalog.getString('TrainingPlanCreate');

            // main datas
            vm.sorIndex = 0; // a gyakorlatok torléséhez kell egy elsödlegeskulcs sorIndex)
            vm.params = null;
            vm.loadPlan = false;
            vm.saveParams = null;
            vm.trainingSectionId = 0;
            vm.trainingSectionList = [];
            vm.trainingName = null;
            vm.trainingDescription = null;
            vm.trainingAgeGroupId = 0;
            vm.trainingAgeGroupList = [];
            vm.gridDataSourceTraining = [];
            vm.imageRoot = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl;
            vm.addNewPermission = false;
            vm.timeLength = 0;

            // filter
            vm.autoComplete = [];
            vm.sectionId = 0;
            vm.sectionList = [];
            vm.ageGroupId = 0;
            vm.ageGroupList = [];
            vm.coachId = 0;
            vm.coachList = [];

            // grid
            vm.selectedRow = [];
            vm.EDZO = false;

            // autoComplete empty string ''
            vm.bodyPartValue = '';
            vm.TypeValue = '';
            vm.individualTeamValue = '';
            vm.AssetValue = '';
            vm.difficultyValue = '';
            vm.necessaryAreaValue = '';
            vm.withVideo = false;
            vm.withPattern = false;
            vm.withPaint = false;

            // button
            vm.addEnabled = false;
            vm.modify = false;
            vm.allowSave = false;

            function sumIdo(args) {
                var res = 0;
                args.result.forEach(function (element) {
                    if (element.Ido) {
                        res += element.Ido * element.IsmSzam;
                    }
                });
                vm.timeLength = res;
                return res;
            }

            vm.summaryRows = [{
                columns: [
                    {
                        type: 'Custom',
                        customAggregate: sumIdo,
                        columnName: 'Ido',
                        footerTemplate: gettextCatalog.getString('Sum') + ': ${Custom}'
                    }
                ]
            }];
            vm.columnsMain = [
                {type: 'checkbox', headerText: '', textAlign: 'center', width: 55},
                {field: 'SorIndex', isPrimaryKey: true, visible: false},
                {field: 'EdzesFejId', visible: false},
                {field: 'GyakorlatId', visible: false},
                {
                    field: 'Nev',
                    headerText: gettextCatalog.getString('Name'),
                    width: 200,
                    textAlign: 'center',
                    template: '#wtpropentemp'
                },
                {field: 'Ido', headerText: gettextCatalog.getString('TimeLength'), width: 150, textAlign: 'center'},
                {
                    field: 'Mintakep',
                    headerText: gettextCatalog.getString('Pattern'),
                    width: 180,
                    textAlign: 'center',
                    template: '#imagetemplateGrid'
                },
                {
                    field: 'Video',
                    headerText: gettextCatalog.getString('Video'),
                    width: 115,
                    type: 'boolean',
                    displayAsCheckBox: true,
                    textAlign: 'center'
                },
                {
                    field: 'GyakorlatKategoria',
                    headerText: gettextCatalog.getString('GyakorlatKategoria'),
                    width: 150,
                    textAlign: 'center'
                },
                {field: 'IsmSzam', headerText: gettextCatalog.getString('IsmSzam'), width: 150, textAlign: 'center'},
                {
                    field: 'GyakorlatTipus',
                    headerText: gettextCatalog.getString('GyakorlatTipus'),
                    width: 150,
                    textAlign: 'center'
                },
                {
                    field: 'Jellege',
                    headerText: gettextCatalog.getString('individualTeam'),
                    width: 150,
                    textAlign: 'center'
                },
                {field: 'Testresz', headerText: gettextCatalog.getString('BodyParts'), width: 150, textAlign: 'center'},
            ];

            var MainGrid = new ej2.grids.Grid({
                dataSource: [],
                toolbar: $rootScope.toolbarItemsWithSearchAndDelete,
                allowPaging: true,
                allowSorting: true,
                allowExcelExport: true,
                locale: 'hu-HU',
                gridLines: 'Both',
                aggregates: vm.summaryRows,
                pageSettings: $rootScope.pageSettings,
                columns: vm.columnsMain,
                rowDataBound: function (args) {
                    $rootScope.compile(args.row, $scope);
                },
                actionComplete: function (args) {
                    if (args.requestType === 'paging' || args.requestType === 'refresh') {
                        $rootScope.compile($('#MainGrid'), $scope);
                    }
                },
                actionBegin: function (args) {
                    if (args.requestType === 'delete') {
                        MainGrid.dataSource = $rootScope.deleteRows(MainGrid);
                        args.cancel = true;
                    }
                }
            });
            MainGrid.appendTo('#MainGrid');
            MainGrid.toolbarClick = function (args) {
                $rootScope.toolbarClick(args, MainGrid);
            };

            vm.trainingColumns = [
                {type: 'checkbox', headerText: '', textAlign: 'center', width: 60},
                {field: 'GyakorlatId', isPrimaryKey: true, visible: false},
                {
                    field: 'Nev',
                    headerText: gettextCatalog.getString('Name'),
                    width: 150,
                    textAlign: 'center',
                    template: '#wtpropentemp'
                },
                {
                    field: 'Mintakep',
                    headerText: gettextCatalog.getString('Pattern'),
                    width: 120,
                    textAlign: 'center',
                    template: '#imagetemplateGrid'
                },
                {
                    field: 'Video',
                    headerText: gettextCatalog.getString('Video'),
                    width: 100,
                    type: 'boolean',
                    displayAsCheckBox: true,
                    textAlign: 'center'
                },
                {
                    field: 'Szakosztaly',
                    headerText: gettextCatalog.getString('Section'),
                    width: 150,
                    textAlign: 'center'
                },
                {field: 'SzakosztalyId', visible: false},
                {
                    field: 'Korosztaly',
                    headerText: gettextCatalog.getString('AgeGroup'),
                    width: 150,
                    textAlign: 'center'
                },
                {field: 'KorosztalyId', visible: false},
                {field: 'Edzo', headerText: gettextCatalog.getString('Coach'), width: 150, textAlign: 'center'},
                {field: 'EdzoId', visible: false},
                {field: 'Ido', headerText: gettextCatalog.getString('TimeLength'), width: 150, textAlign: 'center'},
                {field: 'Testresz', headerText: gettextCatalog.getString('BodyParts'), width: 150, textAlign: 'center'},
                {field: 'Tipus', headerText: gettextCatalog.getString('Type'), width: 150, textAlign: 'center'},
                {
                    field: 'Jellege',
                    headerText: gettextCatalog.getString('individualTeam'),
                    width: 150,
                    textAlign: 'center'
                },
                {
                    field: 'Felszerelesek',
                    headerText: gettextCatalog.getString('Assets'),
                    width: 150,
                    textAlign: 'center'
                },
                {
                    field: 'Publikus',
                    headerText: gettextCatalog.getString('Public'),
                    width: 100,
                    type: 'boolean',
                    displayAsCheckBox: true,
                    textAlign: 'center'
                },
                {field: 'Kifesto', headerText: gettextCatalog.getString('Paint'), width: 150, textAlign: 'center'},
                {
                    field: 'JatekosokSzama',
                    headerText: gettextCatalog.getString('Players'),
                    width: 100,
                    textAlign: 'center'
                },
                {
                    field: 'Nehezseg',
                    headerText: gettextCatalog.getString('Difficulty'),
                    width: 100,
                    textAlign: 'center'
                },
                {
                    field: 'SzuksegesTerulet',
                    headerText: gettextCatalog.getString('Space'),
                    width: 150,
                    textAlign: 'center'
                }
            ];

            var TrainingGrid = new ej2.grids.Grid({
                dataSource: [],
                toolbar: $rootScope.toolbarItemsWithSearch,
                allowPaging: true,
                allowSorting: true,
                allowExcelExport: true,
                enableToggle: true,
                locale: 'hu-HU',
                gridLines: 'Both',
                pageSettings: $rootScope.pageSettings,
                columns: vm.trainingColumns,
                selectionSettings: {type: 'Multiple', mode: 'Row'},
                rowDataBound: function (args) {
                    $rootScope.compile(args.row, $scope);
                },
                actionComplete: function (args) {
                    if (args.requestType === 'paging' || args.requestType === 'refresh') {
                        $rootScope.compile($('#TrainingGrid'), $scope);
                    }
                },
                rowSelecting: function () {
                    $timeout(function () {
                        vm.selectedRow = TrainingGrid.getSelectedRecords();
                        if (vm.selectedRow && vm.selectedRow.length > 0) {
                            vm.addEnabled = true && vm.allowSave;
                        }
                    }, 100);
                },
                rowDeselecting: function (args) {
                    $timeout(function () {
                        if (TrainingGrid.getSelectedRecords().length === 0) {
                            vm.addEnabled = false;
                            vm.selectedRow = [];
                            $scope.$apply();
                        } else {
                            vm.selectedRow.splice((vm.selectedRow.find(row => row.GyakorlatId == args.data[0].GyakorlatId)), 1);
                        }
                    }, 100);
                },
            });
            TrainingGrid.appendTo('#TrainingGrid');
            TrainingGrid.toolbarClick = function (args) {
                $rootScope.toolbarClick(args, TrainingGrid);
            };

            // events
            // filter bottom
            vm.sectionSelect = sectionSelect;
            vm.sectionDataBound = sectionDataBound;
            vm.ageGroupSelect = ageGroupSelect;
            vm.coachSelect = coachSelect;
            vm.ageGroupDataBound = ageGroupDataBound;
            vm.coachDataBound = coachDataBound;

            // filter top
            vm.trainingSectionSelect = trainingSectionSelect;
            vm.trainingSectionDataBound = trainingSectionDataBound;
            vm.trainingAgeGroupSelect = trainingAgeGroupSelect;
            vm.trainingAgeGroupDataBound = trainingAgeGroupDataBound;

            // grid
            vm.openTemplate = openTemplate;

            // button
            vm.showExercises = showExercises;
            vm.addRow = addRow;
            vm.save = save;
            activate();

            function activate() {
                $q.all([
                    authenticationService.getRight('UJEDZESSABLON'),
                    authenticationService.getRight('MODEDZESSABLON'),
                    authenticationService.getRight('MEGTEDZESSABLON'),
                    authenticationService.getRight('EDZO'),
                    paramHelper.getParams([],
                        ['trainingPlanCreate.trainingId',
                            'trainingPlanCreate.sectionId',
                            'trainingPlanCreate.ageGroupId'
                        ]),
                    authenticationService.getRight('UJGYAKORLAT')
                ]).then(function (results) {
                        $timeout(function () {
                            vm.UJEDZESSABLON = results[0];
                            vm.MODEDZESSABLON = results[1];
                            vm.MEGTEDZESSABLON = results[2];
                            vm.EDZO = results[3];
                            vm.params = results[4];
                            vm.addNewPermission = results[5];
                            if (!vm.MEGTEDZESSABLON) {
                                log.permissionError(true);
                                return;
                            }
                            if (vm.params && vm.params['trainingPlanCreate.trainingId'] > 0) {
                                vm.trainingId = vm.params['trainingPlanCreate.trainingId'];
                                LoadPlan(vm.params['trainingPlanCreate.trainingId']);
                            }
                            if (vm.params && vm.params['trainingPlanCreate.trainingId'] === 0) {
                                LoadPlan(0);
                            } else {
                                getTrainingSections();
                                getAutoComplete();
                            }

                            if ((vm.UJEDZESSABLON && (vm.trainingId < 1 || !vm.trainingId)) || (vm.MODEDZESSABLON && vm.trainingId > 0)) {
                                vm.allowSave = true;
                                MainGrid.allowSelection = true;
                                TrainingGrid.allowSelection = true;
                                MainGrid.editSettings.allowDeleting = true;
                                MainGrid.refreshHeader();
                                $timeout(function () {
                                    $scope.$apply();
                                }, 100);
                            }
                        }, 0);
                    }
                );
            }

            function LoadPlan(trainingId) {
                vm.loadPlan = true;
                if (trainingId > 0) {
                    dataservice.GetTrainingPlanById(trainingId).then(function (data) {
                        if (data) {
                            vm.trainingSectionId = data.itemsList[0].SzakosztalyId ? data.itemsList[0].SzakosztalyId : vm.trainingSectionId;
                            vm.trainingAgeGroupId = data.itemsList[0].KorosztalyId ? data.itemsList[0].KorosztalyId : vm.trainingAgeGroupId;
                            vm.trainingName = data.itemsList[0].Nev;
                            MainGrid.dataSource = $filter('addImagePathToObject')(data.itemsList[0].Gyakorlatok, 'Mintakep', vm.imageRoot);
                            vm.trainingDescription = data.itemsList[0].Leiras;
                            vm.public = data.itemsList[0].Publikus;
                            $timeout(function () {
                                $('.thumbnail-item').magnificPopup({
                                    type: 'image'
                                });
                            }, 100);
                        }
                    });
                } else {
                    vm.trainingSectionId = vm.params['trainingPlanCreate.sectionId'] ? vm.params['trainingPlanCreate.sectionId'] : vm.trainingSectionId;
                    vm.trainingAgeGroupId = vm.params['trainingPlanCreate.ageGroupId'] ? vm.params['trainingPlanCreate.ageGroupId'] : vm.trainingAgeGroupId;
                }
                getTrainingSections();
                getAutoComplete();
            }

            // function Top
            function getTrainingSections() {
                dataservice.sectionDropDownList(0, false).then(function (data) {
                    if (data.itemsList && data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    // egyszerre átadom a két szakosztály dropdown adatait
                    vm.sectionList = data.itemsList;
                    vm.trainingSectionList = data.itemsList;
                });
            }

            function trainingSectionDataBound() {
                if (vm.sectionList.length === 1) {
                    angular.element('#trainingSectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                } else {
                    angular.element('#trainingSectionDropDown').ejDropDownList('selectItemByValue', vm.trainingSectionId);
                }
            }

            function trainingSectionSelect(args) {
                vm.trainingSectionId = args.value;
                getTrainingAgeGroups(args.value);
            }

            function getTrainingAgeGroups(trainingSectionId) {
                return dataservice.ageGroupDropDownList(trainingSectionId).then(function (data) {
                    if (data.itemsList && data.itemsList.length !== 1) {
                        data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    }
                    vm.trainingAgeGroupList = data.itemsList;
                });
            }

            function trainingAgeGroupDataBound() {
                if (vm.trainingAgeGroupList.length === 1) {
                    angular.element('#trainingAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.trainingAgeGroupList[0].value);
                } else {
                    angular.element('#trainingAgeGroupDropDown').ejDropDownList('selectItemByValue', vm.trainingAgeGroupId);
                }
            }

            function trainingAgeGroupSelect(args) {
                vm.trainingAgeGroupId = args.value;
            }

            // function bottom
            function getAutoComplete() {
                dataservice.getTrainingPracticeAutoComplete()
                    .then(function (result) {
                        vm.autoComplete = result.data;
                    });
            }

            function sectionDataBound() {
                if (vm.sectionList && vm.sectionList.length === 1) {
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                } else {
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
                }
            }

            function sectionSelect(args) {
                getAgeGroups(args.value);
            }

            function getAgeGroups(sectionId) {
                return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                    if (data.itemsList && data.itemsList.length !== 1) {
                        data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    }
                    vm.ageGroupList = data.itemsList;
                });
            }

            function ageGroupDataBound() {
                if (vm.ageGroupList && vm.ageGroupList.length === 1) {
                    angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                } else {
                    angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                }
            }

            function ageGroupSelect(args) {
                getCoachesList(args.value);
            }

            function getCoachesList(sectionId) {
                return dataservice.coachDropDownList(sectionId, 0).then(function (data) {
                    if (data.itemsList && data.itemsList.length !== 1) {
                        data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                    }
                    vm.coachList = data.itemsList;
                });
            }

            function coachDataBound() {
                if (vm.coachList && vm.coachList.length === 1) {
                    angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                } else {
                    angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
                }
            }

            function coachSelect() {
                //vm.coachId = args.value;
            }

            // grid top events
            function openTemplate(exerciseId) {
                if (vm.allowSave) {
                    swal({
                        title: gettextCatalog.getString('Would you like to save the plan?'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No'),
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            save().then(function (res) {
                                if (res) {
                                    paramHelper.setParams([{'exercises.exerciseId': exerciseId}]);
                                    $state.go('administration.exercises.exercisesCreate');
                                }
                            });
                        } else if (isConfirm.dismiss === 'cancel') {
                            paramHelper.setParams([{'exercises.exerciseId': exerciseId}]);
                            $state.go('administration.exercises.exercisesCreate');
                        }
                    });
                } else {
                    paramHelper.setParams([{'exercises.exerciseId': exerciseId}]);
                    $state.go('administration.exercises.exercisesCreate');
                }
            }

            // button events
            function showExercises() {
                dataservice.getExercises(vm.sectionId, vm.ageGroupId, vm.coachId, vm.bodyPartValue, vm.TypeValue, vm.individualTeamValue, vm.AssetValue, vm.difficultyValue, vm.necessaryAreaValue, vm.withVideo, vm.withPattern, vm.withPaint, vm.timeValue)
                    .then(function (data) {
                        TrainingGrid.dataSource = $filter('addImagePathToObject')(data.itemsList, 'Mintakep', vm.imageRoot);
                        $timeout(function () {
                            $('.thumbnail-item').magnificPopup({
                                type: 'image'
                            });
                        }, 100);
                    });
            }

            function save() {
                if ((vm.UJEDZESSABLON && (vm.trainingId < 1 || !vm.trainingId)) || (vm.MODEDZESSABLON && vm.trainingId > 0)) {
                    return $q(function (resolve) {
                        $scope.$broadcast('submitted');
                        if ($scope.form.$valid === false) {
                            log.errorMsg('FillTheRequiredFields');
                            resolve(0);
                        } else {
                            vm.saveParam = {
                                TrainingId: vm.trainingId,
                                TrainingName: vm.trainingName,
                                SectionId: vm.trainingSectionId,
                                AgeGroupId: vm.trainingAgeGroupId,
                                Description: vm.trainingDescription,
                                Public: vm.public,
                                Data: MainGrid.dataSource
                            };
                            dataservice.saveTrainingPlan(vm.saveParam).then(function (result) {
                                if (result.retValue !== 0) {
                                    vm.trainingId = result.retValue;
                                    log.successMsg('SaveCompleted');
                                    // $state.go('administration.trainingPlan.trainingPlanList');
                                }
                                resolve(result.retValue);
                            });
                            if (vm.MODEDZESSABLON) {
                                vm.allowSave = true;
                            } else {
                                vm.allowSave = false;
                                TrainingGrid.clearSelection();
                                MainGrid.editSettings.allowDeleting = false;
                            }
                        }
                    });
                } else {
                    log.permissionError();
                }
            }

            function addRow() {
                var gyakorlatKategoria = null;
                var ismSzam = null;
                var HTMLtext = '';
                HTMLtext += '<div style="margin-top: 10px;">';
                HTMLtext += '<label  style="text-align: left; margin-top:7px;  font-weight: bold;">' + gettextCatalog.getString('GyakorlatKategoria') + gettextCatalog.getString('(e.g. warmingUp)') + '</label><br>';
                HTMLtext += '<input class="col-sm-8" id="AutoCompleteSwalType"  ej-autocomplete></input><br>';
                HTMLtext += '<label style="text-align: left; margin-top:7px; margin-bottom: auto">' + gettextCatalog.getString('NumberOfRepetitions') + '</label><br>';
                HTMLtext += '<input class="col-sm-8" id="SwalIsmetlesSzam" placeholder=""></input><br>';
                HTMLtext += '</div>';
                if (vm.selectedRow && vm.selectedRow.length > 0) {
                    swal({
                        html: HTMLtext,
                        type: 'info',
                        showCancelButton: true,
                        allowOutsideClick: false,
                        confirmButtonText: gettextCatalog.getString('Add'),
                        cancelButtonText: gettextCatalog.getString('Cancel'),
                        preConfirm: function () {
                            vm.trainingPlanCategory = angular.element('#AutoCompleteSwalType').data('ejAutocomplete').getValue(); // get value
                            return new Promise(function (resolve) {
                                if (!vm.trainingPlanCategory) {
                                    swal.showValidationError(gettextCatalog.getString('ExerciseCategoryRequired'));
                                }
                                resolve();
                            });
                        }
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            var rows = MainGrid.dataSource;
                            MainGrid.dataSource = [];
                            vm.selectedRow.forEach(function (element) {
                                rows.push(
                                    {
                                        SorIndex: vm.sorIndex,
                                        GyakorlatId: element.GyakorlatId,
                                        GyakorlatKategoria: gyakorlatKategoria,
                                        GyakorlatTipus: element.Tipus,
                                        IsmSzam: ismSzam,
                                        Nev: element.Nev,
                                        Ido: element.Ido,
                                        Video: element.Video,
                                        Mintakep: element.Mintakep,
                                        Jellege: element.Jellege,
                                        Testresz: element.Testresz
                                    }
                                );
                                vm.sorIndex++;
                            });
                            MainGrid.dataSource = rows;
                        }
                    }, function () {
                    });

                    // in swal
                    angular.element('#AutoCompleteSwalType').ejAutocomplete({
                        dataSource: vm.autoComplete.Tipus,
                        allowAddNew: true,
                        showPopupButton: true,
                        showEmptyResultText: true,
                        emptyResultText: gettextCatalog.getString('NoSuggestions'),
                        change: function (arg) {
                            gyakorlatKategoria = arg.value;
                        },
                        value: gyakorlatKategoria,
                        width: '100%'
                    });
                    angular.element('#SwalIsmetlesSzam').ejNumericTextbox({
                        change: function changeValue(arg) {
                            ismSzam = arg.value;
                        },
                        width: '100%'
                    });
                }
            }

            vm.createNewTrainingPlan = function () {
                if (vm.addNewPermission !== true) {
                    log.permissionError(true);
                    return;
                }
                if (vm.allowSave) {
                    swal({
                        title: gettextCatalog.getString('Would you like to save the plan?'),
                        type: 'warning',
                        showCancelButton: true,
                        confirmButtonColor: '#DD6B55',
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No'),
                        closeOnConfirm: false,
                        closeOnCancel: false
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            save().then(function (res) {
                                if (res) {
                                    paramHelper.setParams([{'trainingPlanCreate.trainingId': res}]);
                                    paramHelper.setParams([
                                        {'exercisesList.SzakosztalyId': vm.trainingSectionId},
                                        {'exercisesList.KorosztalyId': vm.trainingAgeGroupId},
                                        {'exercisesList.EdzoId': 0}
                                    ]);
                                    $state.go('administration.exercises.exercisesCreate');
                                }
                            });
                        } else if (isConfirm.dismiss === 'cancel') {
                            $state.go('administration.exercises.exercisesCreate');
                        }
                    });
                } else {
                    $state.go('administration.exercises.exercisesCreate');
                }
            };
        }
    }
)
();
