(function () {
    'use strict';

    angular
        .module('app.administration.monthlyTrainingPlan.monthlyTrainingPlanList')
        .controller('MonthlyTrainingPlanListController', MonthlyTrainingPlanListController);

    function MonthlyTrainingPlanListController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm;

        function setInitVals() {
            $rootScope.title = gettextCatalog.getString('MonthlyTrainingPlanList');
            //grid adatai
            vm.columns = [];

            vm.coachList = [];
            vm.selectedCoach = 0;

            vm.sectionList = [];
            vm.selectedSection = 0;

            vm.ageGroupList = [];
            vm.selectedAgeGroup = 0;

            vm.years = [];
            for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
                vm.years.push(ev);
            }
            //Kiválasztott év (alapból az aktuális)
            vm.selectedYear = new Date().getFullYear();
            vm.selectedRowId = -1;

            vm.textSearch = '';

            vm.delEnabled = false;
        }

        setInitVals();

        vm.coachChanged = coachChanged;
        vm.coachDataBound = coachDataBound;
        vm.sectionChanged = sectionChanged;
        vm.ageGroupChanged = ageGroupChanged;
        vm.yearChanged = yearChanged;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.refreshData = refreshData;
        vm.goToNewUser = goToNewUser;
        vm.goToNewAgeGroup = goToNewAgeGroup;
        vm.goToWeeklyTrainingPlan = goToWeeklyTrainingPlan;
        vm.createNewWeeklyTrainingPlan = goToNewWeeklyTrainingPlan;
        vm.rowSelect = rowSelect;
        vm.rowDeselected = rowDeselected;
        vm.deleteWeeklyTrainingPlan = deleteWeeklyTrainingPlan;

        gridColumnsRefresh();

        var grid = new ej2.grids.Grid({
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            allowTextWrap: true,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowSelected: vm.rowSelect,
            rowDeselected: vm.rowDeselected,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            var promises = [paramHelper.getParams([], ['monthlyTrainingPlan.edzoId', 'monthlyTrainingPlan.szakosztalyId', 'monthlyTrainingPlan.korosztalyId', 'monthlyTrainingPlan.ev', 'monthlyTrainingPlan.szoveg']),
                authenticationService.getRight('UJHAVIEDZTERV'),
                authenticationService.getRight('MODHAVIEDZTERV'),
                authenticationService.getRight('MEGTHAVIEDZTERV'),
                authenticationService.getRight('MEGTFELH'),
                authenticationService.getRight('MEGTKOROKEZ'),
                authenticationService.getRight('TORLHAVIEDZTERV')];

            return $q.all(promises).then(function (results) {
                angular.element('#maEdzo').ejDropDownList({dataSource: vm.coachList});
                if (vm.coachList.length === 1) {
                    vm.selectedCoach = vm.coachList[0].value;
                }
                vm.UJHAVIEDZTERV = results[1];
                vm.MODHAVIEDZTERV = results[2];
                vm.MEGTHAVIEDZTERV = results[3];
                vm.MEGTFELH = results[4];
                vm.MEGTKOROKEZ = results[5];
                vm.TORLHAVIEDZTERV = results[6];
                if (!vm.MEGTHAVIEDZTERV) {
                    log.permissionError(true);
                    return;
                }
                getCoaches();
                $timeout(function () {
                    if (angular.isDefined(results[0]['monthlyTrainingPlan.edzoId'])) {
                        vm.selectedCoach = parseInt(results[0]['monthlyTrainingPlan.edzoId']);
                    }
                    if (angular.isDefined(results[0]['monthlyTrainingPlan.szakosztalyId'])) {
                        vm.selectedSection = parseInt(results[0]['monthlyTrainingPlan.szakosztalyId']);
                    }
                    if (angular.isDefined(results[0]['monthlyTrainingPlan.korosztalyId'])) {
                        vm.selectedAgeGroup = parseInt(results[0]['monthlyTrainingPlan.korosztalyId']);
                    }
                    if (angular.isDefined(results[0]['monthlyTrainingPlan.szoveg'])) {
                        vm.textSearch = results[0]['monthlyTrainingPlan.szoveg'];
                    }
                    if (results[0]['monthlyTrainingPlan.ev']) {
                        vm.selectedYear = results[0]['monthlyTrainingPlan.ev'];
                    } else {
                        vm.selectedYear = new Date().getFullYear();
                    }
                    angular.element('#maEv').ejDropDownList({value: vm.selectedYear});
                    $scope.$apply();
                }, 0);
            });
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;

                    angular.element('#maEdzo').ejDropDownList({dataSource: vm.coachList});
                    if (vm.coachList.length === 1) {
                        vm.selectedCoach = vm.coachList[0].value;
                    }
                }
            );
        }

        //Grid oszlopainak definíciója
        function gridColumnsRefresh() {
            vm.columns = [
                {
                    field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true
                },
                {
                    field: 'Link',
                    headerText: '',
                    textAlign: 'center',
                    allowEditing: false,
                    width: 60,
                    template: '#motrpllitemplateDataColumn3'
                },
                {
                    field: 'Edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'center',
                    allowEditing: false,
                    width: 125,
                    template: '#motrpllitemplateDataColumn1'
                },
                {
                    field: 'Szakosztaly',
                    headerText: gettextCatalog.getString('Section'),
                    textAlign: 'center',
                    width: 125,
                    allowEditing: false
                },
                {
                    field: 'Korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center',
                    allowEditing: false,
                    width: 125,
                    template: '#motrpllitemplateDataColumn2'
                },
                {
                    field: 'Honap',
                    headerText: gettextCatalog.getString('Month'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'Makrociklus',
                    headerText: gettextCatalog.getString('Makrociklus'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'Jovahagyott',
                    headerText: gettextCatalog.getString('Approved'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false,
                    template: '#motrpllitemplateDataColumn4'
                }
            ];
        }

        //edző dropdownlist selectionchanged
        function coachChanged(args) {
            vm.selectedCoach = args.value;

            vm.ageGroupList = [];
            getSectionList(args.value);
        }

        function getSectionList(coachId) {
            return dataservice.sectionDropDownList(coachId).then(
                function (data) {
                    vm.sectionList = [];
                    vm.sectionList = data.itemsList;
                }
            );
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.selectedCoach = vm.coachList[0].value;
            } else {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }// Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.selectedSection = vm.sectionList[0].value;
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        function sectionChanged(args) {
            if (args.value === vm.selectedSection) {
                vm.selectedSection = args.value;
                return;
            }
            vm.selectedSection = args.value;
            vm.ageGroupList = [];
            dataservice.ageGroupSelectYear(vm.selectedCoach, vm.selectedSection, vm.selectedYear).then(
                function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupChanged(args) {
            if (args.value != null) {
                vm.selectedAgeGroup = args.value;
            }
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
            }
        }

        function yearChanged(args) {
            vm.selectedYear = args.value;
            return dataservice.ageGroupSelectYear(vm.selectedCoach, vm.selectedSection, vm.selectedYear).then(
                function (data) {
                    vm.ageGroupList = data.itemsList;
                }
            );
        }

        function refreshData() {
            $timeout(function () {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                    return;
                }
                paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach}, {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection}, {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup}, {'monthlyTrainingPlan.ev': vm.selectedYear}, {'monthlyTrainingPlan.szoveg': vm.textSearch}]);
                dataservice.getMonthlyTrainingPlanList(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup, vm.selectedYear, vm.textSearch).then(
                    function (data) {
                        grid.dataSource = data.itemsList;
                        vm.selectedRowId = -1;
                        vm.delEnabled = false;
                    }
                );
            }, 0);
        }

        function goToNewUser(edzoId) {
            if (!vm.MEGTFELH) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach}, {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection}, {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup}, {'monthlyTrainingPlan.ev': vm.selectedYear}, {'newUser.userId': edzoId}, {'monthlyTrainingPlan.szoveg': vm.textSearch}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAgeGroup(ageGroupId) {
            if (!vm.MEGTKOROKEZ) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach}, {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection}, {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup}, {'monthlyTrainingPlan.ev': vm.selectedYear}, {'ageGroups.ageGroup': ageGroupId}, {'monthlyTrainingPlan.szoveg': vm.textSearch}]);
            $state.go('athletes.ageGroups.modifyAgeGroup');
        }

        function goToWeeklyTrainingPlan(trainingPlanId) {
            if (!vm.MEGTHAVIEDZTERV) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach},
                {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'monthlyTrainingPlan.ev': vm.selectedYear},
                {'monthlyTrainingPlan.trainingPlanId': trainingPlanId},
                {'monthlyTrainingPlan.edzoNev': getNameByID(vm.coachList, vm.selectedCoach)},
                {'monthlyTrainingPlan.szakosztalyNev': getNameByID(vm.sectionList, vm.selectedSection)},
                {'monthlyTrainingPlan.korosztalyNev': getNameByID(vm.ageGroupList, vm.selectedAgeGroup)},
                {'monthlyTrainingPlan.szoveg': vm.textSearch}
            ]);
            $state.go('administration.monthlyTrainingPlan.monthlyTrainingPlanReport');
        }

        function goToNewWeeklyTrainingPlan() {
            if (!vm.UJHAVIEDZTERV) {
                log.permissionError();
                return;
            }
            if (angular.isUndefined(vm.selectedCoach) || vm.selectedCoach == null || vm.selectedCoach <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectCoach'), gettextCatalog.getString('Error'));
                return;
            }
            if (angular.isUndefined(vm.selectedSection) || vm.selectedSection == null || vm.selectedSection <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectSection'), gettextCatalog.getString('Error'));
                return;
            }
            if (angular.isUndefined(vm.selectedAgeGroup) || vm.selectedAgeGroup == null || vm.selectedAgeGroup <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectAgeGroup'), gettextCatalog.getString('Error'));
                return;
            }
            if (angular.isUndefined(vm.selectedYear) || vm.selectedYear == null || vm.selectedYear <= 0) {
                log.errorMsg(gettextCatalog.getString('SelectCoach'), gettextCatalog.getString('Error'));
                return;
            }
            paramHelper.setParams([{'monthlyTrainingPlan.edzoId': vm.selectedCoach},
                {'monthlyTrainingPlan.szakosztalyId': vm.selectedSection},
                {'monthlyTrainingPlan.korosztalyId': vm.selectedAgeGroup},
                {'monthlyTrainingPlan.ev': vm.selectedYear},
                {'monthlyTrainingPlan.trainingPlanId': -1},
                {'monthlyTrainingPlan.edzoNev': getNameByID(vm.coachList, vm.selectedCoach)},
                {'monthlyTrainingPlan.szakosztalyNev': getNameByID(vm.sectionList, vm.selectedSection)},
                {'monthlyTrainingPlan.korosztalyNev': getNameByID(vm.ageGroupList, vm.selectedAgeGroup)},
                {'monthlyTrainingPlan.szoveg': vm.textSearch}
            ]);
            $state.go('administration.monthlyTrainingPlan.monthlyTrainingPlanReport');
        }

        function getNameByID(arr, id) {
            for (var i = 0; i < arr.length; i++) {
                if (arr[i].value == id) {
                    return arr[i].text;
                }
            }
        }

        function rowSelect(args) {
            vm.selectedRowId = args.data.Id;
            vm.delEnabled = vm.MODHAVIEDZTERV;
            $scope.$apply();
        }

        function rowDeselected() {
            vm.delEnabled = false;
            $scope.$apply();
        }

        function deleteWeeklyTrainingPlan() {
            if (angular.isUndefined(vm.selectedRowId) || vm.selectedRowId <= 0) {
                return;
            }

            dataservice.deleteMonthlyTrainingPlan(vm.selectedRowId).then(
                function (result) {
                    if (result.retValue) {
                        for (var i = 0; i < grid.dataSource.length; i++) {
                            if (grid.dataSource[i].Id === vm.selectedRowId) {
                                grid.dataSource.splice(i, 1);
                                grid.refresh();
                                break;
                            }
                        }
                        log.successMsg(gettextCatalog.getString('PlanDeleted'));
                    } else {
                        log.errorMsg('CantDeleted');
                    }
                });
        }
    }
})();
