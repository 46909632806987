(function () {
    'use strict';

    angular
        .module('app.administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport')
        .controller('CompetitiveMonthlyPlanReportController', TeamsCtrl);

    function TeamsCtrl(dataservice, log, $q, $state, $rootScope, gettextCatalog, authenticationService, paramHelper, $scope, $compile, $filter, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('CompetitiveMonthlyPlanReport');

        var lang = authenticationService.getAuthData().language;
        moment.locale(lang);

        var gridHasAppended = false;
        // Grid's details
        vm.selectedAgeGroup = undefined;
        vm.ageGroupSelect = ageGroupSelect;
        vm.data = [];
        vm.dataYear = [];
        vm.columnsYear = [];
        vm.stackedHeaderRows = [];
        vm.coachsAgeGroups = [];
        initEvesGrid();

        vm.SzakosztalyNeve = '';
        vm.EdzoNeve = '';
        vm.SzakosztalyId = 0;
        vm.EdzoId = 0;
        vm.MODHAVIVERST = false;
        vm.readOnlyMonthlyPlan = true;
        vm.reportParameters = {};
        //Évek (2010-től az aktuális évig)
        vm.years = [];
        vm.alreadyCreated = false;

        var evIg = new Date().getFullYear();
        var evvege = (new Date().getMonth() >= 9);
        if (evvege) {
            evIg++;
        }
        var year = new Date().getFullYear();
        var month = new Date().getMonth();

        for (var ev = year - 10; ev <= evIg; ev++) {
            vm.years.push({text: ev, value: ev});
        }

        //Hónapok
        vm.months = [
            {text: gettextCatalog.getString('January'), value: 0},
            {text: gettextCatalog.getString('February'), value: 1},
            {text: gettextCatalog.getString('March'), value: 2},
            {text: gettextCatalog.getString('April'), value: 3},
            {text: gettextCatalog.getString('May'), value: 4},
            {text: gettextCatalog.getString('June'), value: 5},
            {text: gettextCatalog.getString('July'), value: 6},
            {text: gettextCatalog.getString('August'), value: 7},
            {text: gettextCatalog.getString('September'), value: 8},
            {text: gettextCatalog.getString('October'), value: 9},
            {text: gettextCatalog.getString('November'), value: 10},
            {text: gettextCatalog.getString('December'), value: 11}
        ];

        vm.individualText = gettextCatalog.getString('Individual');
        vm.teamText = gettextCatalog.getString('Team');

        vm.selectedYear = undefined;
        vm.selectedMonth = undefined;

        //Edzők listája, kiválasztott edző
        vm.coachList = [];
        //Szakosztályok listája, kiválasztott szakosztály
        vm.sectionList = [];
        vm.selectedSection = 0;

        vm.saveButton = false;
        vm.closeButton = false;
        vm.acceptButton = false;
        vm.rejectButton = false;

        vm.egyeni = false;

        vm.noMonthlyPlan = false;
        vm.hasMonthlyPlan = false;
        vm.hasSignedMonthlyPlan = false;
        vm.hasAcceptedMonthlyPlan = false;

        //A szerkesztés alatt álló havi versenyterv
        vm.editedMonthlyPlan = {};

        //Jelenlét hiba volt a mentés során
        vm.hasErrorAtSave = false;
        //Visszautasítás indoka kerül bele
        vm.rejectMonthlyPlanText = '';

        vm.coachDataBound = coachDataBound;
        vm.deletePlan = deletePlan;
        vm.closeMonthlyPlan = closeMonthlyPlan;
        vm.acceptMonthlyPlan = acceptMonthlyPlan;
        vm.rejectMonthlyPlan = rejectMonthlyPlan;
        vm.saveMonthlyPlan = saveMonthlyPlan;
        vm.closerejectingWindow = closerejectingWindow;
        vm.rejectMonthlyPlan = rejectMonthlyPlan;
        vm.rejectMonthlyPlanOk = rejectMonthlyPlanOk;
        //vm.lefut = lefut;
        vm.actionBegin = actionBegin;
        vm.printMonthlyPlan = printMonthlyPlan;
        vm.openAthlete = openAthlete;
        vm.openTeam = openTeam;
        vm.cellEdit = cellEdit;
        vm.cellSave = cellSave;

        vm.yearSelect = yearSelect;
        vm.monthSelect = monthSelect;

        vm.selectedRowIndexPopup = -1;
        vm.goToOlderMonthlyCompetitiveReports = goToOlderMonthlyCompetitiveReports;
        vm.closeEarlierCompetitvePlanWindow = closeEarlierCompetitvePlanWindow;
        vm.selectEarlierCompetitvePlanWindow = selectEarlierCompetitvePlanWindow;

        vm.dataPopup = [];

        vm.Id = 0;
        vm.editedRowId = 0;
        var coachId = -1;
        var sectionId = -1;
        var reopen = false;
        var newplan = false;

        var printOpenedCS = false;
        var printOpenedE = false;

        vm.AthleteUnderEditing = '';
        vm.AthleteUnderEditingText = gettextCatalog.getString('ActualAthlete');
        vm.editedColumnName = '';
        vm.CopyCellToColumn = CopyCellToColumn;

        vm.dateTimeEditorTemplate = {
            create: function () {
                vm.elem = document.createElement('input');
                vm.elem.addEventListener('focus', function () {
                    this.select();
                });
                return vm.elem;
            },
            read: function () {
                return vm.maskObject.getMaskedValue();
            },
            destroy: function () {
                if (vm.maskObject != null) {
                    vm.maskObject.destroy();
                }
            },
            write: function (args) {
                vm.maskObject = new ej2.inputs.MaskedTextBox({
                    minWidth: '100%',
                    mask: '>0000.00.00.',
                    value: args.rowData[args.column.field],
                    readOnly: vm.readOnlyMonthlyPlan,
                });
                vm.editedRowId = args.rowData['Id'];
                $compile(args.element[0])($scope);
                vm.maskObject.appendTo(vm.elem);
            }
        };

        vm.columns = [];
        vm.columnsPopup = [];
        var columnsSet = -1;

        activate();
        refreshGrid();

        function EdzoKorcsoportjai(EdzoId) {
            dataservice.getEdzoKorcsoportjai(EdzoId).then(function (data) {
                vm.coachsAgeGroups = data.itemsList;
            });
        }

        function ageGroupSelect(arg) {
            vm.selectedAgeGroup = arg.value;
            VersenyTervTablazat(vm.EdzoId, vm.selectedAgeGroup, vm.selectedMonth);
        }

        function VersenyTervTablazat(edzo, korcsoport, honap) {
            initEvesGrid();
            dataservice.annualSeasponPlanTableListSelect2(edzo, korcsoport, honap)
                .then(function (data) {
                    vm.dataYear = data.itemsList;
                    gridSearch.dataSource = vm.dataYear;
                });
        }

        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'RowNumber', headerText: '', textAlign: 'center', width: 50, allowEditing: false},
            {
                field: 'KorosztalySportolo',
                headerText: gettextCatalog.getString('Korosztály, sportoló'),
                textAlign: 'center',
                allowEditing: false,
                width: 175,
                //template: '#cmpropenathlete'
            },
            {
                field: 'KorosztalyCsapat',
                headerText: gettextCatalog.getString('AgeGroupTeam'),
                textAlign: 'center',
                allowEditing: false,
                width: 175,
            },
            {
                field: 'Merkozes1',
                headerText: gettextCatalog.getString('Verseny') + ' 1',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes1')
            },
            {
                field: 'Versenyszam1',
                headerText: gettextCatalog.getString('Versenyszam') + ' 1',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam1')
            },
            {
                field: 'Helyszin1',
                headerText: gettextCatalog.getString('Helyszin') + ' 1',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin1')
            },
            {
                field: 'Eredmeny1',
                headerText: gettextCatalog.getString('VarhEredm') + ' 1',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny1')
            },
            {
                field: 'DatumTol1',
                headerText: gettextCatalog.getString('DatumTol') + ' 1',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg1',
                headerText: gettextCatalog.getString('DatumIg') + ' 1',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'Merkozes2',
                headerText: gettextCatalog.getString('Verseny') + ' 2',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes2')
            },
            {
                field: 'Versenyszam2',
                headerText: gettextCatalog.getString('Versenyszam') + ' 2',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam2')
            },
            {
                field: 'Helyszin2',
                headerText: gettextCatalog.getString('Helyszin') + ' 2',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin2')
            },
            {
                field: 'Eredmeny2',
                headerText: gettextCatalog.getString('VarhEredm') + ' 2',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny2')
            },
            {
                field: 'DatumTol2',
                headerText: gettextCatalog.getString('DatumTol') + ' 2',
                textAlign: 'center',
                width: 90,
                //allowEditing: !vm.readOnlyMonthlyPlan,
                edit: vm.dateTimeEditorTemplate
            },
            {
                field: 'DatumIg2',
                headerText: gettextCatalog.getString('DatumIg') + ' 2',
                textAlign: 'center',
                width: 90,
                //allowEditing: !vm.readOnlyMonthlyPlan,
                edit: vm.dateTimeEditorTemplate
            },
            {
                field: 'Merkozes3',
                headerText: gettextCatalog.getString('Verseny') + ' 3',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes3')
            },
            {
                field: 'Versenyszam3',
                headerText: gettextCatalog.getString('Versenyszam') + ' 3',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam3')
            },
            {
                field: 'Helyszin3',
                headerText: gettextCatalog.getString('Helyszin') + ' 3',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin3')
            },
            {
                field: 'Eredmeny3',
                headerText: gettextCatalog.getString('VarhEredm') + ' 3',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny3')
            },
            {
                field: 'DatumTol3',
                headerText: gettextCatalog.getString('DatumTol') + ' 3',
                textAlign: 'center',
                width: 90,
                //allowEditing: !vm.readOnlyMonthlyPlan,
                edit: vm.dateTimeEditorTemplate
            },
            {
                field: 'DatumIg3',
                headerText: gettextCatalog.getString('DatumIg') + ' 3',
                textAlign: 'center',
                width: 90,
                //allowEditing: !vm.readOnlyMonthlyPlan,
                edit: vm.dateTimeEditorTemplate
            },
            {
                field: 'Merkozes4',
                headerText: gettextCatalog.getString('Verseny') + ' 4',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes4')
            },
            {
                field: 'Versenyszam4',
                headerText: gettextCatalog.getString('Versenyszam') + ' 4',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam4')
            },
            {
                field: 'Helyszin4',
                headerText: gettextCatalog.getString('Helyszin') + ' 4',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin4')
            },
            {
                field: 'Eredmeny4',
                headerText: gettextCatalog.getString('VarhEredm') + ' 4',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny4')
            },
            {
                field: 'DatumTol4',
                headerText: gettextCatalog.getString('DatumTol') + ' 4',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg4',
                headerText: gettextCatalog.getString('DatumIg') + ' 4',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'Merkozes5',
                headerText: gettextCatalog.getString('Verseny') + ' 5',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes5')
            },
            {
                field: 'Versenyszam5',
                headerText: gettextCatalog.getString('Versenyszam') + ' 5',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam5')
            },
            {
                field: 'Helyszin5',
                headerText: gettextCatalog.getString('Helyszin') + ' 5',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin5')
            },
            {
                field: 'Eredmeny5',
                headerText: gettextCatalog.getString('VarhEredm') + ' 5',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny5')
            },
            {
                field: 'DatumTol5',
                headerText: gettextCatalog.getString('DatumTol') + ' 5',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                // allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg5',
                headerText: gettextCatalog.getString('DatumIg') + ' 5',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'Merkozes6',
                headerText: gettextCatalog.getString('Verseny') + ' 6',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes6')
            },
            {
                field: 'Versenyszam6',
                headerText: gettextCatalog.getString('Versenyszam') + ' 6',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam6')
            },
            {
                field: 'Helyszin6',
                headerText: gettextCatalog.getString('Helyszin') + ' 6',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin6')
            },
            {
                field: 'Eredmeny6',
                headerText: gettextCatalog.getString('VarhEredm') + ' 6',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny6')
            },
            {
                field: 'DatumTol6',
                headerText: gettextCatalog.getString('DatumTol') + ' 6',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg6',
                headerText: gettextCatalog.getString('DatumIg') + ' 6',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'Merkozes7',
                headerText: gettextCatalog.getString('Verseny') + ' 7',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes7')
            },
            {
                field: 'Versenyszam7',
                headerText: gettextCatalog.getString('Versenyszam') + ' 7',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam7')
            },
            {
                field: 'Helyszin7',
                headerText: gettextCatalog.getString('Helyszin') + ' 7',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin7')
            },
            {
                field: 'Eredmeny7',
                headerText: gettextCatalog.getString('VarhEredm') + ' 7',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny7')
            },
            {
                field: 'DatumTol7',
                headerText: gettextCatalog.getString('DatumTol') + ' 7',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg7',
                headerText: gettextCatalog.getString('DatumIg') + ' 7',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'Merkozes8',
                headerText: gettextCatalog.getString('Verseny') + ' 8',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Merkozes8')
            },
            {
                field: 'Versenyszam8',
                headerText: gettextCatalog.getString('Versenyszam') + ' 8',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Versenyszam8')
            },
            {
                field: 'Helyszin8',
                headerText: gettextCatalog.getString('Helyszin') + ' 8',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Helyszin8')
            },
            {
                field: 'Eredmeny8',
                headerText: gettextCatalog.getString('VarhEredm') + ' 8',
                textAlign: 'center',
                width: 150,
                //allowEditing: !vm.readOnlyMonthlyPlan
                //editTemplate: vm.editTextTemplate('Eredmeny8')
            },
            {
                field: 'DatumTol8',
                headerText: gettextCatalog.getString('DatumTol') + ' 8',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            },
            {
                field: 'DatumIg8',
                headerText: gettextCatalog.getString('DatumIg') + ' 8',
                textAlign: 'center',
                width: 90,
                edit: vm.dateTimeEditorTemplate,
                //allowEditing: !vm.readOnlyMonthlyPlan
            }
        ];

        var gridNew = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: false,
            allowTextWrap: true,
            allowExcelExport: true,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columns,
            cellSave: vm.cellSave,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            rowSelected: vm.gridRowSelected,
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#GridNew'), $scope);
                }
            },
            cellEdit: function (arg) {
                vm.AthleteUnderEditing = arg.rowData.SportoloId != null ? arg.rowData.KorosztalySportolo : arg.rowData.KorosztalyCsapat;
                vm.editedColumnName = arg.columnName;
                $timeout(function () {
                    $scope.$apply();
                }, 0);
            }
        });
        gridNew.appendTo('#GridNew');
        gridNew.keyConfigs.escape = '';
        gridNew.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridNew);
        };

        var gridSearch = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false},
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 400,
            columns: vm.columnsYear,
            stackedHeaderRows: vm.stackedHeaderRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridYear'), $scope);
                }
            }
        });
        gridSearch.appendTo('#GridYear');
        gridSearch.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridSearch);
        };

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            editSettings: {allowEditing: false},
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 400,
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#GridPopup'), $scope);
                }
            }
        });
        gridPopup.appendTo('#GridPopup');
        gridPopup.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, gridPopup);
        };

        function activate() {
            // $scope.$watch('vm.data', function (args) {
            //     //console.log('watcher: ', args);
            // });
            // $scope.$watch('vm.editedMonthlyPlan.tetelek', function (args) {
            //     //console.log('watcher tetelek: ', args);
            // });

            $q.all([paramHelper.getParams([], ['competitiveMonthlyPlan.competitivePlanId', 'competitiveMonthlyPlan.szakosztalyId', 'competitiveMonthlyPlan.szakosztalyNev', 'competitiveMonthlyPlan.edzoId', 'competitiveMonthlyPlan.edzoNev', 'competitiveMonthlyPlan.egyeni', 'competitiveMonthlyPlan.datum']),
                    authenticationService.getRight('EDZO'),
                    authenticationService.getRight('ADMIN'),
                    authenticationService.getRight('SZAKOSZTVEZ'),
                    authenticationService.getRight('TECHIGAZGATO'),
                    authenticationService.getRight('HAVIVERSTALAIR1'),
                    authenticationService.getRight('HAVIVERSTALAIR2'),
                    authenticationService.getRight('UJHAVIVERST'),
                    authenticationService.getRight('MEGTSPORTLIST'),
                    authenticationService.getRight('MEGTHAVIVERST'),
                    authenticationService.getRight('TORLHAVIVERS'),
                    authenticationService.getRight('MODHAVIVERST')
                ])
                .then(function (results) {
                    if (!results[9]) {
                        log.permissionError(true);
                    } else {
                        //VersenyTervTablazat();

                        $timeout(function () {
                            vm.EDZO = results[1];
                            vm.ADMIN = results[2];
                            vm.SZAKOSZTVEZ = results[3];
                            vm.TECHIGAZGATO = results[4];
                            vm.HAVIVERSTALAIR1 = results[5];
                            vm.HAVIVERSTALAIR2 = results[6];
                            vm.UJHAVIVERST = results[7];
                            vm.MEGTSPORTLIST = results[8];
                            vm.MEGTHAVIJEL = results[9];
                            vm.TORLHAVIVERS = results[10];
                            vm.MODHAVIVERST = results[11];

                            if (results[0]['competitiveMonthlyPlan.szakosztalyNev']) {
                                vm.SzakosztalyNeve = results[0]['competitiveMonthlyPlan.szakosztalyNev'];
                            }
                            if (results[0]['competitiveMonthlyPlan.edzoNev']) {
                                vm.EdzoNeve = results[0]['competitiveMonthlyPlan.edzoNev'];
                            }
                            if (results[0]['competitiveMonthlyPlan.szakosztalyId']) {
                                vm.SzakosztalyId = results[0]['competitiveMonthlyPlan.szakosztalyId'];
                            }
                            vm.egyeni = false;
                            if (results[0]['competitiveMonthlyPlan.egyeni'] != undefined) {
                                vm.egyeni = results[0]['competitiveMonthlyPlan.egyeni'];
                                if (vm.egyeni) {
                                    vm.columns[3].visible = false;
                                    vm.columns[2].visible = true;
                                } else {
                                    vm.columns[2].visible = false;
                                    vm.columns[3].visible = true;
                                }
                                gridNew.refresh();
                            }
                            if (results[0]['competitiveMonthlyPlan.edzoId']) {
                                vm.EdzoId = results[0]['competitiveMonthlyPlan.edzoId'];
                                EdzoKorcsoportjai(vm.EdzoId);
                            }

                            //létezőt nyit vissza
                            if (results[0]['competitiveMonthlyPlan.competitivePlanId'] && results[0]['competitiveMonthlyPlan.competitivePlanId'] !== -1) {
                                paramHelper.removeParam('competitiveMonthlyPlan.competitivePlanId');
                                reopen = true;
                                dataservice.monthlyPlanSelectById(results[0]['competitiveMonthlyPlan.competitivePlanId'], results[0]['competitiveMonthlyPlan.szakosztalyId'], vm.egyeni)
                                    .then(function (data) {
                                        viewMonthlyPlan(data);
                                        var date = data.fej.Datum.split('-');
                                        $timeout(function () {
                                            var Datum = new Date(date[0], date[1] - 1, 1, 0, 0, 0);
                                            angular.element('#maEv').ejDropDownList('selectItemByValue', Datum.getFullYear());
                                            angular.element('#maHonap').ejDropDownList('selectItemByValue', Datum.getMonth());
                                            $scope.$apply();
                                        }, 100);
                                        vm.defaultSection = vm.editedMonthlyPlan.fej.IdSzakosztaly;
                                        sectionId = vm.editedMonthlyPlan.fej.IdSzakosztaly;
                                        vm.defaultCoach = vm.editedMonthlyPlan.fej.IdEdzo;
                                        coachId = vm.editedMonthlyPlan.fej.IdEdzo;
                                        vm.editedMonthlyPlan.fej.SzakosztalyNeve = data.fej.SzakosztalyNeve;
                                        vm.editedMonthlyPlan.fej.EdzoNeve = data.fej.EdzoNeve;
                                        vm.Id = vm.editedMonthlyPlan.fej.Id;
                                    });
                                //új versenyeztetési terv
                            } else {
                                newplan = true;
                                vm.noMonthlyPlan = true;
                                vm.editedMonthlyPlan = {};
                                vm.editedMonthlyPlan.fej = {};
                                vm.editedMonthlyPlan.fej.Id = -1;
                                vm.editedMonthlyPlan.fej.Tipus = vm.egyeni;
                                if (results[0]['competitiveMonthlyPlan.szakosztalyNev']) {
                                    vm.editedMonthlyPlan.fej.SzakosztalyNeve = results[0]['competitiveMonthlyPlan.szakosztalyNev'];
                                }
                                if (results[0]['competitiveMonthlyPlan.edzoNev']) {
                                    vm.editedMonthlyPlan.fej.EdzoNeve = results[0]['competitiveMonthlyPlan.edzoNev'];
                                }
                                if (results[0]['competitiveMonthlyPlan.szakosztalyId']) {
                                    vm.editedMonthlyPlan.fej.SzakosztalyId = results[0]['competitiveMonthlyPlan.szakosztalyId'];
                                }
                                if (results[0]['competitiveMonthlyPlan.edzoId']) {
                                    vm.EdzoId = results[0]['competitiveMonthlyPlan.edzoId'];
                                    vm.editedMonthlyPlan.fej.EdzoId = results[0]['competitiveMonthlyPlan.edzoId'];
                                    EdzoKorcsoportjai(vm.EdzoId);
                                }
                                $timeout(function () {
                                    var datum = new Date(results[0]['competitiveMonthlyPlan.datum']);
                                    vm.selectedYear = datum.getFullYear();
                                    vm.selectedMonth = datum.getMonth();
                                    vm.editedMonthlyPlan.fej.Datum = moment({
                                        y: vm.selectedYear,
                                        M: vm.selectedMonth,
                                        d: 1
                                    }).format('YYYY-MM-DD');
                                    vm.editedMonthlyPlan.fej.Vegleges = false;
                                    viewMonthlyPlan(vm.editedMonthlyPlan);
                                    $scope.$apply();
                                }, 0);
                                // selectByDate(); // Ha az adott hónapra már van terv, akkor azt tölti vissza
                            }
                        }, 0);
                    }
                });
        }

        function actionBegin(args) {
            if (args.requestType && (args.requestType === 'paging' || args.requestType === 'sorting')) {
                args.cancel = true;
                gridNew.editModule.batchSave();
            }
        }

        function viewMonthlyPlan(data) {
            vm.editedMonthlyPlan = data;
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol1');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol2');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol3');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol4');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol5');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol6');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol7');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumTol8');

            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg1');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg2');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg3');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg4');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg5');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg6');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg7');
            vm.editedMonthlyPlan.tetelek = $filter('dateStringFilter')(vm.editedMonthlyPlan.tetelek, 'DatumIg8');

            vm.noMonthlyPlan = false;
            vm.hasAcceptedMonthlyPlan = false;
            vm.hasSignedMonthlyPlan = false;
            vm.hasMonthlyPlan = false;
            // UJ
            if (!vm.editedMonthlyPlan.fej || vm.editedMonthlyPlan.fej.Id < 0) {
                vm.acceptButton = false;
                vm.rejectButton = false;
                vm.noMonthlyPlan = true;
                if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.UJHAVIVERST) {
                    vm.saveButton = true;
                    vm.closeButton = true;
                } else if (vm.EDZO && vm.UJHAVIVERST) {
                    vm.saveButton = true;
                    vm.closeButton = true;
                } else {
                    vm.saveButton = false;
                    vm.closeButton = false;
                }
                // if(vm.selectedMonth) {
                //     createNewMonthlyPlan();
                //     refreshGrid();
                // }
                // LETEZO
            } else {
                if (vm.editedMonthlyPlan.fej.Vegleges) {
                    if (vm.editedMonthlyPlan.fej.SzakosztalyVezetoId) {
                        vm.hasAcceptedMonthlyPlan = true;
                        vm.saveButton = false;
                        vm.closeButton = false;
                        vm.acceptButton = false;
                        vm.rejectButton = false;
                    } else {
                        vm.hasSignedMonthlyPlan = true;
                        if (vm.UJHAVIVERST) {
                            vm.saveButton = false;
                            vm.closeButton = false;
                        }
                        if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.HAVIVERSTALAIR1) {
                            vm.acceptButton = true;
                            vm.rejectButton = true;
                        } else {
                            vm.acceptButton = false;
                            vm.rejectButton = false;
                        }
                    }
                } else {
                    vm.hasMonthlyPlan = true;
                    vm.acceptButton = false;
                    vm.rejectButton = false;
                    if ((vm.ADMIN || vm.SZAKOSZTVEZ) && vm.MODHAVIVERST) {
                        vm.saveButton = true;
                        vm.closeButton = true;
                    } else if (vm.EDZO && vm.MODHAVIVERST) {
                        vm.saveButton = true;
                        vm.closeButton = true;
                    } else {
                        vm.saveButton = false;
                        vm.closeButton = false;
                    }
                }
                vm.data = vm.editedMonthlyPlan.tetelek;
            }

            vm.readOnlyMonthlyPlan = !vm.saveButton;
            gridNew.editSettings.allowEditing = !vm.readOnlyMonthlyPlan;

            if (vm.editedMonthlyPlan.fej) {
                vm.leadvaStr = $filter('dateStringFilter')(vm.editedMonthlyPlan.fej.Leadva);
                vm.elfogadvaStr = $filter('dateStringFilter')(vm.editedMonthlyPlan.fej.Elfogadas1);
            }
            refreshGrid();
        }

        function refreshGrid() {
            //vm.columns = [];

            if (gridNew) {
                gridNew.columns = vm.columns;
                gridNew.dataSource = vm.data; //[{Id: 1, RowNumber:1}, {Id: 2, RowNumber:2}];
            }

            vm.columnsPopup = [];
            vm.columnsPopup = [{
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
                {
                    field: 'Szakosztaly',
                    headerText: gettextCatalog.getString('Section'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'Edzo',
                    headerText: gettextCatalog.getString('Trainer'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                },
                {
                    field: 'Megjegyzes',
                    headerText: gettextCatalog.getString('Comment'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                },
                {
                    field: 'Datum',
                    headerText: gettextCatalog.getString('Date'),
                    textAlign: 'center',
                    width: 100,
                    allowEditing: false
                }
            ];
        }

        function createNewMonthlyPlan() {
            if (vm.editedMonthlyPlan.fej.SzakosztalyId) {
                sectionId = vm.editedMonthlyPlan.fej.SzakosztalyId;
            }
            if (vm.editedMonthlyPlan.fej.EdzoId) {
                coachId = vm.editedMonthlyPlan.fej.EdzoId;
            }
            // TODO a versenyterven nem szerepelnek csak azok akik 1-én tagok voltak (nem lehete utólag helyesen tölteni)
            // dataservice.getCompetitiveMonthlyPlanItems(coachId, sectionId, moment(vm.editedMonthlyPlan.fej.Datum).endOf('month').format('YYYY-MM-DD'), vm.egyeni)
            dataservice.getCompetitiveMonthlyPlanItems(coachId, sectionId, moment(vm.editedMonthlyPlan.fej.Datum).format('YYYY-MM-DD'), vm.egyeni)
                .then(function (data2) {
                    vm.data = data2.itemsList;
                    vm.editedMonthlyPlan.tetelek = vm.data;
                    gridNew.editSettings.allowEditing = true;
                    refreshGrid();
                    //saveMonthlyPlan();
                });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.selectedCoach = vm.coachList[0].value;
            } else {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.defaultCoach);
                vm.selectedCoach = vm.defaultCoach;
            }
        }

        function closeMonthlyPlan() {
            $scope.$broadcast('submitted');
            vm.editedMonthlyPlan.fej.Vegleges = true;
            vm.editedMonthlyPlan.fej.Leadva = moment();
            saveMonthlyPlan();
            if (!vm.hasErrorAtSave) {
                vm.readOnlyMonthlyPlan = true;
            }
        }

        function acceptMonthlyPlan() {
            dataservice.monthlyCompetitionPlanAcceptByLeader(vm.editedMonthlyPlan.fej.Id, vm.editedMonthlyPlan.fej.SzakosztalyId, vm.editedMonthlyPlan.fej.Tipus).then(function () {
                log.successMsg('CompetitionPlanAccepted');
                dataservice.monthlyPlanSelectById(vm.editedMonthlyPlan.fej.Id, vm.editedMonthlyPlan.fej.SzakosztalyId, vm.egyeni).then(function (data) {
                    viewMonthlyPlan(data);
                });
            });
        }

        //Havi jelenléti visszautasítása gomb click
        function rejectMonthlyPlan() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.open();
        }

        //Felugró ablak 'Mégsem' gomb click
        function closerejectingWindow() {
            var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
            dialogObj.close();
        }

        //Felugró ablak 'Rendben' gomb click - visszautasítás elmentése
        function rejectMonthlyPlanOk() {
            if (vm.rejectMonthlyPlanText.length > 0) {
                var dialogObj = angular.element('#rejectAttendanceWindow').data('ejDialog');
                dialogObj.close();
            } else {
                return;
            }
            var uzi = gettextCatalog.getString('SystemMessage') + '<br>' + gettextCatalog.getString('The') + ' ' + vm.editedMonthlyPlan.fej.SzakosztalyNev + ' - ' + ' ' + vm.selectedYear +
                ' ' + vm.months[vm.selectedMonth].text +
                ' ' + gettextCatalog.getString('MonthlyAttendanceReportRejected') + '<br>' + gettextCatalog.getString('Cause') + vm.MonthlyPlanText;

            dataservice.messageSave(uzi, [vm.selectedCoach], vm.selectedSection).then(function () {
                vm.editedMonthlyPlan.fej.Vegleges = false;
                vm.editedMonthlyPlan.fej.Leadva = null;
                vm.editedMonthlyPlan.fej.Elfogadas1 = null;
                vm.editedMonthlyPlan.fej.Elfogadas2 = null;
                vm.editedMonthlyPlan.fej.SzakosztalyVezetoId = null;
                vm.editedMonthlyPlan.fej.GazdasagiId = null;
                saveMonthlyPlan();
                if (!vm.hasErrorAtSave) {
                    vm.readOnlyMonthlyPlan = false;
                }
                viewMonthlyPlan(vm.editedMonthlyPlan);
            });
        }

        function saveMonthlyPlan() {
            $scope.$broadcast('submitted');
            if (gridNew.editSettings.allowEditing) {
                gridNew.editModule.endEdit();
            }
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (!vm.MODHAVIVERST && vm.editedMonthlyPlan.fej.Id > 0) {
                log.permissionError();
                return;
            }
            vm.editedMonthlyPlan.tetelek = vm.data;
            dataservice.saveMonthlyCompetitionPlan(vm.editedMonthlyPlan)
                .then(function (/*id*/) {
                    log.successMsg('aaaaCompetitionPlanSaved');
                    $rootScope.back();
                    /*
                    if (vm.editedMonthlyPlan.fej.Id < 0) {
                        dataservice.monthlyPlanSelectById(id.res, vm.editedMonthlyPlan.fej.SzakosztalyId, vm.egyeni).then(function (data) {
                            viewMonthlyPlan(data);
                        });
                    }
                    */
                });
        }

        vm.yearDatabound = function () {
            $timeout(function () {
                vm.selectedYear = year;
                angular.element('#maEv').ejDropDownList('selectItemByValue', vm.selectedYear);
            }, 0);
        };

        function yearSelect(args) {
            if (!vm.editedMonthlyPlan || !vm.editedMonthlyPlan.fej || !vm.editedMonthlyPlan.fej.Datum) {
                return;
            }
            if (vm.selectedYear != args.value) {
                vm.alreadyCreated = false;
            }
            vm.selectedYear = args.value;
            vm.editedMonthlyPlan.fej.Datum = moment(vm.editedMonthlyPlan.fej.Datum).year(vm.selectedYear).format('YYYY-MM-DD');
        }

        vm.monthDatabound = function () {
            $timeout(function () {
                vm.selectedMonth = month;
                angular.element('#maHonap').ejDropDownList('selectItemByValue', vm.selectedMonth);
            }, 0);
        };

        function monthSelect(args) {
            if (!vm.editedMonthlyPlan || !vm.editedMonthlyPlan.fej || !vm.editedMonthlyPlan.fej.Datum) {
                return;
            }
            if (vm.selectedMonth != args.value) {
                vm.alreadyCreated = false;
            }
            vm.selectedMonth = args.value;

            vm.editedMonthlyPlan.fej.Datum = moment({
                y: vm.selectedYear,
                M: vm.selectedMonth,
                d: 1
            }).format('YYYY-MM-DD');
            if (vm.selectedYear && vm.selectedMonth >= 0) {
                selectByDate();
            }
            if (vm.selectedAgeGroup) {
                VersenyTervTablazat(vm.EdzoId, vm.selectedAgeGroup, vm.selectedMonth);
            }
        }

        function selectByDate() {
            if (reopen || angular.isUndefined(vm.selectedMonth) || vm.alreadyCreated) {
                return;
            }
            vm.alreadyCreated = true;
            if (vm.editedMonthlyPlan.fej.SzakosztalyId) {
                sectionId = vm.editedMonthlyPlan.fej.SzakosztalyId;
            }
            if (vm.editedMonthlyPlan.fej.EdzoId) {
                coachId = vm.editedMonthlyPlan.fej.EdzoId;
            }
            dataservice.monthlyPlanSelectByDate(vm.selectedYear, vm.selectedMonth + 1, sectionId, coachId, newplan, vm.egyeni).then(function (data) {
                if (!data.status) { // nem null érkezett (új terv, és van az adott hónapra terv)
                    vm.readOnlyMonthlyPlan = true;
                    viewMonthlyPlan(data);
                } else { // null érkezett (új terv, és nincs még az adott hónapra)
                    vm.editedMonthlyPlan.fej.Vegleges = false;
                    vm.editedMonthlyPlan.fej.Datum = moment({
                        y: vm.selectedYear,
                        M: vm.selectedMonth,
                        d: 1
                    }).format('YYYY-MM-DD');
                    vm.editedMonthlyPlan.fej.Leadva = null;
                    vm.editedMonthlyPlan.fej.Elfogadas1 = null;
                    vm.editedMonthlyPlan.fej.Id = -1;
                    vm.editedMonthlyPlan.fej.Megjegyzes = '';
                    vm.elfogadvaStr = '';
                    vm.leadvaStr = '';
                    vm.saveButton = true;
                    vm.rejectButton = false;
                    vm.closeButton = false;
                    vm.acceptButton = false;
                    vm.noMonthlyPlan = true;
                    vm.hasMonthlyPlan = false;
                    vm.hasSignedMonthlyPlan = false;
                    vm.hasAcceptedMonthlyPlan = false;
                    vm.readOnlyMonthlyPlan = false;
                    createNewMonthlyPlan();
                }
            });
        }

        function getAccepterCoachById() {
            if (vm.editedMonthlyPlan.fej.SzakosztalyVezetoId === null) {
                return '';
            } else {
                return $q(function (resolve) {
                    dataservice.getUserData(vm.editedMonthlyPlan.fej.SzakosztalyVezetoId)
                        .then(function (args) {
                            resolve(args.retValue.name);
                        });
                });
            }
        }

        function printMonthlyPlan() {
            $q.all([getAccepterCoachById(), getSystemSettings()])
                .then(function (results) {
                    var honap = moment(vm.editedMonthlyPlan.fej.Datum).format('YYYY-MM');
                    var masodiklap = 1;
                    if (vm.editedMonthlyPlan.tetelek == null || vm.editedMonthlyPlan.tetelek.length == 0) {
                        masodiklap = 1;
                    } else {
                        vm.editedMonthlyPlan.tetelek.forEach(function (element) {
                            if (
                                element.Merkozes7 !== null || element.Merkozes8 !== null ||
                                element.Eredmeny7 !== null || element.Eredmeny8 !== null ||
                                element.Helyezes7 !== null || element.Helyezes8 !== null) {
                                masodiklap = masodiklap < 4 ? 4 : masodiklap;
                            } else if (
                                element.Merkozes5 !== null || element.Merkozes6 !== null ||
                                element.Eredmeny5 !== null || element.Eredmeny6 !== null ||
                                element.Helyezes5 !== null || element.Helyezes6 !== null) {
                                masodiklap = masodiklap < 3 ? 3 : masodiklap;
                            } else if (
                                element.Merkozes3 !== null || element.Merkozes4 !== null ||
                                element.Eredmeny3 !== null || element.Eredmeny4 !== null ||
                                element.Helyezes3 !== null || element.Helyezes4 !== null) {
                                masodiklap = masodiklap < 2 ? 2 : masodiklap;
                            }
                        });
                    }
                    vm.reportParameters = {
                        Honap: honap,
                        Szakosztaly: vm.editedMonthlyPlan.fej.SzakosztalyNeve,
                        EdzoNeve: vm.editedMonthlyPlan.fej.EdzoNeve,
                        Edzo: vm.editedMonthlyPlan.fej.EdzoNeve,
                        Varos: results[1] == null ? '' : (results[1] + ', '),
                        Jovahagyta1: results[0],
                        Veglegesitve: vm.leadvaStr,
                        Elfogadas1: vm.elfogadvaStr,
                        Jovahagyta2: '',
                        Merkozes1: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes1 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes1,
                        Merkozes2: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes2 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes2,
                        Merkozes3: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes3 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes3,
                        Merkozes4: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes4 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes4,
                        Merkozes5: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes5 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes5,
                        Merkozes6: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes6 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes6,
                        Merkozes7: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes7 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes7,
                        Merkozes8: vm.editedMonthlyPlan.tetelek.length == 0 || vm.editedMonthlyPlan.tetelek[0].Merkozes8 === null ? '' : vm.editedMonthlyPlan.tetelek[0].Merkozes8,
                        TervId: vm.editedMonthlyPlan.fej.Id,
                        Masodiklap: masodiklap
                    };
                    if (vm.egyeni == true) {
                        if (printOpenedE) {
                            angular.element('#printDialogE').ejDialog('open');
                        } else {
                            printOpenedE = true;
                            angular.element('#printDialogE').ejDialog({
                                height: '95%',
                                width: '95%',
                                enablemodal: 'true',
                                'position-x': '0',
                                'position-y': '1',
                                target: '#mainContent',
                                enableresize: 'false'
                            });
                        }
                    } else {
                        if (printOpenedCS) {
                            angular.element('#printDialogCS').ejDialog('open');
                        } else {
                            printOpenedCS = true;
                            angular.element('#printDialogCS').ejDialog({
                                height: '95%',
                                width: '95%',
                                enablemodal: 'true',
                                'position-x': '0',
                                'position-y': '1',
                                target: '#mainContent',
                                enableresize: 'false'
                            });
                        }
                    }
                });
        }

        function openAthlete(id) {
            if (vm.MEGTSPORTLIST) {
                paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.permissionError();
            }
        }

        function openTeam(teamid, ageGroupId, sectionId) {
            paramHelper.setParams([
                {'teams.team': teamid},
                {'teams.ageGroupId': ageGroupId},
                {'teams.sectionId': sectionId}
            ]);
            $state.go('athletes.teams.teamMembersList');
        }

        function cellEdit(args) {
            if (vm.readOnlyMonthlyPlan) {
                args.cancel = true;
            }
        }

        function goToOlderMonthlyCompetitiveReports() {
            vm.selectedRowIndexPopup = -1;
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.open();
            dataservice.getOldCompetitivePlanByUserId(vm.editedMonthlyPlan.fej.EdzoId, vm.editedMonthlyPlan.fej.Id).then(function (args) {
                vm.dataPopup = args.itemsList;
                gridPopup.dataSource = vm.dataPopup;
            });
        }

        function closeEarlierCompetitvePlanWindow() {
            var dialogObj = angular.element('#searchWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectEarlierCompetitvePlanWindow() {
            vm.selectedRowIndexPopup = gridPopup.getSelectedRowIndexes();
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = vm.dataPopup[vm.selectedRowIndexPopup];
                if (vmi) {
                    dataservice.monthlyPlanSelectById(vmi.Id, vmi.SzakosztalyId).then(function (data) {
                        vm.editedMonthlyPlan = {};
                        vm.editedMonthlyPlan.fej = {};
                        vm.editedMonthlyPlan.fej.Datum = moment({
                            y: vm.selectedYear,
                            M: vm.selectedMonth,
                            d: 1
                        }).format('YYYY-MM-DD');
                        vm.editedMonthlyPlan.fej.EdzoId = vm.EdzoId;
                        vm.editedMonthlyPlan.fej.EdzoNeve = vm.EdzoNeve;
                        vm.editedMonthlyPlan.fej.Elfogadas1 = null;
                        vm.editedMonthlyPlan.fej.Elfogadas2 = null;
                        vm.editedMonthlyPlan.fej.GazdasagiId = null;
                        vm.Id = 0;
                        vm.editedMonthlyPlan.fej.Leadva = null;
                        vm.editedMonthlyPlan.fej.Megjegyzes = data.fej.Megjegyzes;
                        vm.editedMonthlyPlan.fej.SzakosztalyNeve = vm.SzakosztalyNeve;
                        vm.editedMonthlyPlan.fej.SzakosztalyId = vm.SzakosztalyId;
                        vm.editedMonthlyPlan.fej.Vegleges = false;
                        vm.editedMonthlyPlan.fej.SzakosztalyVezetoId = null;
                        vm.editedMonthlyPlan.tetelek = data.tetelek;
                        vm.leadvaStr = null;
                        vm.elfogadvaStr = null;
                        viewMonthlyPlan(vm.editedMonthlyPlan);
                        vm.Id = 0;
                    });
                    var dialogObj = angular.element('#searchWindow').data('ejDialog');
                    dialogObj.close();
                }
            }
        }

        function deletePlan() {
            if (!vm.TORLHAVIVERS) {
                log.permissionError();
                return;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected competitive monthly plan?'),
                text: gettextCatalog.getString('You will not be able to recover the plan!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this competitive monthly plan'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    return dataservice.VersenyTervHaviDeleteById(vm.editedMonthlyPlan.fej.Id, vm.SzakosztalyId, vm.egyeni).then(
                        function () {
                            $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanList');
                        }
                    );
                }
            });
        }

        function getSystemSettings() {
            return dataservice.getSystemSettingsValue('VAROS').then(function (response) {
                return response.retValue;
            });
        }

        function CopyCellToColumn() {
            gridNew.editModule.batchSave();
            var db = vm.data.length;
            for (var rowCntr = 0; rowCntr < db; rowCntr++) {
                vm.data[rowCntr][vm.editedColumnName] = vm.editedColumnValue;
            }
            gridNew.refresh();
        }

        function cellSave(arg) {
            $timeout(function () {
                vm.AthleteUnderEditing = '';
                vm.editedColumnValue = arg.value;
                $scope.$apply();
            }, 0);
        }

        function initEvesGrid() {
            vm.columnsYear = [];
            vm.columnsYear = [{
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true,
                width: 30
            },
                {
                    field: 'HetSzam',
                    headerText: gettextCatalog.getString('WeekNumber'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'FelkHetSzam',
                    headerText: gettextCatalog.getString('PreparationWeekNumber'),
                    textAlign: 'center',
                    width: 100,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'Datum',
                    headerText: gettextCatalog.getString('Date'),
                    textAlign: 'center',
                    width: 130
                },
                {
                    field: 'FelkIdJellTiszta',
                    headerText: gettextCatalog.getString('FelkeszulesiIdoTiszta'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'FelkIdJellVegyes',
                    headerText: gettextCatalog.getString('FelkeszulesiIdoVegyes'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'FelkIdJellVerseny',
                    headerText: gettextCatalog.getString('FelkeszulesiIdoVerseny'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzesSzamAlt',
                    headerText: gettextCatalog.getString('HetiEdzesSzamAltalanos'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzesSzamSpSpec',
                    headerText: gettextCatalog.getString('HetiEdzesSzamSportagSpecifikus'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzesSzamOsszesen',
                    headerText: gettextCatalog.getString('HetiEdzesSzamOsszesen'),
                    textAlign: 'center',
                    width: 110,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiTerhelesMagas',
                    headerText: gettextCatalog.getString('HetiTerhelesMagas'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiTerhelesKozepes',
                    headerText: gettextCatalog.getString('HetiTerhelesKozepes'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiTerhelesAlacsony',
                    headerText: gettextCatalog.getString('HetiTerhelesAlacsony'),
                    textAlign: 'center',
                    width: 80,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzMunkaTechnikai',
                    headerText: gettextCatalog.getString('HetiEdzesMunkaTechnikai'),
                    textAlign: 'center',
                    width: 100,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzMunkaKepFejl',
                    headerText: gettextCatalog.getString('HetiEdzesMunkaKepessegFejlesztes'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzMunkaKieg',
                    headerText: gettextCatalog.getString('HetiEdzesMunkaKiegeszito'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'HetiEdzMunkaEgyeb',
                    headerText: gettextCatalog.getString('HetiEdzesMunkaEgyeb'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'FelmeresekAlt',
                    headerText: gettextCatalog.getString('GeneralSurveys'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'FelmeresekSpSpec',
                    headerText: gettextCatalog.getString('SportagSpecifikusFelmeresek'),
                    textAlign: 'center',
                    width: 130,
                    cssClass: 'rotate90degreegridheader3'
                },
                {
                    field: 'EdzTabIdopont',
                    headerText: gettextCatalog.getString('EdzotaborozasIdopont'),
                    textAlign: 'center',
                    width: 130
                },
                {
                    field: 'EdzTabHely',
                    headerText: gettextCatalog.getString('EdzotaborozasHely'),
                    textAlign: 'center',
                    width: 130
                },
                {
                    field: 'EdzTabLetszam',
                    headerText: gettextCatalog.getString('EdzotaborozasLetszam'),
                    textAlign: 'center',
                    width: 120
                },
                {
                    field: 'Verseny',
                    headerText: gettextCatalog.getString('Verseny'),
                    textAlign: 'center',
                    width: 150
                }
            ];

            vm.stackedHeaderRows = [{
                stackedHeaderColumns: [{
                    headerText: gettextCatalog.getString('FelkHetSzam') + '<br>(1-52)',
                    column: 'HetSzam, FelkHetSzam, Datum',
                    textAlign: 'center'
                },
                    {
                        headerText: gettextCatalog.getString('FelkIdJelleg') + '<br>(X)',
                        column: 'FelkIdJellTiszta, FelkIdJellVegyes, FelkIdJellVerseny',
                        textAlign: 'center'
                    },
                    {
                        headerText: gettextCatalog.getString('HetiEdzesSzam') + '<br>(0-21)',
                        column: 'HetiEdzesSzamAlt, HetiEdzesSzamSpSpec, HetiEdzesSzamOsszesen',
                        textAlign: 'center'
                    },
                    {
                        headerText: gettextCatalog.getString('HetiTerheles') + '<br>(X, 0-9)',
                        column: 'HetiTerhelesMagas, HetiTerhelesKozepes, HetiTerhelesAlacsony',
                        textAlign: 'center'
                    },
                    {
                        headerText: gettextCatalog.getString('HetiEdzesJell') + '<br>(X, 0-9)',
                        column: 'HetiEdzMunkaTechnikai, HetiEdzMunkaKepFejl, HetiEdzMunkaKieg, HetiEdzMunkaEgyeb',
                        textAlign: 'center',
                    },
                    {
                        headerText: gettextCatalog.getString('Felmeresek') + '<br>(0-9)',
                        column: 'FelmeresekAlt, FelmeresekSpSpec',
                        textAlign: 'center'
                    },
                    {
                        headerText: gettextCatalog.getString('Edzotaborozas') + '<br>',
                        column: 'EdzTabIdopont, EdzTabHely, EdzTabLetszam',
                        textAlign: 'center'
                    },
                    {
                        headerText: gettextCatalog.getString('Verseny') + '<br>',
                        column: 'Verseny',
                        textAlign: 'center'
                    }
                ]
            }];
        }
    }
})();
