(function () {
    'use strict';

    angular
        .module('app.business.annualEducationalFeeBySection')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'business.annualEducationalFeeBySection',
                config: {
                    url: '/annualEducationalFeeBySection',
                    templateUrl: 'app/business/annualEducationalFeeBySection/annualEducationalFeeBySection.html',
                    controller: 'AnnualEducationalFeeBySectionController',
                    controllerAs: 'vm',
                    title: 'AnnualEducationalFeeBySection',
                    settings: {
                        navId: 2143,
                        level: 2,
                        order: 14,
                        orderTitle_hu: 'Oktatási díj összesítés szakosztályonként',
                        orderTitle_en: 'Annual Educational Fee by Section',
                        parentId: 3,
                        content: 'AnnualEducationalFeeBySection',
                        activatorPermission: ['"OKTOSSZSZAKO"']
                    }
                }
            }
        ];
    }
})();
