/* global XRegExp */
(function () {
    'use strict';

    angular
        .module('app.core')
        .directive('ctsDropdownList', ctsDropdownList)
        .directive('ctsAutoComplete', ctsAutoComplete)
        .directive('ctsColorPicker', ctsColorPicker)
        .directive('ctsDatePicker', ctsDatePicker)
        .directive('ctsDateTimePicker', ctsDateTimePicker)
        .directive('ctsMaskBox', ctsMaskBox)
        .directive('ctsCheckBox', ctsCheckBox)
        .directive('ctsHorizontalCheckBox', ctsHorizontalCheckBox)
        .directive('ctsTextBox', ctsTextBox)
        .directive('ctsTextBoxTajSzam', ctsTextBoxTajSzam)
        .directive('ctsTextArea', ctsTextArea)
        .directive('ctsTextBoxPhoneNumber', ctsTextBoxPhoneNumber)
        .directive('ctsTextBoxEmail', ctsTextBoxEmail)
        .directive('ctsTextBoxTaxId', ctsTextBoxTaxId)
        .directive('ctsTextBoxPassportNumber', ctsTextBoxPassportNumber)
        .directive('ctsListBox', ctsListBox)
        .directive('ctsNumericTextBox', ctsNumericTextBox)
        .directive('ctsRating', ctsRating)
        .directive('report', report)
        .directive('preventEnterSubmit', preventEnterSubmit)
        .directive('fileDropzone', fileDropzone)
        .directive('ngFileSelect', ngFileSelect)
        .directive('htWidgetHeader', htWidgetHeader);

    function htWidgetHeader(log, gettextCatalog) {
        //Usage:
        //<div ht-widget-header title="vm.map.title"></div>
        // Creates:
        // <div ht-widget-header=""
        //      title="Movie"
        //      allow-collapse="true" </div>
        function link($scope) {
            $scope.helpText = gettextCatalog.getString('Help');
            $scope.openHelp = function () {
                log.helpMsgSmall($scope.textId);
            };
        }

        return {
            link: link,
            scope: {
                'title': '@',
                'textId': '@'
            },
            template: '<div class="widget-head">' +
            '<h2 class="page-title">{{title|translate}}' +
            '<a class="pull-right helpText" ng-click="openHelp()"> <span class="glyphicon glyphicon-info-sign"></span> </a>' +
            '</h2></div>',
            restrict: 'EA'
        };
    }

    function ctsDropdownList(gettextCatalog) {
        function controller($scope) {
            if ($scope.create() !== undefined) {
                $scope.create2 = function (data) {
                    $scope.create()({value: data.value});
                };
            }
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.clear() !== undefined) {
                $scope.clear2 = function () {
                    $scope.clear()();
                };
            } else if ($scope.open() !== undefined) {
                $scope.open2 = function () {
                    $scope.open()();
                };
            }
            if ($scope.databound() !== undefined) {
                $scope.databound2 = function (data) {
                    $scope.databound()(data);
                };
            }
            if ($scope.select() !== undefined) {
                $scope.select2 = function (data) {
                    $scope.select()(data);
                };
            }
            //if ($scope.ctsPlaceholder === undefined) {
            $scope.ctsPlaceholder = 'PleaseChoose';
        }

        var link = {
            pre: function ($scope, element) {
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                } else {
                    $scope.$watch(function () {
                        return $scope.enabled;
                    }, function (newValue) {
                        angular.element('#' + $scope.name).ejDropDownList({
                            enabled: newValue,
                            locale: $scope.locale,
                            fields: {
                                text: $scope.fieldText,
                                value: $scope.fieldValue
                            },
                            value: $scope.value,
                            dataSource: $scope.datasource,
                            width: '100%',
                            watermarkText: gettextCatalog.getString($scope.ctsPlaceholder),
                            create: $scope.create2,
                            change: $scope.change2,
                            dataBound: $scope.databound2,
                            select: $scope.select2,
                            selectedIndex: $scope.selectedindex
                        });
                    });
                }
                if (!$scope.fieldValue) {
                    $scope.fieldValue = 'value';
                }
                if (!$scope.fieldText) {
                    $scope.fieldText = 'text';
                }
            },
            post: function ($scope) {
                $scope.form = $scope.$parent.form;
                if ($scope.validationRules !== undefined) {
                    $scope.classes = '';
                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                        $scope.$watch(function () {
                            return $scope.value;
                        }, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.form[$scope.name].$dirty = true;
                                if (newValue === '') {
                                    $scope.form[$scope.name].$setValidity('required', false);
                                } else {
                                    $scope.form[$scope.name].$setValidity('required', true);
                                }
                            }
                        });
                        $scope.$on('submitted', function () {
                            if (!$scope.value) {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                            //$scope.$apply();
                        });
                    }
                } else {
                    $scope.required = false;
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                create: '&',
                clear: '&',
                open: '&',
                databound: '&',
                datasource: '=',
                enabled: '=?',
                label: '@',
                name: '@',
                postfix: '@',
                select: '&',
                validationRules: '=?',
                value: '=?',
                selectedindex: '=?',
                fieldValue: '@?',
                fieldText: '@?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div class="input-group">' +
            '<input class="addId" name="{{name}}" ej-dropdownlist e-locale="locale" e-fields-value="fieldValue" e-fields-text="fieldText" e-value="value" e-datasource="datasource" e-width="100%" e-watermarktext="{{ctsPlaceholder|translate}}" e-create="create2" e-change="change2" e-enabled="enabled" e-dataBound="databound2" e-select="select2" e-selectedIndex="selectedindex">' +
            '<span data-ng-if="clear2 !== undefined" class="input-group-addon" data-ng-click="clear2()"><i class="glyphicon glyphicon-trash"></i></span>' +
            '<span data-ng-if="open2 !== undefined" class="input-group-addon" data-ng-click="open2()"><i' +
            ' class="glyphicon glyphicon-eye-open"></i></span>' +
            '<span data-ng-if="postfix !== undefined" class="input-group-addon">{{postfix}}</span>' +
            //'<div class="validation-messages">' +
            //'<span class="error">{{\'NotValid\'|translate}}</span>' +
            //'</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function autocompleteWatch($timeout, newValue, oldValue, required, maxlength, $scope) {
        if (angular.isDefined(newValue)) {
            if (newValue !== oldValue) {
                $scope.form[$scope.name].$dirty = true;
                if (required) {
                    if (newValue === '') {
                        $timeout(function () {
                            $scope.form[$scope.name].$setValidity('required', false);
                            $scope.$apply();
                        }, 100);
                    } else {
                        $timeout(function () {
                            $scope.form[$scope.name].$setValidity('required', true);
                            $scope.$apply();
                        }, 100);
                    }
                }
                if (maxlength && newValue) {
                    if (newValue.length < maxlength) {
                        $scope.form[$scope.name].$setValidity('maxlength', true);
                    } else {
                        $scope.form[$scope.name].$setValidity('maxlength', false);
                    }
                }
            }
        }
    }

    function ctsAutoComplete($timeout) {
        function controller($scope) {
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
            if (!$scope.showPopupButton) {
                $scope.showPopupButton = false;
            }
            if (!$scope.delaySuggestionTimeout) {
                $scope.delaySuggestionTimeout = '0';
            }
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.multiSelect === 'true') {
                $scope.multiSelectMode = ej.MultiSelectMode.VisualMode;
            } else {
                $scope.multiSelect = false;
                $scope.multiSelectMode = ej.MultiSelectMode.None;
            }
            if (!$scope.allowAddNew) {
                $scope.allowAddNew = false;
            } else {
                $scope.allowAddNew = true;
            }
        }

        var link = {
            pre: function ($scope, element) {
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.owndisabled === undefined) {
                    $scope.owndisabled = false;
                }
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                } else {
                    $scope.$watch(function () {
                        return $scope.enabled;
                    }, function (newValue) {
                        angular.element('#' + $scope.name).ejAutocomplete({enabled: newValue});
                    });
                }
            },
            post: function ($scope) {
                $scope.form = $scope.$parent.form;
                if ($scope.validationRules !== undefined) {
                    $scope.classes = '';
                    $scope.$watch(function () {
                        return $scope.value;
                    }, function (newValue, oldValue) {
                        autocompleteWatch($timeout, newValue, oldValue, $scope.validationRules.required, $scope.validationRules.maxlength, $scope);
                    });
                    $scope.$on('submitted', function () {
                        $scope.value = angular.element('#' + $scope.name).ejAutocomplete('getValue');
                        autocompleteWatch($scope.value, undefined, $scope.validationRules.required, $scope.validationRules.maxlength, $scope);
                        // $scope.$apply();
                    });

                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                    }
                } else {
                    $scope.required = false;
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                clear: '&',
                datasource: '=',
                enabled: '=?',
                label: '@',
                name: '@',
                ctsPlaceholder: '@',
                emptyResultText: '@?',
                postfix: '@',
                select: '&',
                validationRules: '=?',
                value: '=?',
                addnewtext: '=?',
                showPopupButton: '=?',
                delaySuggestionTimeout: '@',
                multiSelect: '@?',
                allowAddNew: '=?',
                owndisabled: '=?',
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div class="input-group">' +
            '<input class="addId" type="text" name="{{name}}" ej-autocomplete e-delaySuggestionTimeout="delaySuggestionTimeout" e-emptyResultText="{{\'emptyResultText\'|translate}}" e-change="change2" e-locale="locale" e-showPopupButton="showPopupButton" e-addnewtext="addnewtext" e-value="value" e-datasource="datasource" e-width="100%" e-watermarktext="{{ctsPlaceholder|translate}}" e-enabled="enabled" data-ng-disabled="owndisabled" e-multiSelectMode="multiSelectMode" e-allowAddNew="true" e-enableDistinct="true" data-ng-required="required"' +
            ' e-autoFocus="true" >' +
            '<span data-ng-if="clear() !== undefined" class="input-group-addon" data-ng-click="clear()()"><i class="glyphicon glyphicon-trash"></i></span>' +
            '<span data-ng-if="postfix !== undefined" class="input-group-addon">{{postfix}}</span>' +
            //'<div class="validation-messages">' +
            //'<span class="error">{{\'NotValid\'|translate}}</span>' +
            //'</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsColorPicker() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.select() !== undefined) {
                $scope.select2 = function (data) {
                    $scope.select()({value: data.value});
                };
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        var link = {
            pre: function ($scope, element) {
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                } else {
                    $scope.$watch(function () {
                        return $scope.enabled;
                    }, function (newValue) {
                        angular.element('#' + $scope.name).ejDropDownList({enabled: newValue});
                    });
                }
            },
            post: function ($scope) {
                $scope.form = $scope.$parent.form;
                if ($scope.validationRules !== undefined) {
                    $scope.classes = '';
                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                        $scope.$watch(function () {
                            return $scope.value;
                        }, function (newValue, oldValue) {
                            if (newValue !== oldValue) {
                                $scope.form[$scope.name].$dirty = true;
                                if (newValue === '') {
                                    $scope.form[$scope.name].$setValidity('required', false);
                                } else {
                                    $scope.form[$scope.name].$setValidity('required', true);
                                }
                            }
                        });
                        $scope.$on('submitted', function () {
                            if (!$scope.value) {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                            //$scope.$apply();
                        });
                    }
                } else {
                    $scope.required = false;
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                select: '&',
                label: '@',
                enabled: '=?',
                name: '@',
                ctsPlaceholder: '@',
                validationRules: '=?',
                value: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid, }">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="addId" name="{{name}}" ej-ColorPicker e-locale="locale" e-value="value" e-width="100%" placeholder="{{ctsPlaceholder|translate}}" e-select="select2" e-change="change2" e-enabled="enabled">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsDatePicker() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
            if ($scope.clear() !== undefined) {
                $scope.clear2 = function () {
                    $scope.clear()();
                };
            }
            $scope.refresh = function(data) {
                $scope.value = data.value;
            };
        }

        var link = {
            pre: function ($scope, element) {
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                } else {
                    $scope.$watch(function () {
                        return $scope.enabled;
                    }, function (newValue) {
                        angular.element('#' + $scope.name).ejDatePicker({enabled: newValue});
                    });
                }
            },
            post: function ($scope) {
                $scope.form = $scope.$parent.form;
                if ($scope.validationRules !== undefined) {
                    $scope.classes = '';
                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                        $scope.$watch(function () {
                            return $scope.value;
                        }, function (newValue, oldValue) {
                            if (newValue !== oldValue && newValue != '') {
                                $scope.form[$scope.name].$dirty = true;
                                if (newValue === '') {
                                    $scope.form[$scope.name].$setValidity('required', false);
                                } else {
                                    $scope.form[$scope.name].$setValidity('required', true);
                                }
                            }
                        });
                        $scope.$on('submitted', function () {
                            if (!$scope.value) {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                            // $scope.$apply();
                        });
                    }
                } else {
                    $scope.required = false;
                }
            }

        };

        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                enabled: '=?',
                change: '&',
                clear: '&',
                label: '@',
                name: '@',
                ctsPlaceholder: '@',
                validationRules: '=?',
                value: '=?',
                locale: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div class="input-group">' +
            '<input class="addId" name="{{name}}" ej-DatePicker e-enableStrictMode="true" e-startDay="1" e-value="value" e-locale="locale" e-width="100%" e-showtooltip="true" e-change="change2" e-focusOut="refresh" e-enabled="enabled">' +
            '<span data-ng-if="clear() !== undefined" class="input-group-addon" data-ng-click="clear()()"><i class="glyphicon glyphicon-trash"></i></span>' +
            '<span data-ng-if="postfix !== undefined" class="input-group-addon">{{postfix}}</span>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsDateTimePicker() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
            if ($scope.clear() !== undefined) {
                $scope.clear2 = function () {
                    $scope.clear()();
                };
            }
            $scope.refresh = function(data) {
                $scope.value = data.value;
            };
        }

        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.enabled === undefined) {
                $scope.enabled = true;
            }
            $scope.$watch(function () {
                return $scope.enabled;
            }, function (newValue) {
                angular.element('#' + $scope.name).ejDateTimePicker({enabled: newValue});
            });
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                if ($scope.validationRules.required === true) {
                    $scope.classes += ' required';
                    $scope.$watch(function () {
                        return $scope.value;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue && newValue != '') {
                            $scope.form[$scope.name].$dirty = true;
                            if (newValue === '') {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                        }
                    });
                    $scope.$on('submitted', function () {
                        if (!$scope.value) {
                            $scope.form[$scope.name].$setValidity('required', false);
                        } else {
                            $scope.form[$scope.name].$setValidity('required', true);
                        }
                        $scope.$apply();
                    });
                }
            } else {
                $scope.required = false;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                enabled: '=?',
                change: '&',
                clear: '&',
                label: '@',
                name: '@',
                interval: '=',
                ctsPlaceholder: '@',
                validationRules: '=?',
                value: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div class="input-group">' +
            '<input class="addId" name="{{name}}" ej-DateTimePicker e-value="value" e-locale="locale" e-width="100%" e-datetimeformat="yyyy-MM-dd HH:mm" e-watermarktext="éééé-hh-nn hh:mm" e-change="change2" e-focusOut="refresh" e-enabled="enabled" e-interval="interval">' +
            '<span data-ng-if="clear() !== undefined" class="input-group-addon" data-ng-click="clear()()"><i class="glyphicon glyphicon-trash"></i></span>' +
            '<span data-ng-if="postfix !== undefined" class="input-group-addon">{{postfix}}</span>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsMaskBox() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.value});
                };
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.enabled === undefined) {
                $scope.enabled = true;
            }
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                if ($scope.validationRules.required === true) {
                    $scope.classes += ' required';
                    $scope.$watch(function () {
                        return $scope.value;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            $scope.form[$scope.name].$dirty = true;
                            if (newValue === '') {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                        }
                    });
                    $scope.$on('submitted', function () {
                        if (!$scope.value) {
                            $scope.form[$scope.name].$setValidity('required', false);
                        } else {
                            $scope.form[$scope.name].$setValidity('required', true);
                        }
                        //$scope.$apply();
                    });
                }
            } else {
                $scope.required = false;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                enabled: '=?',
                change: '&',
                label: '@',
                name: '@',
                maskFormat: '@',
                ctsPlaceholder: '@',
                validationRules: '=?',
                value: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="addId" name="{{name}}" ej-MaskEdit e-value="value" e-locale="locale" e-maskFormat="maskFormat" e-width="100%" placeholder="{{ctsPlaceholder|translate}}" e-change="change2" e-enabled="enabled">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBox() {
        var link = {
            pre: function ($scope, element) {
                $scope.form = $scope.$parent.form;
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                $scope.classes = '';
                if ($scope.owndisabled === undefined) {
                    $scope.owndisabled = false;
                }
                if ($scope.inputonly === true) {
                    $scope.classes += ' inputonly';
                }
                if ($scope.validationRules !== undefined) {
                    $scope.required = $scope.validationRules.required === true;
                    $scope.classes += $scope.validationRules.required === true ? ' required' : '';
                    if ($scope.validationRules.minlength !== undefined) {
                        $scope.minlength = $scope.validationRules.minlength;
                    } else {
                        $scope.minlength = 0;
                    }
                    if ($scope.validationRules.maxlength !== undefined) {
                        $scope.maxlength = $scope.validationRules.maxlength;
                    } else {
                        $scope.maxlength = 128;
                    }
                    if ($scope.type === undefined) {
                        $scope.type = 'text';
                    }
                } else {
                    $scope.required = false;
                }
                if ($scope.ctsPlaceholder === undefined) {
                    $scope.ctsPlaceholder = $scope.label;
                }
            },
            post: function ($scope) {
                if ($scope.change() !== undefined) {
                    $scope.$watch(function () {
                        return $scope.ngModel;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            $scope.change()();
                        }
                    });
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            scope: {
                change: '&', //
                //clear: '&',     //
                //enabled: '=?',  //
                owndisabled: '=?',
                inputonly: '=?',
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                type: '@',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            //'<input class="form-control addId" name="{{name}}" type="{{type}}" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" data-ng-minlength="minlength" data-ng-maxlength="maxlength" data-ng-disabled="disabled">' +
            '<input class="form-control addId" name="{{name}}" type="{{type}}" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" data-ng-minlength="minlength" data-ng-maxlength="maxlength" data-ng-disabled="owndisabled" autocomplete="new-password">' +
            //'<div class="validation-messages">' +
            //'<span class="error">{{\'NotValid\'|translate}}</span>' +
            //'</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBoxTajSzam() {
        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';

                if ($scope.validationRules.required === true) {
                    $scope.required = $scope.validationRules.required === true;
                    $scope.classes += $scope.validationRules.required === true ? ' required' : '';
                    $scope.$watch(function () {
                        return $scope.ngModel;
                    }, function (newValue, oldValue) {
                        if (newValue !== oldValue) {
                            $scope.form[$scope.name].$dirty = true;
                            if (XRegExp('([0-9]{3}-?){3}').test($scope.ngModel) === true) {
                                $scope.form[$scope.name].$setValidity('required', true);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', false);
                            }
                        }
                    });
                    $scope.$on('submitted', function () {
                        if (!$scope.ngModel || XRegExp('([0-9]{3}-?){3}').test($scope.ngModel) === false) {
                            $scope.form[$scope.name].$setValidity('required', false);
                        } else {
                            $scope.form[$scope.name].$setValidity('required', true);
                        }
                        //$scope.$apply();
                    });
                }
            } else {
                $scope.required = false;
            }
            if (angular.isUndefined($scope.ctsPlaceholder)) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" type="text" placeholder="{{ctsPlaceholder|translate}}" e-value="ngModel" data-ng-required="required" ej-MaskEdit e-maskFormat="999-999-999" e-width="100%" e-locale="locale">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextArea() {
        function link($scope) {
            $scope.form = $scope.$parent.form;
            $scope.classes = '';
            if ($scope.inputonly === true) {
                $scope.classes += ' inputonly';
            }
            if (angular.isDefined($scope.validationRules)) {
                $scope.required = $scope.validationRules.required === true;
                $scope.classes += $scope.validationRules.required === true ? ' required' : '';
                if (angular.isDefined($scope.validationRules.minlength)) {
                    $scope.minlength = $scope.validationRules.minlength;
                } else {
                    $scope.minlength = 0;
                }
                if (angular.isDefined($scope.validationRules.maxlength)) {
                    $scope.maxlength = $scope.validationRules.maxlength;
                } else {
                    $scope.maxlength = 128;
                }
            } else {
                $scope.required = false;
            }
            if (angular.isUndefined($scope.type)) {
                $scope.type = 'text';
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
            if ($scope.owndisabled === undefined) {
                $scope.owndisabled = false;
            }
            if ($scope.css === undefined) {
                $scope.css = '';
            }
            if ($scope.rows === undefined) {
                $scope.rows = 5;
            }

            angular.element('.test').attr('id', $scope.name);
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                css: '@',
                owndisabled: '=?',
                inputonly: '=?',
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                validationRules: '=?',
                rows: '@'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}" style="{{css}}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<textarea rows="{{rows}}" style="resize:none" class="form-control addId textarea" name="{{name}}" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" data-ng-minlength="minlength" data-ng-maxlength="maxlength" data-ng-disabled="owndisabled"> </textarea>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsHorizontalCheckBox() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.isChecked});
                };
            }
        }

        var link = {
            pre: function ($scope, element) {
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                }
                element[0].querySelector('.addId').id = $scope.name;

                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                checked: '=?',
                enabled: '=?',
                label: '@',
                name: '@'
            },
            template: '<div class="form-group cts-form-group chkbox">' +
            '<form action="">' +
            '<input class="addId" ej-checkBox e-locale="locale" e-checked="checked" e-enabled="enabled" e-change="change2">&nbsp&nbsp{{label|translate}}<br></form>' +
            '</div>',

            link: link
        };
    }

    function ctsCheckBox() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()({value: data.isChecked});
                };
            }
            if ($scope.enabled === undefined) {
                $scope.enabled = true;
            } else {
                $scope.$watch(function () {
                    return $scope.enabled;
                }, function (newValue) {
                    if (newValue) {
                        angular.element('#' + $scope.name).ejCheckBox('enable');
                    } else {
                        angular.element('#' + $scope.name).ejCheckBox('disable');
                    }
                });
            }
        }

        var link = {
            pre: function ($scope, element) {
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                }
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                checked: '=?',
                enabled: '=?',
                label: '@',
                name: '@'
            },
            template: '<div class="form-group cts-form-group chkbox">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="addId" ej-checkBox e-locale="locale" e-checked="checked" e-enabled="enabled" e-change="change2">' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBoxPhoneNumber() {
        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                $scope.required = $scope.validationRules.required === true;
                $scope.classes += $scope.validationRules.required === true ? ' required' : '';
            } else {
                $scope.required = false;
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" type="text" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" ej-MaskEdit e-locale="locale" e-maskFormat="99-999-9999" e-width="100%">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBoxEmail() {
        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                $scope.required = $scope.validationRules.required === true;
                $scope.classes += $scope.validationRules.required === true ? ' required' : '';
                if ($scope.validationRules.minlength !== undefined) {
                    $scope.minlength = $scope.validationRules.minlength;
                } else {
                    $scope.minlength = 0;
                }
                if ($scope.validationRules.maxlength !== undefined) {
                    $scope.maxlength = $scope.validationRules.maxlength;
                } else {
                    $scope.maxlength = 128;
                }
                if ($scope.type === undefined) {
                    $scope.type = 'text';
                }
            } else {
                $scope.required = false;
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
            if ($scope.enabled === undefined) {
                $scope.enabled = true;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                type: '@',
                enabled: '=?',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" type="text" data-ng-disabled="!enabled" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBoxTaxId() {
        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                $scope.required = $scope.validationRules.required === true;
                $scope.classes += $scope.validationRules.required === true ? ' required' : '';
            } else {
                $scope.required = false;
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" type="text" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" e-width="100%">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsTextBoxPassportNumber() {
        function link($scope, element) {
            $scope.form = $scope.$parent.form;
            element[0].querySelector('.addId').id = $scope.name;
            $scope.locale = $scope.$root.language;
            if ($scope.locale === 'hu') {
                $scope.locale = 'hu-HU';
            } else if ($scope.locale === 'en') {
                $scope.locale = 'en-GB';
            }
            if ($scope.validationRules !== undefined) {
                $scope.classes = '';
                $scope.required = $scope.validationRules.required === true;
                $scope.classes += $scope.validationRules.required === true ? ' required' : '';
            } else {
                $scope.required = false;
            }
            if ($scope.ctsPlaceholder === undefined) {
                $scope.ctsPlaceholder = $scope.label;
            }
        }

        return {
            restrict: 'E',
            replace: true,
            scope: {
                label: '@',
                name: '@',
                ngModel: '=',
                ctsPlaceholder: '@',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid && (form[name].$dirty || form.$submitted)}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" type="text" placeholder="{{ctsPlaceholder|translate}}" data-ng-model="ngModel" data-ng-required="required" ej-MaskEdit e-locale="locale" e-width="100%">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsListBox() {
        function controller($scope) {
            if ($scope.checkChange() !== undefined) {
                $scope.checkChange2 = function (data) {
                    $scope.checkChange()(data);
                };
            }
        }

        var link = {
            pre: function ($scope, element) {
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                }
                if ($scope.ctsPlaceholder === undefined) {
                    $scope.ctsPlaceholder = $scope.label;
                }
                if ($scope.cssClass === undefined) {
                    $scope.cssClass = '';
                }
                $scope.showCheckbox = $scope.showCheckbox === 'true' || $scope.showCheckbox === true;
                if ($scope.height === undefined) {
                    $scope.height = 300;
                }
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
            }

        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                checkChange: '&',
                cssClass: '@',
                datasource: '=',
                enabled: '=?',
                height: '@',
                label: '@',
                name: '@',
                checkedIndices: '=',
                ctsPlaceholder: '@',
                showCheckbox: '@'
            },
            template: '<div class="form-group cts-form-group">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div class="input-group">' +
            '<ul class="addId" name="{{name}}" ej-listbox e-locale="locale" e-cssClass="cssClass" e-checkedIndices="checkedIndices" e-datasource="datasource" e-showCheckbox="showCheckbox" e-width="100%" e-height="height" placeholder="{{ctsPlaceholder|translate}}" e-enabled="enabled" e-checkChange="checkChange2" e-enableIncrementalSearch="true"></ul>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsNumericTextBox() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()(data);
                };
            }
            if ($scope.focusout() !== undefined) {
                $scope.focusout2 = function (data) {
                    $scope.focusout()(data);
                };
            }
        }

        var link = {
            pre: function ($scope, element) {
                $scope.form = $scope.$parent.form;
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                $scope.classes = '';
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                }
                if ($scope.inputonly === true) {
                    $scope.classes += ' inputonly';
                }
                if (!$scope.decimalplaces) {
                    $scope.decimalplaces = 0;
                } else {
                    $scope.decimalplaces = parseInt($scope.decimalplaces);
                }
                if ($scope.validationRules !== undefined) {
                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                        $scope.$watch(function () {
                            return $scope.value;
                        }, function (newValue, oldValue) {
                            if (oldValue !== undefined && newValue !== oldValue) {
                                if (newValue === '') {
                                    $scope.form[$scope.name].$setValidity('required', false);
                                } else {
                                    $scope.form[$scope.name].$setValidity('required', true);
                                }
                            }
                        });
                        $scope.$on('submitted', function () {
                            if (!$scope.value && $scope.value !== 0) {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                            //$scope.$apply();
                        });
                    }
                    if ($scope.validationRules.minlength !== undefined) {
                        $scope.minlength = $scope.validationRules.minlength;
                    } else {
                        $scope.minlength = 0;
                    }
                    if ($scope.validationRules.maxlength !== undefined) {
                        $scope.maxlength = $scope.validationRules.maxlength;
                    } else {
                        $scope.maxlength = 1000000;
                    }
                } else {
                    $scope.required = false;
                    $scope.minlength = 0;
                    $scope.maxlength = Number.MAX_VALUE;
                }
                if ($scope.ctsPlaceholder === undefined) {
                    $scope.ctsPlaceholder = $scope.label;
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                focusout: '&',
                decimalplaces: '=?',
                enabled: '=?',
                label: '@',
                inputonly: '=?',
                name: '@',
                value: '=?',
                ctsPlaceholder: '@',
                validationRules: '=?',
                tabIndex: '@'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<input class="form-control addId" name="{{name}}" ej-numerictextbox e-locale="locale" e-enableStrictMode="true"  e-watermarkText="{{ctsPlaceholder|translate}}" min="{{minlength}}" max="{{maxlength}}" e-value="value" e-minValue="minlength" e-maxValue="maxlength" e-enabled="enabled" e-width="100%" e-decimalPlaces="decimalplaces" placeholder="{{ctsPlaceholder|translate}}" e-change="change2" e-focusout="focusout2" tabIndex="{{tabIndex}}">' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function ctsRating() {
        function controller($scope) {
            if ($scope.change() !== undefined) {
                $scope.change2 = function (data) {
                    $scope.change()(data);
                };
            }
        }

        var link = {
            pre: function ($scope, element) {
                $scope.form = $scope.$parent.form;
                element[0].querySelector('.addId').id = $scope.name;
                $scope.locale = $scope.$root.language;
                if ($scope.locale === 'hu') {
                    $scope.locale = 'hu-HU';
                } else if ($scope.locale === 'en') {
                    $scope.locale = 'en-GB';
                }
                if ($scope.enabled === undefined) {
                    $scope.enabled = true;
                }
                if ($scope.validationRules !== undefined) {
                    $scope.classes = '';
                    if ($scope.validationRules.required === true) {
                        $scope.classes += ' required';
                        $scope.$watch(function () {
                            return $scope.value;
                        }, function (newValue, oldValue) {
                            if (oldValue !== undefined && newValue !== oldValue) {
                                if (newValue === '') {
                                    $scope.form[$scope.name].$setValidity('required', false);
                                } else {
                                    $scope.form[$scope.name].$setValidity('required', true);
                                }
                            }
                        });
                        $scope.$on('submitted', function () {
                            if (!$scope.value) {
                                $scope.form[$scope.name].$setValidity('required', false);
                            } else {
                                $scope.form[$scope.name].$setValidity('required', true);
                            }
                            //$scope.$apply();
                        });
                    }
                    if ($scope.validationRules.minlength !== undefined) {
                        $scope.minlength = $scope.validationRules.minlength;
                    } else {
                        $scope.minlength = 0;
                    }
                    if ($scope.validationRules.maxlength !== undefined) {
                        $scope.maxlength = $scope.validationRules.maxlength;
                    } else {
                        $scope.maxlength = 1000000;
                    }
                } else {
                    $scope.required = false;
                    $scope.minlength = 0;
                    $scope.maxlength = Number.MAX_VALUE;
                }
            }
        };
        return {
            restrict: 'E',
            replace: true,
            controller: controller,
            scope: {
                change: '&',
                enabled: '=?',
                label: '@',
                name: '@',
                value: '=?',
                validationRules: '=?'
            },
            template: '<div class="form-group cts-form-group {{classes}}" data-ng-class="{error: form[name].$invalid}">' +
            '<label for="{{name}}">{{label|translate}}</label>' +
            '<div>' +
            '<div class="form-control addId" name="{{name}}" ej-rating e-locale="locale" e-value="value" e-minValue="0" e-maxValue="5" e-enabled="enabled" e-change="change2"></div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.required === true" class="error">{{\'Required\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.maxlength === true" class="error">{{\'Maxlength\'|translate}}</span>' +
            '</div>' +
            '<div class="validation-messages">' +
            '<span data-ng-if="form[name].$error.minlength === true" class="error">{{\'Minlength\'|translate}}</span>' +
            '</div>' +
            '</div>' +
            '</div>',
            link: link
        };
    }

    function report(localStorageService, DOMAIN) {
        function Link(scope, element) {
            var report = null;

            function createReportOptions() {
                var authData = localStorageService.get('_authData');
                var authToken = '';
                if (authData) {
                    authToken = authData.access_token;
                }
                return {
                    reportSource: {
                        report: 'Siker2MVC.' + scope.reportName + ', Siker2MVC',
                        parameters: scope.reportParameters
                    },
                    serviceUrl: DOMAIN.baseURL + 'api/reports/',
                    persistSession: true,
                    authenticationToken: authToken,
                    ready: function () {
                        this.refreshReport();
                    }
                };
            }

            function createReport() {
                report = element.telerik_ReportViewer(createReportOptions()).data('telerik_ReportViewer');
            }

            //createReport();

            //var reportNameWatcher = scope.$watch("reportName", updateReport);
            var reportParametersWatcher = scope.$watch('reportParameters', updateReport);

            function updateReport(newVal, oldVal) {
                if (newVal !== oldVal) {
                    createReport();
                    report.reportSource({
                        report: 'Siker2MVC.' + scope.reportName + ', Siker2MVC',
                        parameters: scope.reportParameters
                    });
                    report.refreshReport();
                }
            }

            element.on('$destroy', function () {
                reportParametersWatcher();
            });
        }

        return {
            restrict: 'E',
            scope: {
                reportName: '@',
                reportParameters: '='
            },
            link: Link
        };
    }

    function preventEnterSubmit() {
        return function (scope, el) {
            el.bind('keydown', function (event) {
                if (13 == event.which) {
                    event.preventDefault(); // Doesn't work at all
                    window.stop(); // Works in all browsers but IE...
                    document.execCommand('Stop'); // Works in IE
                    return false; // Don't even know why it's here. Does nothing.
                }
            });
        };
    }

    function ngFileSelect(fileReader) {
        return {
            link: function ($scope, el) {
                $scope.getFile = function (file) {
                    $scope.progress = 0;
                    fileReader.readAsDataURL(file, $scope).then(function (result) {
                        if ($scope.$parent.$$childHead.imageDatas !== undefined) {
                            $scope.$parent.$$childHead.imageDatas.push(result);
                        } else {
                            $scope.imageDatas.push(result);
                        }
                    });
                };

                el.bind('change', function (e) {
                    var items = (e.srcElement || e.target).files;
                    angular.forEach(items, function (file) {
                        if ($scope.$parent.$$childHead.files !== undefined) {
                            $scope.$parent.$$childHead.files.push(file);
                        } else {
                            $scope.files.push(file);
                        }
                        $scope.getFile(file);
                    });
                });
            }

        };
    }

    function fileDropzone(fileReader, log, gettextCatalog) {
        return {
            restrict: 'A',
            link: function (scope, element) {
                function processDragOverOrEnter(event) {
                    if (event != null) {
                        event.preventDefault();
                    }
                    if (event.originalEvent.dataTransfer) {
                        event.originalEvent.dataTransfer.effectAllowed = 'all';
                    }
                    return false;
                }
                element.bind('dragover', processDragOverOrEnter);
                element.bind('dragenter', processDragOverOrEnter);

                scope.getFile = function (file) {
                    $scope.progress = 0;
                    fileReader.readAsDataURL(file, $scope).then(function (result) {
                        $scope.$parent.$$childHead.imageDatas.push(result);
                    });
                };

                return element.bind('drop', function (event) {
                    if (event) {
                        event.preventDefault();
                    }

                    if (event.originalEvent.dataTransfer) {
                        var items = event.originalEvent.dataTransfer.files;
                        var largeError = '';
                        var typeError = '';
                        angular.forEach(items, function (file) {
                            if (file.size <= 4194304) {
                                if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/bmp' || file.type === 'image/gif') {
                                    scope.$parent.$$childHead.files.push(file);
                                    scope.getFile(file);
                                } else {
                                    typeError += file.name + '<br>';
                                }
                            } else {
                                largeError += file.name + '<br>';
                            }
                        });
                        if (largeError) {
                            log.errorMsg(gettextCatalog.getString('FileTooLarge') + '<br><br>' + largeError);
                        }
                        if (typeError) {
                            log.errorMsg(gettextCatalog.getString('FileTypeError') + '<br><br>' + typeError);
                        }
                    }
                    return false;
                });
            }
        };
    }
})();
