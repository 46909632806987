(function () {
    'use strict';

    angular
        .module('app.administration.trainingPlan.trainingPlanList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.trainingPlan.trainingPlanList',
                config: {
                    url: '/trainingPlanList',
                    templateUrl: 'app/administration/trainingPlan/trainingPlanList/trainingPlanList.html',
                    controller: 'TrainingPlanListController',
                    controllerAs: 'vm',
                    title: 'TrainingPlanList',
                    settings: {
                        navId: 2142,
                        level: 2,
                        order: 14,
                        orderTitle_hu: 'Edzéssablon lista',
                        orderTitle_en: 'Training Plan List',
                        parentId: 2,
                        content: 'TrainingPlanList',
                        activatorPermission: ['"MEGTEDZESSABLON"']
                    }
                }
            }
        ];
    }
})();
