(function () {
    'use strict';

    angular
        .module('app.administration.dailyAttendance')
        .controller('DailyAttendanceController', DailyAttendanceController);

    function DailyAttendanceController(log, logger, DOMAIN, paramHelper, $state, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('dailyAttendance');
        vm.presenceText = gettextCatalog.getString('presenceText');
        vm.absenceText = gettextCatalog.getString('absenceText');
        vm.provenabsenceText = gettextCatalog.getString('provenabsenceText');
        vm.competitionText = gettextCatalog.getString('competitionText');
        vm.holidayText = gettextCatalog.getString('holidayText');
        vm.sicknessText = gettextCatalog.getString('sicknessText');
        vm.injuryText = gettextCatalog.getString('injuryText');
        vm.noImgUrl = DOMAIN.baseURL + DOMAIN.userImage;
        var newAthletesId = -1;
        vm.allAgeGroupfill = false;
        vm.coachList = [];
        vm.thereIsIncativeAthlete = false;
        vm.clicked = null;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.saveAttendance = saveAttendance;
        vm.loadAttendance = loadAttendance;
        vm.iconClicked = iconClicked;
        vm.DDClick = DDClick;
        vm.sectionId = undefined;
        vm.ageGroupId = undefined;
        vm.userid = undefined;
        vm.editedAttendance = {};
        vm.dayz = {};
        vm.today = {};
        vm.today.Year = new Date().getFullYear();
        vm.today.Month = new Date().getMonth() + 1;
        vm.today.Day = new Date().getDate();
        var button = {
            UNDEFINED: -1,
            PRESENCE: 1,
            ABSENCE: 2,
            PROVENABSENCE: 3, //22,
            HOLIDAY: 4,
            SICKNESS: 5,
            INJURY: 6,
            COMPETITION: 7, //3,
            NOTPRESSED: 10
        };
        vm.b = button;

        activate();

        function activate() {
            var promises = [authenticationService.getRight('HJFELVIHET'), authenticationService.getRight('MEGTHAVIJEL'), authenticationService.getRight('EDZO'), authenticationService.getRight('SZAKOSZTVEZ'), paramHelper.getParams([], ['dailyAttendance.SzakosztalyId'])];
            $q.all(promises).then(function (results) {
                vm.userid = authenticationService.getAuthData().id;
                if (!(results[1])) {
                    log.permissionError(true);
                    return;
                }
                vm.allowEdit = results[0];
                if (!results[2] && !results[3]) {
                    log.errorMsg(gettextCatalog.getString('YouMustBeACoachOrSectionLeaderToUseDailyAttendance'));
                    log.permissionError(true);
                } else {
                    getCoaches();
                    getSections(vm.userid);
                }
                if (results[4]['dailyAttendance.SzakosztalyId']) {
                    vm.sectionId = results[4]['dailyAttendance.SzakosztalyId'];
                }
            });
        }

        function isMobile() {
            var check = false;
            (function (a) {
                if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                    check = true;
                }
            })(navigator.userAgent || navigator.vendor || window.opera);
            return check;
        }

        function iconClicked(index, setTo) {
            var tetel = vm.editedAttendance.tetelek[index];
            // console.log('iconClicked index:', index, 'Nev:', tetel.SportoloNev, 'prevState: ', tetel.selectedIcon, 'setTo:', setTo);
            //console.log('tetel[index]', tetel);
            // Ha volt már aznap jelenlét, és átkattintok máshova akkor a napi jelenlét értékét csökkentem
            if (setTo != button.PRESENCE && tetel.selectedIcon == button.PRESENCE && tetel.today > 0) {
                tetel.today--;
            }
            // HA ugyanarra kattintok, akkor leveszem a kijelölést
            if (tetel.selectedIcon == setTo) {
                tetel.selectedIcon = button.UNDEFINED;
                // Ha a jelenlét kivan jelölve, és arról szedem le a kijelölést, akkor csökkentem a mellette lévő számot
                if (setTo == button.PRESENCE) {
                    tetel.today--;
                }
            }
            // Ha valamelyik szürkére kattintok
            else {
                // Ha a pipára kattintok
                if (setTo == button.PRESENCE) {
                    // de már volt aznapra jelenlét (1,2,3),
                    if (tetel.today > 0) {
                        // három eddigi napi jelenlétnél már nem lehet több
                        if (tetel.today == 3) {
                            log.htmlError(gettextCatalog.getString('Max3TrainingsPerDay') + ' ' + tetel.SportoloNev);
                            tetel.selectedIcon = button.UNDEFINED;
                        }
                        //  1,2 nél lehet. Akkor a napi jelenlét nő eggyel.
                        else {
                            tetel.today++;
                            tetel.selectedIcon = button.PRESENCE;
                        }
                    }
                    // Pipára kattintok, de ez a mai első jelenlét
                    else {
                        tetel.today = 1;
                        tetel.selectedIcon = button.PRESENCE;
                    }
                }
                // Ha a hiányzásra kattintok, és ma még nem volt edzésen
                else if (setTo == button.ABSENCE) {
                    if (tetel.today == 3) {
                        log.htmlError(gettextCatalog.getString('Max3TrainingsPerDay') + ' ' + tetel.SportoloNev);
                        tetel.selectedIcon = button.UNDEFINED;
                    } else {
                        tetel.selectedIcon = button.ABSENCE;
                    }
                }
                // Ha az igazolt hiányzásra kattintok, és ma még nem volt edzésen
                else if (setTo == button.PROVENABSENCE && !(tetel.today > 0)) {
                    tetel.selectedIcon = button.PROVENABSENCE;
                }
                // Ha a sérülésre vagy betegségre kattintok (az is jelenlétnek számít)
                else if (setTo == button.INJURY || setTo == button.SICKNESS) {
                    tetel.selectedIcon = setTo;
                }
                // Nem a pipára kattintok
                else {
                    tetel.selectedIcon = setTo;
                }
            }
        }

        function loadAttendance() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (!vm.allAgeGroupfill) {
                loadMonthlyAttendance(vm.userid, vm.sectionId, 0, vm.today.Year, vm.today.Month, vm.today.Day);
            } else {
                if (!(vm.ageGroupId > 0)) {
                    log.errorMsg(gettextCatalog.getString('ChooseAgeGroup'));
                    return;
                }
                loadMonthlyAttendance(vm.userid, vm.sectionId, vm.ageGroupId, vm.today.Year, vm.today.Month, vm.today.Day);
            }
        }

        function setIcons() {
            //console.log('vm.editedAttendance', vm.editedAttendance);
            for (var t = 0; t < vm.editedAttendance.tetelek.length; t++) {
                var active = '';
                if (vm.editedAttendance.aktiv[t]['Nap' + vm.today.Day]) {
                    active = vm.editedAttendance.aktiv[t]['Nap' + vm.today.Day].trim();
                }
                if (active == '1') {
                    vm.editedAttendance.tetelek[t].activeToday = true;
                } else {
                    vm.thereIsIncativeAthlete = true;
                    vm.editedAttendance.tetelek[t].activeToday = false;
                }
                vm.editedAttendance.tetelek[t].selectedIcon = button.UNDEFINED;
                vm.editedAttendance.tetelek[t].img = DOMAIN.baseURL + DOMAIN.profileImgBaseUrl + vm.editedAttendance.tetelek[t].img;
                setIconsForToday(t, vm.editedAttendance.tetelek[t]);
            }
        }

        function setIconsForToday(sor, tetel) {
            var nap = tetel['Nap' + vm.today.Day];
            if (nap) {
                nap = nap.trim();
                if (isNaN(nap)) {
                    nap = nap.toUpperCase();
                } else if (nap.length > 0) {
                    //console.log('setIconsForToday Nev:', vm.editedAttendance.tetelek[sor].SportoloNev, '  nap:', nap);
                    nap = Number(nap);
                }
            }
            vm.editedAttendance.tetelek[sor].today = ' ';
            switch (nap) {
                case 'V':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.COMPETITION;
                    break;
                case 'Ü':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.HOLIDAY;
                    break;
                case 'B':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.SICKNESS;
                    break;
                case 'S':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.INJURY;
                    break;
                case 'H':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.PROVENABSENCE;
                    break;
                case 0:
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.ABSENCE;
                    break;
                case 1:
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.NOTPRESSED;
                    vm.editedAttendance.tetelek[sor].today = 1;
                    break;
                case 2:
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.NOTPRESSED;
                    vm.editedAttendance.tetelek[sor].today = 2;
                    break;
                case 3:
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.NOTPRESSED;
                    vm.editedAttendance.tetelek[sor].today = 3;
                    break;
                case '':
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.NOTPRESSED;
                    break;
                default:
                    vm.editedAttendance.tetelek[sor].selectedIcon = button.NOTPRESSED;
            }
        }

        /*
                Gomb állapotok:
                -1      Marad az előző állapot
				1 - 1++ presence  	    (jelenlét)
				2 - 0   absence     	(hiányzás)
                3 - H   provenabsence   (igazolt hiányzás)
				4 - Ü   holiday     	(ünnep)
				5 - B   sickness    	(betegség)
				6 - S   injury      	(sérülés)
				7 - V   competition 	(verseny)
                10 - visszatöltésnél ha van már jelenlét
        */

        function loadMonthlyAttendance(coach, section, agegroup, year, month) {
            //console.log('loadMonthlyAttendance', coach, section, agegroup, year, month, day);
            dataservice.monthlyAttendanceSelect(coach, section, agegroup, year, month)
                .then(function (data) {
                    if (data.fej) {
                        if (data.fej.EdzoAlairta) {
                            log.errorMsg(gettextCatalog.getString('MonthlyAttendanceIsAlreadyClosed'));
                            return;
                        }
                        vm.editedAttendance = data;
                        console.log('EXISTING vm.editedAttendance.tetelek', vm.editedAttendance.tetelek);
                        setIcons();
                    } else {
                        createNewAttendance();
                    }
                });
        }

        function createNewAttendance() {
            dataservice.getLastMonthlyAttendanceTrainingLocationAndTime(vm.userid, vm.sectionId, vm.ageGroupId).then(function (data) {
                vm.editedAttendance.fej = {};
                vm.editedAttendance.fej.CsoportLetszam = 0;
                vm.editedAttendance.fej.Datum = new Date(vm.today.Year, vm.today.Month - 1, 1, 0, 0, 0);
                vm.editedAttendance.fej.Erkezett = 0;
                vm.editedAttendance.fej.Id = -1;
                vm.editedAttendance.fej.IdEdzo = vm.userid;
                vm.editedAttendance.fej.IdKorcsoport = vm.ageGroupId;
                vm.editedAttendance.fej.IdSzakosztaly = vm.sectionId;
                vm.editedAttendance.fej.KorosztalyosToltes = vm.allAgeGroupfill;
                vm.editedAttendance.fej.Megjegyzes = '';
                vm.editedAttendance.fej.Tavozott = 0;
                //vm.editedAttendance.fej.EdzoNev = angular.element('#maEdzo').data('ejDropDownList').getSelectedItem()[0].innerText;
                vm.editedAttendance.fej.SzakosztalyNev = angular.element('#sectionDropDown').data('ejDropDownList').getSelectedItem()[0].innerText;
                if (angular.element('#ageGroupDropDown').data('ejDropDownList') && angular.element('#ageGroupDropDown').data('ejDropDownList').getSelectedItem().length > 0) {
                    vm.editedAttendance.fej.KorcsoportNev = angular.element('#ageGroupDropDown').data('ejDropDownList').getSelectedItem()[0].innerText;
                } else {
                    vm.editedAttendance.fej.KorcsoportNev = 0;
                    vm.ageGroupId = 0;
                }
                if (data !== null) {
                    vm.dayz.CsutortokHely = data.CsutortokHely;
                    vm.dayz.CsutortokIdo = data.CsutortokIdo;
                    vm.dayz.HetfoHely = data.HetfoHely;
                    vm.dayz.HetfoIdo = data.HetfoIdo;
                    vm.dayz.IdHaviJelenletiFej = -1;
                    vm.dayz.KeddHely = data.KeddHely;
                    vm.dayz.KeddIdo = data.KeddIdo;
                    vm.dayz.PentekHely = data.PentekHely;
                    vm.dayz.PentekIdo = data.PentekIdo;
                    vm.dayz.SzerdaHely = data.SzerdaHely;
                    vm.dayz.SzerdaIdo = data.SzerdaIdo;
                    vm.dayz.SzombatHely = data.SzombatHely;
                    vm.dayz.SzombatIdo = data.SzombatIdo;
                    vm.dayz.VasarnapHely = data.VasarnapHely;
                    vm.dayz.VasarnapIdo = data.VasarnapIdo;
                } else {
                    vm.dayz.CsutortokHely = '';
                    vm.dayz.CsutortokIdo = '';
                    vm.dayz.HetfoHely = '';
                    vm.dayz.HetfoIdo = '';
                    vm.dayz.IdHaviJelenletiFej = -1;
                    vm.dayz.KeddHely = '';
                    vm.dayz.KeddIdo = '';
                    vm.dayz.PentekHely = '';
                    vm.dayz.PentekIdo = '';
                    vm.dayz.SzerdaHely = '';
                    vm.dayz.SzerdaIdo = '';
                    vm.dayz.SzombatHely = '';
                    vm.dayz.SzombatIdo = '';
                    vm.dayz.VasarnapHely = '';
                    vm.dayz.VasarnapIdo = '';
                }
                vm.editedAttendance.edzesek = vm.dayz;
                dataservice.getAthletesForMonthlyAttendance(vm.userid, vm.sectionId, vm.ageGroupId, vm.editedAttendance.fej.Datum)
                    .then(function (data2) {
                        if (data2.tetelek.length == 0) {
                            log.errorMsg(gettextCatalog.getString('ThereAreNoAthletesAtTheSelectedTimeForThisCoach'));
                        } else {
                            vm.data = data2.tetelek;
                            vm.editedAttendance.tetelek = vm.data;
                            console.log('UJ vm.editedAttendance.tetelek', vm.editedAttendance.tetelek);
                            vm.editedAttendance.aktiv = data2.aktiv;
                            vm.activePeriods = data2.aktiv;
                            setIcons();
                        }
                    });
            });
        }

        function chechButtonStates() {
            for (var t = 0; t < vm.editedAttendance.tetelek.length; t++) {
                vm.editedAttendance.tetelek[t].JelenletOsszesen = -1;
                vm.editedAttendance.tetelek[t].HianyzasOsszesen = -1;
                vm.editedAttendance.tetelek[t].VersenyOsszesen = -1;
                var pressedButton = Number(vm.editedAttendance.tetelek[t].selectedIcon);
                if (vm.editedAttendance.tetelek[t].activeToday) {
                    //console.log('chechButtonStates', vm.editedAttendance.tetelek[t].SportoloNev, pressedButton);
                    switch (pressedButton) {
                        case button.PRESENCE:
                            //console.log('PRESENCE', vm.editedAttendance.tetelek[t]);
                            if (vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day]) {
                                if (isNaN(vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day])) {
                                    vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 1;
                                } else {
                                    if (vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] == 3) {
                                        log.htmlError(gettextCatalog.getString('Max3TrainingsPerDay') + ' ' + vm.editedAttendance.tetelek[t].SportoloNev);
                                        return false;
                                    } else {
                                        vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day]++;
                                    }
                                }
                            } else {
                                vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 1;
                            }
                            break;
                        case button.ABSENCE:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = '0';
                            break;
                        case button.COMPETITION:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 'V';
                            break;
                        case button.HOLIDAY:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 'Ü';
                            break;
                        case button.SICKNESS:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 'B';
                            break;
                        case button.INJURY:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 'S';
                            break;
                        case button.PROVENABSENCE:
                            vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = 'H';
                            break;
                    }
                } else {
                    vm.editedAttendance.tetelek[t]['Nap' + vm.today.Day] = null;
                }
            }
            return true;
        }

        function saveAttendance() {
            if (chechButtonStates()) {
                saveAttendanceOK();
            }
        }

        function saveAttendanceOK() {
            dataservice.saveDailyAttendance(vm.editedAttendance).then(function (id) {
                if (!isMobile()) {
                    paramHelper.setParams([{'monthlyAttendance.id': id.res}]);
                    $state.go('administration.monthlyAttendance.monthlyAttendanceReport');
                } else {
                    $state.go('dashboard');
                }
            });
        }

        function getSections(userid) {
            dataservice.sectionDropDownList(userid)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                $('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
            paramHelper.setParams([{'dailyAttendance.SzakosztalyId': args.value}]);
        }

        function getAgeGroups() {
            dataservice.ageGroupSelect(vm.userid, vm.sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                    if (vm.userid > 0 && vm.ageGroupList.length == 1 && vm.sectionList.length == 1) {
                        setTimeout(function () {
                            loadAttendance();
                        }, 1000);
                    }
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                vm.ageGroupId = vm.ageGroupList[0].value;
            } else {
                vm.ageGroupId = 0;
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function getCoaches() {
            dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                    var item = ej.DataManager(vm.coachList).executeLocal(new ej.Query().where('value', 'equal', vm.userid))[0];
                    if (item) {
                        vm.allAgeGroupfill = item.HaviJelenletiKorosztalyonkent;
                    }
                }
            );
        }

        function DDClick(sportolo, action) {
            // console.log('DDClick sportolo=', sportolo, '  action=', action, 'coordinates=',vm.coordinates);
            if (sportolo == -1) {
                if (vm.clicked) {
                    var now = new Date();
                    var diff = now - vm.clicked;
                    if (diff > 100) { // mert egymas utan ketszer fut le, eloszor a sportoloid-vel, utana -1 el. 100ms felett valodi kattintas
                        CloseEveryOtherDropDowns(sportolo);
                    }
                    if (diff > 310) {
                        $('#containerDiv').animate({marginBottom: '0px'}, 300);
                    }
                }
                return;
            } else {
                $('#containerDiv').animate({marginBottom: '0px'}, 300);
                vm.clicked = new Date();
                CloseEveryOtherDropDowns(sportolo);
            }
            var SportoloItem = ej.DataManager(vm.editedAttendance.tetelek).executeLocal(new ej.Query().where('IdSportolo', 'equal', sportolo))[0];
            if (SportoloItem.hasOwnProperty('open') == false) {
                SportoloItem.open = false;
            }
            switch (action) {
                case 'open':
                    SportoloItem.open = true;
                    $('#DDL' + sportolo).addClass('open');
                    break;
                case 'close':
                    $('#DDL' + sportolo).removeClass('open');
                    SportoloItem.open = false;
                    break;
                case 'toggle':
                    if (SportoloItem.open === true) {
                        $('#DDL' + sportolo).removeClass('open');
                        SportoloItem.open = false;
                    } else {
                        $('#DDL' + sportolo).addClass('open');
                        SportoloItem.open = true;
                    }
                    break;
            }
            if (sportolo == vm.editedAttendance.tetelek[vm.editedAttendance.tetelek.length - 1].IdSportolo && SportoloItem.open) {
                $('#containerDiv').animate({marginBottom: '230px'}, 300);
            }
            if (sportolo > 0 && SportoloItem.open) {
                setTimeout(function () {
                    ScrollDownIfNeeded(sportolo, vm.coordinates);
                }, 250);
            }
        }

        function ScrollDownIfNeeded(sportolo) {
            var DropDownSize = 290;
            var ImgPos = $('#IMG_' + sportolo).offset();
            var DdPosStart = ImgPos.top + 164;
            var DdPosEnd = DdPosStart + DropDownSize;
            var TopOfPage = $(window).scrollTop();
            var BottomOfPage = TopOfPage + window.screen.height;
            var diff = BottomOfPage - DdPosEnd;
            if (diff < 0) {
                $('html, body').stop().animate({scrollTop: TopOfPage - diff + 70}, 300);
            }
        }

        function CloseEveryOtherDropDowns(except) {
            vm.editedAttendance.tetelek.forEach(function (athlete) {
                if (athlete.IdSportolo !== except) {
                    $('#DDL' + athlete.IdSportolo).removeClass('open');
                    athlete.open = false;
                }
            });
        }
    }
})();
