(function () {
    'use strict';

    angular
        .module('app.administration.trainingPlan.trainingPlanList')
        .controller('TrainingPlanListController', TrainingPlanListController);

    function TrainingPlanListController(log, paramHelper, dataservice, $timeout, $q, $rootScope, $filter, DOMAIN, gettextCatalog, authenticationService, $scope, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('TrainingPlanList');

        vm.imageRoot = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl;
        vm.gridDataSource = [];
        vm.sectionId = 0;
        vm.sectionList = [];
        vm.ageGroupId = 0;
        vm.ageGroupList = [];
        vm.coachId = 0;
        vm.coachList = [];
        vm.openPermission = false;
        vm.addNewPermission = false;
        vm.deletePermission = false;

        //events
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.createNewTrainingPlan = createNewTrainingPlan;
        vm.openTrainingPlan = openTrainingPlan;
        vm.deleteTrainingPlan = deleteTrainingPlan;
        vm.search = search;

        vm.columns = [
            {type: 'checkbox', headerText: '', textAlign: 'center', width: 55},
            {field: 'Link', headerText: '', textAlign: 'center', width: 60, template: '#wtro'},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 120},
            {field: 'Ido', headerText: gettextCatalog.getString('TrainingTimeLength'), textAlign: 'center', width: 120},
            {field: 'SzakosztalyId', visible: false},
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 120},
            {field: 'KorosztalyId', visible: false},
            {field: 'Korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 120},
            {field: 'EdzoId', visible: false},
            {field: 'Edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 120},
            {field: 'Leiras', headerText: gettextCatalog.getString('Description'), textAlign: 'center', width: 120},
            {field: 'Publikus', headerText: gettextCatalog.getString('Public'), type: 'boolean', displayAsCheckBox: true, textAlign: 'center', width: 100}
        ];

        //gyakorlatok
        vm.childGridColums = [
            {field: 'EdzesFejId', visible: false},
            {field: 'GyakorlatId', isPrimaryKey: true, visible: false},
            {field: 'GyakorlatKategoria', headerText: gettextCatalog.getString('GyakorlatKategoria'), textAlign: 'center', width: 120},
            {field: 'Nehezseg', headerText: gettextCatalog.getString('Nehezseg'), textAlign: 'center', width: 100},
            {field: 'Ido', headerText: gettextCatalog.getString('TimeLength'), textAlign: 'center', width: 100},
            {field: 'IsmSzam', headerText: gettextCatalog.getString('IsmSzam'), textAlign: 'center', width: 100},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'center', template: '#wtpropentemp', width: 180},
            {field: 'GyakorlatTipus', headerText: gettextCatalog.getString('GyakorlatTipus'), textAlign: 'center', width: 120},
            {field: 'Mintakep', headerText: gettextCatalog.getString('Pattern'), width: 120, textAlign: 'center', template: '#imagetemplateGrid'},
            {field: 'Video', headerText: gettextCatalog.getString('YouTube'), textAlign: 'center', width: 100, type: 'boolean', displayAsCheckBox: true},
            {field: 'Jellege', headerText: gettextCatalog.getString('individualTeam'), textAlign: 'center', width: 100},
            {field: 'Testresz', headerText: gettextCatalog.getString('BodyParts'), textAlign: 'center', width: 120},
        ];

        var Grid = new ej2.grids.Grid({
            dataSource: [],
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            //allowPaging: true,
            //pageSettings: $rootScope.pageSettings,
            toolbar: ['Print', 'Search'],
            columns: vm.columns,
            childGrid: {
                dataSource: [],
                queryString: 'EdzesFejId',
                columns: vm.childGridColums,
                allowTextWrap: true,
                allowPaging: true,
                pageSettings: $rootScope.pageSettings,
                rowDataBound: function (args) {
                    $rootScope.compile(args.row, $scope);
                }
            },
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelecting: function () {
                Grid.clearSelection();
            },
            checkBoxChange: function (args) {
                $rootScope.checkBoxChangeOnlyOne(args, Grid);
            }
        });

        Grid.appendTo('#Grid');

        activate();
        function activate() {
            $q.all([authenticationService.getRight('MEGTEDZESSABLON'), authenticationService.getRight('MODEDZESSABLON'), authenticationService.getRight('UJEDZESSABLON'), authenticationService.getRight('TOROLEDZESSABLON'), paramHelper.getParams([], ['trainPlanList.SzakosztalyId', 'trainPlanList.KorosztalyId', 'trainPlanList.EdzoId'])]).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    $timeout(function () {
                        vm.openPermission = results[0];
                        vm.modifyPermission = results[1];
                        vm.addNewPermission = results[2];
                        vm.deletePermission = results[3];
                        $scope.$apply();
                    }, 1);
                    getSections();
                    getAgeGroups();
                    vm.sectionId = results[4]['trainPlanList.SzakosztalyId'];
                    vm.ageGroupId = results[4]['trainPlanList.KorosztalyId'];
                    vm.coachId = results[4]['trainPlanList.EdzoId'];
                }
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0, false)
                .then(function (data) {
                    if (data.itemsList && data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList && vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
            }
            angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
        }

        // Run after section selected
        function sectionSelect(args) {
            vm.sectionId = args.value;
            getAgeGroups();
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList && vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        // Run after age group selected
        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getActualCoachesList();
        }

        function getActualCoachesList() {
            return dataservice.coachDropDownList(vm.sectionId, 0).then(function (data) {
                if (data.itemsList && data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList && vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        // Run after age coach selected
        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function openTrainingPlan(Id) {
            if (vm.openPermission !== true) {
                log.permissionError(true);
                return;
            }

            paramHelper.removeParam('trainingPlanCreate.sectionId');
            paramHelper.removeParam('trainingPlanCreate.ageGroupId');
            paramHelper.setParams([{'trainingPlanCreate.trainingId': Id}]);
            $state.go('administration.trainingPlan.trainingPlanCreate');
        }

        function createNewTrainingPlan() {
            if (vm.addNewPermission !== true) {
                log.permissionError(true);
                return;
            }
            paramHelper.setParams([{'trainPlanList.SzakosztalyId': vm.sectionId}]);
            paramHelper.setParams([{'trainPlanList.KorosztalyId': vm.ageGroupId}]);
            paramHelper.setParams([{'trainPlanList.EdzoId': vm.coachId}]);
            paramHelper.setParams([{'trainingPlanCreate.trainingId': 0}, {'trainingPlanCreate.sectionId': vm.sectionId}, {'trainingPlanCreate.ageGroupId': vm.ageGroupId}]);
            $state.go('administration.trainingPlan.trainingPlanCreate');
        }

        function deleteTrainingPlan() {
            var selectedRow = Grid.getSelectedRecords();
            if (!selectedRow || selectedRow.length == 0) {
                log.errorMsg('CantDeleted');
                return;
            }
            if (vm.deletePermission !== true) {
                log.permissionError(true);
                return;
            }
            swal({
                title: gettextCatalog.getString('Are you sure you want to delete the selected training plan?'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function(isConfirm) {
                if (isConfirm.value) {
                    dataservice.DeleteTrainingPlan(selectedRow[0].EdzesFejId)
                        .then(function (response) {
                            if (response.retValue == true) {
                                log.successMsg('trainingPlanDeleted');
                                search();
                            }
                        }, function () {
                        });
                }
            });
        }

        function search() {
            paramHelper.setParams([{'trainPlanList.SzakosztalyId': vm.sectionId}]);
            paramHelper.setParams([{'trainPlanList.KorosztalyId': vm.ageGroupId}]);
            paramHelper.setParams([{'trainPlanList.EdzoId': vm.coachId}]);
            dataservice.getTrainingPlanList(vm.sectionId, vm.ageGroupId, vm.coachId)
                .then(function (response) {
                    Grid.dataSource = response.itemsList;
                    Grid.childGrid.dataSource = $filter('addImagePathToObject')(response.Gyakorlatok, 'Mintakep', vm.imageRoot);
                    $timeout(function () {
                        $('.thumbnail-item').magnificPopup({
                            type: 'image'
                        });
                    }, 300);
                });
        }

        vm.openTemplate = openTemplate;
        function openTemplate(exerciseId) {
            paramHelper.setParams([{'exercises.exerciseId': exerciseId}]);
            $state.go('administration.exercises.exercisesCreate');
        }
    }
})();
