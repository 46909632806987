(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.monthlyAttendanceReports')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.managementReports.monthlyAttendanceReports',
                config: {
                    url: '/monthlyAttendanceReports',
                    templateUrl: 'app/reports/managementReports/monthlyAttendanceReports/monthlyAttendanceReports.html',
                    controller: 'MonthlyAttendanceReportsController',
                    controllerAs: 'vm',
                    title: 'MonthlyAttendance',
                    settings: {
                        navId: 32244,
                        level: 3,
                        order: 2,
                        orderTitle_hu: 'Havi jelenléti ívek',
                        orderTitle_en: 'Monthly Attendance Sheets',
                        parentId: 244,
                        content: 'MonthlyAttendance',
                        activatorPermission: ['"VEZETOIHJ"']
                    }
                }
            }
        ];
    }
})();
