(function () {
    'use strict';

    angular
        .module('app.administration.resultReportAthletes')
        .controller('ResultReportAthletesController', ResultReportAthletesController);

    function ResultReportAthletesController($q, dataservice, log, DOMAIN, paramHelper, $scope, authenticationService, gettextCatalog, $rootScope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ResultReportAthletes');
        vm.deleted = false;
        vm.editedReportResult = {};
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.athleteList = [];
        vm.athleteListPartner1 = [];
        vm.athleteListPartner2 = [];
        vm.athleteListPartner3 = [];
        vm.noImage = '';
        vm.pageSize = 12;
        vm.defaultSection = -1;
        vm.competitionList = [];
        vm.EredmenyIdo = '';
        vm.noImage = DOMAIN.baseURL + DOMAIN.noImage;
        vm.editedReportResult.Foto = vm.noImage;
        vm.editable = false;
        vm.CanCreateNew = true;
        vm.CanModify = true;
        vm.selectedRowIndexPopup = -1;
        vm.columnsPopup = [];

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.closeEarlierResultReportAthletesWindow = closeEarlierResultReportAthletesWindow;
        vm.selectEarlierResultReportAthletesWindow = selectEarlierResultReportAthletesWindow;

        vm.cmChange = cmChange;
        vm.mChange = mChange;

        function cmChange() {
            var cm = vm.editedReportResult.EredmenyTavolsag;
            if (vm.editedReportResult.EredmenyTavolsagM != cm / 100) {
                vm.editedReportResult.EredmenyTavolsagM = cm / 100;
            }
        }

        function mChange() {
            if (vm.editedReportResult.EredmenyTavolsagM && vm.editedReportResult.EredmenyTavolsagM.length > 0) {
                vm.editedReportResult.EredmenyTavolsagM = vm.editedReportResult.EredmenyTavolsagM.replace(',', '.');
            }
            var m = vm.editedReportResult.EredmenyTavolsagM;
            if (vm.editedReportResult.EredmenyTavolsag != m * 100) {
                vm.editedReportResult.EredmenyTavolsag = Math.round(m * 100);
            }
        }

        vm.columns = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'Filenev', headerText: gettextCatalog.getString('Filename'), textAlign: 'center', allowEditing: false, template: '#rereattemplateDataColumn1'},
            {field: 'Datum', headerText: gettextCatalog.getString('Date'), textAlign: 'center', allowEditing: false}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: ['Delete'],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            editSettings: {allowDeleting: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
                if (args.requestType == 'delete') {
                    return dataservice.deleteFiles(args.data[0].Id).then(function () {
                        log.successMsg(gettextCatalog.getString('FileDeleted'));
                    });
                }
            },
        });
        grid.appendTo('#Grid');

        vm.columnsPopup = [
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'VersenyNeve', headerText: gettextCatalog.getString('CompetitionName'), textAlign: 'center', width: 125, allowEditing: false},
            {field: 'Versenyszam', headerText: gettextCatalog.getString('Versenyszam'), textAlign: 'center', width: 125, allowEditing: false},
            {field: 'Szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 125, allowEditing: false},
            {field: 'Korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 100, allowEditing: false},
            {field: 'SportoloCsapat', headerText: gettextCatalog.getString('Athlete'), textAlign: 'center', width: 150, allowEditing: false},
            {field: 'Datum', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125, allowEditing: false}
        ];

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            height: 400,
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            }
        });
        gridPopup.appendTo('#GridPopup');

        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.athleteDataBound = athleteDataBound;
        vm.athleteSelect = athleteSelect;
        vm.athleteDataBoundPartner = athleteDataBoundPartner;
        vm.athleteDataBoundPartner2 = athleteDataBoundPartner2;
        vm.athleteDataBoundPartner3 = athleteDataBoundPartner3;
        vm.athleteSelectPartner = athleteSelectPartner;
        vm.athleteSelectPartner2 = athleteSelectPartner2;
        vm.athleteSelectPartner3 = athleteSelectPartner3;
        vm.goToOlderAnnualSeasonPlanReports = goToOlderAnnualSeasonPlanReports;
        vm.openCropDialog = openImgDialog;
        vm.deleteImg = deleteImg;
        vm.deleteFile = deleteFile;
        vm.createResultReport = createResultReport;
        vm.modifyResultReport = modifyResultReport;
        vm.saveResultReport = saveResultReport;
        vm.clearTars1 = clearTars1;
        vm.clearTars2 = clearTars2;
        vm.clearTars3 = clearTars3;

        activate();

        function activate() {
            generateUploadBox();
            $q.all([paramHelper.getParams([], ['resultReportAthletes.Id']),
                    authenticationService.getRight('MODEERJEL'),
                    authenticationService.getRight('UJEERJEL'),
                    authenticationService.getRight('MEGTEERJEL')])
                .then(function (results) {
                    vm.MODEERJEL = results[1];
                    vm.UJEERJEL = results[2];
                    vm.MEGTEERJEL = results[3];

                    if (!vm.MEGTEERJEL) {
                        log.permissionError(true);
                        return;
                    }

                    vm.CanCreateNew = ((!vm.editable) && vm.UJEERJEL);
                    vm.CanModify = ((!vm.editable) && vm.MODEERJEL);
                    //létezőt nyit vissza
                    if (results[0]['resultReportAthletes.Id'] && results[0]['resultReportAthletes.Id'] !== -1) {
                        getReportAthleteById(results[0]['resultReportAthletes.Id']).then(function () {
                            paramHelper.removeParam('resultReportAthletes.Id');
                        });
                    } else {
                        createResultReport();
                        getSections();
                    }
                });
        }

        function getReportAthleteById(Id) {
            return dataservice.resultReportAthletesSelectById(Id)
                .then(function (data) {
                    vm.editedReportResult = data.item;
                    vm.defaultSection = data.item.SzakosztalyId;
                    vm.defaultAgeGroup = data.item.KorcsoportId;
                    vm.defaultAthlete = data.item.SportoloId;
                    vm.defaultAthletePartner = data.item.TarsEredmenySportolo;
                    vm.defaultAthletePartner2 = data.item.TarsEredmenySportolo2;
                    vm.defaultAthletePartner3 = data.item.TarsEredmenySportolo3;
                    if (!data.item.Foto || data.item.Foto == 'noImage.png' || data.item.Foto == 'noImg.jpg') {
                        vm.editedReportResult.Foto = DOMAIN.baseURL + DOMAIN.noImage;
                    } else {
                        vm.editedReportResult.Foto = DOMAIN.baseURL + DOMAIN.resultReportImgBaseUrl + data.item.Foto;
                    }
                    getSections();
                    getFiles(Id);
                });
        }

        function generateUploadBox() {
            angular.element('#UploadDefault').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUpload',
                removeUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileRemove',
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                autoUpload: true,
                enabled: true,
                buttonText: {
                    browse: gettextCatalog.getString('ChooseFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf,.xls, .mp4, .avi',
                complete: function (args) {
                    if (args.responseText === 'true') {
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        getFiles(vm.editedReportResult.Id);
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf,.xls,.mp4,.avi extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdfXls');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'JelentokEgyeni', tablaId: vm.editedReportResult.Id};
                }
            });
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.defaultSection);
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.editedReportResult.SzakosztalyId = args.value;
            getAgeGroups(args.value);
            getCharacterOfACompetitionList(args.value);
        }

        //korcsoportok lekérése
        function getAgeGroups(szakosztalyId) {
            return dataservice.ageGroupDropDownList(szakosztalyId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function getCharacterOfACompetitionList(szakosztalyId) {
            var csapat = false;
            return dataservice.getCharacterOfACompetitionList(csapat, szakosztalyId)
                .then(function (data) {
                    vm.competitionList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.defaultAgeGroup);
            }
        }

        function getAthletes(KorcsoportId) {
            return dataservice.athleteDropDownListSelect(vm.editedReportResult.SzakosztalyId, KorcsoportId, vm.defaultAthlete, vm.defaultAthletePartner)
                .then(function (data) {
                    vm.athleteList = data.itemsList;
                    for (var i = 0; i < vm.athleteList.length; i++) {
                        vm.athleteListPartner1[i] = {value: data.itemsList[i].value, text: data.itemsList[i].text};
                        vm.athleteListPartner2[i] = {value: data.itemsList[i].value, text: data.itemsList[i].text};
                        vm.athleteListPartner3[i] = {value: data.itemsList[i].value, text: data.itemsList[i].text};
                    }
                });
        }

        function ageGroupSelect(args) {
            vm.editedReportResult.KorcsoportId = args.value;
            getAthletes(args.value);
        }

        function athleteDataBound() {
            if (vm.athleteList.length === 1) {
                angular.element('#maSportolo').ejDropDownList('selectItemByValue', vm.athleteList[0].value);
            } else {
                angular.element('#maSportolo').ejDropDownList('selectItemByValue', vm.editedReportResult.SportoloId);
            }
        }

        function excludeFromPartnerList(sportoloid) {
            vm.athleteListPartner1 = vm.athleteListPartner1.filter(function (a) {
                return a.value != sportoloid;
            });
        }

        function athleteDataBoundPartner() {
            angular.element('#maTarsSportolo').ejDropDownList('selectItemByValue', vm.defaultAthletePartner);
        }

        function athleteDataBoundPartner2() {
            angular.element('#maTarsSportolo2').ejDropDownList('selectItemByValue', vm.defaultAthletePartner2);
        }

        function athleteDataBoundPartner3() {
            angular.element('#maTarsSportolo3').ejDropDownList('selectItemByValue', vm.defaultAthletePartner3);
        }

        function athleteSelect(args) {
            vm.editedReportResult.SportoloId = args.value;
            vm.athleteListPartner1 = vm.athleteListPartner1.filter(function (a) {
                return a.value != args.value;
            });
        }

        function athleteSelectPartner(args) {
            vm.editedReportResult.TarsEredmenySportolo = args.value;
            vm.athleteListPartner2 = vm.athleteListPartner1.filter(function (a) {
                return a.value != args.value;
            });
        }

        function athleteSelectPartner2(args) {
            vm.editedReportResult.TarsEredmenySportolo2 = args.value;
            vm.athleteListPartner3 = vm.athleteListPartner2.filter(function (a) {
                return a.value != args.value;
            });
        }

        function athleteSelectPartner3(args) {
            vm.editedReportResult.TarsEredmenySportolo3 = args.value;
        }

        function clearTars1() {
            vm.editedReportResult.TarsEredmenySportolo = undefined;
            $('#maTarsSportolo').ejDropDownList('clearText');
            clearTars2();
            clearTars3();
        }

        function clearTars2() {
            vm.editedReportResult.TarsEredmenySportolo2 = undefined;
            $('#maTarsSportolo2').ejDropDownList('clearText');
            clearTars3();
        }

        function clearTars3() {
            vm.editedReportResult.TarsEredmenySportolo3 = undefined;
            $('#maTarsSportolo3').ejDropDownList('clearText');
        }

        function goToOlderAnnualSeasonPlanReports() {
            vm.deleted = false;

            if (angular.isUndefined(vm.editedReportResult.SzakosztalyId) || vm.editedReportResult.SzakosztalyId === null || vm.editedReportResult.SzakosztalyId === -1) {
                swal({
                    title: gettextCatalog.getString('Error'),
                    text: gettextCatalog.getString('LetHimGrantAtLeastASection'),
                    type: 'error',
                    confirmButtonText: gettextCatalog.getString('Ok')
                });
            } else {
                vm.selectedRowIndexPopup = -1;
                angular.element('#searchWindow').data('ejDialog').open();
                dataservice.resultReportAthletesOlderReports(vm.editedReportResult.SzakosztalyId, vm.editedReportResult.KorcsoportId, true).then(function (args) {
                    gridPopup.dataSource = args.itemsList;
                });
            }
        }

        function openImgDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function createResultReport() {
            vm.editedReportResult = {};
            vm.editedReportResult.Id = -1;
            vm.editedReportResult.DatumIg = null;
            vm.editedReportResult.DatumTol = null;
            vm.editedReportResult.EdzoId = null;
            vm.editedReportResult.EdzoNeve = null;
            vm.editedReportResult.Eredmeny = 3;
            vm.editedReportResult.EredmenyIdo = null;
            vm.editedReportResult.EredmenySzoveg = null;
            vm.editedReportResult.EredmenyTavolsag = null;
            vm.editedReportResult.Ertekeles = null;
            vm.editedReportResult.Foto = vm.noImage;
            vm.editedReportResult.Helyezes = null;
            vm.editedReportResult.Helyszin = null;
            vm.editedReportResult.Hivatalos = false;
            vm.editedReportResult.Jellege = null;
            vm.editedReportResult.KorcsoportId = null;
            vm.editedReportResult.KorcsoportNeve = null;
            vm.editedReportResult.SportoloId = null;
            vm.editedReportResult.SportoloNev = null;
            vm.editedReportResult.SzakosztalyId = null;
            vm.editedReportResult.SzakosztalyNeve = null;
            vm.editedReportResult.SzuletesiIdo = null;
            vm.editedReportResult.TarsEredmenySportolo = null;
            vm.editedReportResult.TarsEredmenySportolo2 = null;
            vm.editedReportResult.TarsEredmenySportolo3 = null;
            vm.editedReportResult.VersenyLeiras = null;
            vm.editedReportResult.fileData = null;
            vm.editedReportResult.fileName = null;
            grid.dataSource = [];
            grid.editSettings.allowDeleting = true;

            vm.editedReportResult.SzakosztalyId = 0;
            vm.editedReportResult.KorcsoportId = 0;
            vm.editedReportResult.SportoloId = 0;
            vm.editedReportResult.TarsEredmenySportolo = 0;
            vm.editedReportResult.TarsEredmenySportolo2 = 0;
            vm.editedReportResult.TarsEredmenySportolo3 = 0;
            // angular.element('#maSzakosztaly').ejDropDownList('clearText');
            // angular.element('#maKorosztaly').ejDropDownList('clearText');
            // angular.element('#maSportolo').ejDropDownList('clearText');
            // angular.element('#maTarsSportolo').ejDropDownList('clearText');
            $timeout(function () {
                vm.editable = true;
                vm.CanCreateNew = false;
                vm.CanModify = false;
                $scope.$apply();
            });
        }

        function modifyResultReport() {
            vm.editable = true;
            grid.editSettings.allowDeleting = true;
            angular.element('#UploadDefault').ejUploadbox('enable');
            vm.CanCreateNew = false;
            vm.CanModify = false;
        }

        function saveResultReport() {
            $scope.$broadcast('submitted');
            if ((vm.editedReportResult.EredmenyIdo == null || angular.isUndefined(vm.editedReportResult.EredmenyIdo) || vm.editedReportResult.EredmenyIdo === '__:__:__.___') &&
                (vm.editedReportResult.EredmenySzoveg == null || angular.isUndefined(vm.editedReportResult.EredmenySzoveg) || vm.editedReportResult.EredmenySzoveg === '') &&
                (vm.editedReportResult.EredmenyTavolsag == null || angular.isUndefined(vm.editedReportResult.EredmenyTavolsag) || vm.editedReportResult.EredmenyTavolsag === '') &&
                (vm.editedReportResult.Eredmeny == null || angular.isUndefined(vm.editedReportResult.Eredmeny) || vm.editedReportResult.Eredmeny === '')
            ) {
                log.errorMsg('LegalabbEgyEredmenytAdjonMeg');
                return;
            }
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            // kép küldése, ha választott
            if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                vm.editedReportResult.fileData = vm.cropper.croppedImage;
                vm.editedReportResult.fileName = angular.element('#fileName').val();
            }
            $timeout(function () {
                $scope.$apply();
                dataservice.saveResultReportAthletes(vm.editedReportResult)
                    .then(function (args) {
                        log.successMsg('ResultReportSaved');
                        getReportAthleteById(args.data);
                        vm.editable = false;
                        angular.element('#UploadDefault').ejUploadbox('disable');
                        vm.CanCreateNew = ((!vm.editable) && vm.UJEERJEL);
                        vm.CanModify = ((!vm.editable) && vm.MODEERJEL);
                    });
            }, 100);
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.deleted = false;
            vm.editedReportResult.Foto = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheImage'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.deleted = true;
                    vm.editedReportResult.Foto = vm.noImage;
                    vm.editedReportResult.fileName = 'oldDeleted';
                    $scope.$apply();
                }
            });
        }

        function getFiles(args) {
            dataservice.getFilesList('JelentokEgyeni', args).then(
                function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function deleteFile(args) {
            return dataservice.deleteFiles(args.data.Id).then(function () {
                log.successMsg(gettextCatalog.getString('FileDeleted'));
            });
        }

        function closeEarlierResultReportAthletesWindow() {
            angular.element('#searchWindow').data('ejDialog').close();
        }

        function selectEarlierResultReportAthletesWindow() {
            vm.selectedRowIndexPopup = gridPopup.selectedRowIndex;
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = gridPopup.currentViewData[vm.selectedRowIndexPopup];
                if (vmi) {
                    getReportAthleteById(vmi.Id)
                        .then(function (data) {
                            vm.editedReportResult = data.item;
                            vm.editedReportResult.Id = -1;
                        });
                    angular.element('#searchWindow').data('ejDialog').close();
                }
            }
        }
    }
})();
