(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.monthlyCompetitivePlans')
        .controller('MonthlyCompetitivePlansController2', MonthlyCompetitivePlansController2);

    function MonthlyCompetitivePlansController2(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $state, $scope, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MonthlyCompetitivePlansManagementReport');

        // Create variables
        vm.promises = [];
        vm.coachId = -1;
        vm.sectionId = -1;
        vm.coachList = [];
        vm.sectionList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 3));
        // Grid's variables
        vm.columns = [
            {field: 'Link', headerText: '', template: '#mocomplcolumnTemplate1', width: 60, textAlign: 'center'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 150, textAlign: 'center'},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                template: '#mocomplcolumnTemplate2',
                width: 150,
                textAlign: 'center'
            },
            {field: 'ErvenyesDatum', headerText: gettextCatalog.getString('Date'), width: 125, textAlign: 'center'},
            {field: 'AlairtaDatum', headerText: gettextCatalog.getString('SignedDate'), width: 125, textAlign: 'center'},
            {
                field: 'JovahagytaDatum',
                headerText: gettextCatalog.getString('SectionLeaderSignedDate'), width: 125, textAlign: 'center'
            },
            {
                field: 'JovahagytaNeve',
                headerText: gettextCatalog.getString('SectionLeaderSigned'),
                template: '#mocomplcolumnTemplate3', width: 150, textAlign: 'center'
            }
        ];

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToMonthlyCompetitivePlan = goToMonthlyCompetitivePlan;
        vm.openCoach = openCoach;
        vm.coachSelect = coachSelect;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            $q.all([
                    authenticationService.getRight('VEZETOIHV'),
                    authenticationService.getRight('MEGTHAVIVERST'),
                    paramHelper.getParams([], [
                        'monthlyCompetitivePlans.coachId',
                        'monthlyCompetitivePlans.szakosztalyId', 'managementReports.monthlyCompetitivePlans.dateFrom', 'managementReports.monthlyCompetitivePlans.dateTo'])])
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    } else {
                        vm.MEGTHAVIVERST = results[1];
                        vm.dateFromValue = results[2]['managementReports.monthlyCompetitivePlans.dateFrom'] ? results[2]['managementReports.monthlyCompetitivePlans.dateFrom'] : vm.dateFromValue;
                        vm.dateToValue = results[2]['managementReports.monthlyCompetitivePlans.dateTo'] ? results[2]['managementReports.monthlyCompetitivePlans.dateTo'] : vm.dateToValue;
                        vm.promises = results[2];
                        getSections();
                    }
                });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.promises['monthlyCompetitivePlans.szakosztalyId']) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.promises['monthlyCompetitivePlans.szakosztalyId']);
                vm.promises['monthlyCompetitivePlans.szakosztalyId'] = null;
            } else if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0, vm.dateFromValue, vm.dateToValue).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.promises['monthlyCompetitivePlans.coachId']) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.promises['monthlyCompetitivePlans.coachId']);
                vm.promises['monthlyCompetitivePlans.coachId'] = null;
            } else if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            search();
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getMonthlyCompetitiveReports(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'ErvenyesDatum');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'JovahagytaDatum');
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'AlairtaDatum');
            });
            paramHelper.setParams([
                {
                    'monthlyCompetitivePlans.szakosztalyId': vm.sectionId
                },
                {
                    'monthlyCompetitivePlans.coachId': vm.coachId
                },
                {'managementReports.monthlyCompetitivePlans.dateFrom': vm.dateFromValue},
                {'managementReports.monthlyCompetitivePlans.dateTo': vm.dateToValue}
            ]);
        }

        function goToMonthlyCompetitivePlan(planId, egyeni) {
            console.log(egyeni);
            if (!vm.MEGTHAVIVERST) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([
                {
                    'competitiveMonthlyPlan.competitivePlanId': planId
                },
                {'competitiveMonthlyPlan.szakosztalyId': vm.sectionId},
                {'monthlyCompetitivePlan.coachId': vm.coachId},
                {'competitiveMonthlyPlan.egyeni': egyeni}
            ]);
            $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport');
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function dateFromChange(args) {
            vm.dateFromValue = args.value;
            getActualCoachesList(vm.sectionId);
        }

        function dateToChange(args) {
            vm.dateToValue = args.value;
            getActualCoachesList(vm.sectionId);
        }
    }
})();
