/*jshint esversion: 6 */
(function () {
    'use strict';

    angular
        .module('app.layout')
        .controller('SidebarController', SidebarController);

    function SidebarController($state, authenticationService, localStorageService, dataservice, $rootScope, $scope) {
        var vm = this;
        vm.isCurrent = isCurrent;

        var language = authenticationService.getLanguage();
        var imgAppStoreSB = document.getElementById('NoAppStoreSB');
        imgAppStoreSB.src = '../../images/NoAppStore_' + language + '.png';
        vm.goToMobilePage = function goToMobilePage () {
            console.log('vm.goToMobilePage');
            $state.go('sikerMobile');
        };
        vm.noEducationalFee = localStorageService.get('noEducationalFee');
        vm.isAuthenticated = authenticationService.isAuthenticated();
        vm.permission = authenticationService.getPermission();
        vm.authenticated = authenticationService.isAuthenticated();
        vm.rights = authenticationService.getAuthData().accessRights;
        vm.checkPermissionGroup = checkPermissionGroup;
        vm.checkUserLevel = checkUserLevel;
        vm.hideAnyWay = hideAnyWay;
        vm.buildNumber = 'Siker 2.0 - 2019.03.06.';
        vm.getUnreadMessageCount = getUnreadMessageCount;
        $rootScope.INAKTIVMENUREJTES = true;
        function getSystemSettings(valtozo) {
            return dataservice.getSystemSettings(valtozo).then(function (response) {
                return response.retValue;
            });
        }
        $scope.$on('msgRead', getUnreadMessageCount);

        getSystemSettings('INAKTIVMENUREJTES').then(function (res) {
            $rootScope.INAKTIVMENUREJTES = res;
        });
        vm.menuItemClicked = menuItemClicked;
        vm.messageCount = 0;
        getUnreadMessageCount();

        function checkPermissionGroup(activators) {
            if (activators) {
                if (activators.some(e => vm.rights.indexOf(e) !== -1)) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        }

        function checkUserLevel(permission) {
            if (permission) {
                if (permission.some(e => vm.permission.indexOf(e) !== -1)) {
                    return true;
                } else {
                    return false;
                }
            }
            return true;
        }

        // a 3 különböző dashboard és a szülői igazolás elrejtése végett
        function hideAnyWay (permission) {
            if (permission) {
                if (checkUserLevel(permission)) {
                    return false;
                } else {
                    return true;
                }
            }
            return false;
        }

        function getUnreadMessageCount() {
            dataservice.GetUnreadMessageCount().then(function(data) {
                vm.messageCount = data.res;
            });
        };

        function menuItemClicked(nav, level) {
            if (nav.settings.childRoutes === undefined || nav.settings.childRoutes.length === 0) {
                if (level === 1) {
                    vm.level1Open = 0;
                    vm.level2Open = 0;
                }
                if (window.innerWidth < 768) {
                    $rootScope.$broadcast('autoCloseSideBar');
                }
                $state.go(nav.name);
            } else {
                switch (level) {
                    case 1:
                        if (nav.settings.navId === vm.level1Open) {
                            vm.level1Open = 0;
                        } else {
                            vm.level1Open = nav.settings.navId;
                            vm.level2Open = 0;
                        }
                        break;
                    case 2:
                        if (nav.settings.navId === vm.level2Open) {
                            vm.level2Open = 0;
                        } else {
                            vm.level2Open = nav.settings.navId;
                        }
                        break;
                    default:
                        break;
                }
            }
        }

        function isCurrent(route) {
            if (!route.title || !$state.current || !$state.current.title) {
                return '';
            }
            var menuName = route.title;
            return $state.current.title === menuName ? 'current' : '';
        }
    }
})();
