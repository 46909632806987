(function () {
    'use strict';

    angular
        .module('app.settings.athleteTransfer')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'settings.athleteTransfer',
                config: {
                    url: '/athleteTransfer',
                    templateUrl: 'app/settings/athleteTransfer/athleteTransfer.html',
                    controller: 'AthleteTransferController',
                    controllerAs: 'vm',
                    title: 'AthleteTransfer',
                    settings: {
                        navId: 255,
                        level: 2,
                        order: 5,
                        orderTitle_hu: 'Edzőváltás',
                        orderTitle_en: 'Change Coach',
                        parentId: 5,
                        content: 'AthleteTransfer',
                        activatorPermission: ['"MEGTEDZVALT"']
                    }
                }
            }
        ];
    }
})();
