(function () {
    'use strict';

    angular
        .module('app.administration.resultReportTeam')
        .controller('ResultReportTeamController', ResultReportTeamController);

    function ResultReportTeamController($q, dataservice, log, DOMAIN, paramHelper, $scope, $compile, authenticationService, gettextCatalog, $rootScope, $timeout, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ResultReportTeam');
        vm.editedReportResult = {};
        vm.editedReportId = -1;
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.teamList = [];
        vm.competitionList = [];
        vm.EredmenyIdo = '';
        vm.noImage = DOMAIN.baseURL + DOMAIN.noImage;
        vm.editedReportResult.Foto = vm.noImage;
        vm.editable = false;
        vm.CanCreateNew = true;
        vm.CanModify = true;
        vm.selectedRowIndexPopup = -1;
        vm.selectedRowIndex = -1;
        vm.AtjatszoAgeGroup = undefined;
        vm.AtjatszoAgeGroupList = [];
        vm.columnsPopup = [];
        vm.splicedAthletes = [];

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.addAtjatszok = addAtjatszok;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.closeEarlierResultReportTeamWindow = closeEarlierResultReportTeamWindow;
        vm.selectEarlierResultReportTeamWindow = selectEarlierResultReportTeamWindow;
        vm.goToOlderAnnualSeasonPlanReports = goToOlderAnnualSeasonPlanReports;

        vm.maskObject;
        vm.elem;
        vm.tableTimeEditorTemplate = {
            create: function () {
                var txt = document.createElement('input');
                txt.style.width = '100%';
                txt.style.float = 'left';
                vm.elem = txt;
                vm.elem.addEventListener('focus', function () {
                    this.select();
                });
                return vm.elem;
            },
            read: function () {
                return vm.maskObject.getMaskedValue();
            },
            destroy: function () {
                if (vm.maskObject != null) {
                    vm.maskObject.destroy();
                }
            },
            write: function (args) {
                var s = args.element.id.toString().replace('GridAthletes', '');
                vm.maskObject = new ej2.inputs.MaskedTextBox({
                    minWidth: '100%',
                    mask: '99:99:99.999',
                    value: args.rowdata !== undefined ? args.rowdata[s] : '',
                    readOnly: !vm.editable
                });
                vm.editedRowId = args.rowData['Id'];
                $compile(args.element[0])($scope);
                vm.maskObject.appendTo(vm.elem);
            }
        };

        vm.columns = [
            {
                field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true
            },
            {
                field: 'Filenev',
                headerText: gettextCatalog.getString('Filename'),
                textAlign: 'center',
                allowEditing: false,
                width: 100,
                template: '#reretetemplateDataColumn1'
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: ['Delete', 'Search'],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            editSettings: {allowDeleting: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
                if (args.requestType == 'delete') {
                    return dataservice.deleteFiles(args.data[0].Id).then(function () {
                        log.successMsg(gettextCatalog.getString('FileDeleted'));
                    });
                }
            },
        });
        grid.appendTo('#Grid');

        // Athletes
        vm.columnsAthletes = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('AthleteName'),
                textAlign: 'center',
                width: 150,
                allowEditing: false
            },
            {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('TrainerName'),
                textAlign: 'center',
                width: 150,
                allowEditing: false,
            },
            {
                field: 'Atjatszo',
                headerText: gettextCatalog.getString('AtFeljatszo'),
                textAlign: 'center',
                width: 150,
                allowEditing: false,
                type: 'boolean',
                editType: ej.Grid.EditingType.Boolean,
                displayAsCheckBox: true,
            },
            {
                field: 'Kiemelt',
                headerText: gettextCatalog.getString('Kiemelt'),
                textAlign: 'center',
                width: 80,
                type: 'boolean',
                editType: ej.Grid.EditingType.Boolean,
                displayAsCheckBox: true,
            },
            {
                field: 'EredmenySzoveg',
                headerText: gettextCatalog.getString('EredmenySzoveg'),
                textAlign: 'center',
                width: 150,
                allowEditing: true
            },
            {
                field: 'EredmenyPont',
                headerText: gettextCatalog.getString('EredmenyPontGol'),
                textAlign: 'center',
                type: 'numeric',
                editType: ej.Grid.EditingType.Numeric,
                editParams: {decimalPlaces: 2, locale: 'HU-hu'},
                width: 100,
                allowEditing: true
            },
            {
                field: 'EredmenyIdo',
                headerText: gettextCatalog.getString('EredmenyIdo'),
                textAlign: 'center',
                edit: vm.tableTimeEditorTemplate,
                width: 100,
                allowEditing: true
            },
            {
                field: 'EredmenyTavolsag',
                headerText: gettextCatalog.getString('EredmenyTavolsag'),
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                format: 'N2',
                params: {decimals: 2},
                width: 100,
                allowEditing: true
            },
            {
                field: 'EredmenyTavolsagM',
                headerText: gettextCatalog.getString('EredmenyTavolsagM'),
                textAlign: 'center',
                editType: ej.Grid.EditingType.Numeric,
                width: 100,
                allowEditing: true
            }
        ];

        var gridAthletes = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            toolbar: ['Search'],
            editSettings: {allowDeleting: false, allowEditing: false, mode: 'Batch', showConfirmDialog: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsAthletes,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#GridAthletes'), $scope);
                }
            },
            cellEdit: function (args) {
                if (!vm.editable) {
                    args.cancel = true;
                } else {
                    if (args.columnName === 'Kiemelt') {
                        var index = gridAthletes.dataSource.findIndex(function (item) {
                            return item.SportolokID === args.rowData.SportolokID;
                        });
                        gridAthletes.dataSource[index]['Kiemelt'] = !gridAthletes.dataSource[index]['Kiemelt'];
                        gridAthletes.endEdit();
                        args.cancel = true;
                    }
                }
            },
            cellSave: function (arg) {
                if (arg.columnName == 'EredmenyTavolsag') {
                    for (var i = 0; i < gridAthletes.dataSource.length; i++) {
                        if (gridAthletes.dataSource[i].SportolokID == arg.rowData.SportolokID) {
                            gridAthletes.dataSource[i].EredmenyTavolsag = arg.value;
                            gridAthletes.dataSource[i].EredmenyTavolsagM = arg.value / 100;
                            gridAthletes.refresh();
                            break;
                        }
                    }
                } else if (arg.columnName == 'EredmenyTavolsagM') {
                    for (var i = 0; i < gridAthletes.dataSource.length; i++) {
                        if (gridAthletes.dataSource[i].SportolokID == arg.rowData.SportolokID) {
                            gridAthletes.dataSource[i].EredmenyTavolsagM = arg.value;
                            gridAthletes.dataSource[i].EredmenyTavolsag = arg.value * 100;
                            gridAthletes.refresh();
                            break;
                        }
                    }
                } else {
                    for (var i = 0; i < gridAthletes.dataSource.length; i++) {
                        if (gridAthletes.dataSource[i].SportolokID == arg.rowData.SportolokID) {
                            gridAthletes.dataSource[i][arg.columnName] = arg.value;
                            gridAthletes.refresh();
                            break;
                        }
                    }
                }
            }
        });
        gridAthletes.appendTo('#GridAthletes');

        vm.atjatszoColumns = [
            {type: 'checkbox', textAlign: 'center', width: 35},
            {field: 'Id', visible: false, isPrimaryKey: true, isIdentity: true},
            {field: 'SportoloNev', headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 150},
            {field: 'Szuletes', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'center', width: 125},
            {field: 'korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 125},
            {field: 'edzoneve', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150}
        ];

        var gridAtjatszo = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            allowTextWrap: true,
            toolbar: ['Search'],
            editSettings: {allowDeleting: false},
            locale: 'hu-HU',
            gridLines: 'Both',
            selectionSettings: {type: 'multiple', mode: 'Both'},
            pageSettings: $rootScope.pageSettings,
            columns: vm.atjatszoColumns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            rowSelecting: function () {
            },
        });
        gridAtjatszo.appendTo('#GridAtjatszo');

        vm.columnsPopup = [
            {
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'VersenyNeve',
                headerText: gettextCatalog.getString('CompetitionName'),
                textAlign: 'center',
                width: 125,
                allowEditing: false
            },
            {
                field: 'Versenyszam',
                headerText: gettextCatalog.getString('Versenyszam'),
                textAlign: 'center',
                width: 125,
                allowEditing: false
            },
            {
                field: 'Szakosztaly',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: 125,
                allowEditing: false
            },
            {
                field: 'Korosztaly',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'SportoloCsapat',
                headerText: gettextCatalog.getString('Team'),
                textAlign: 'center',
                width: 150,
                allowEditing: false
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center',
                width: 125,
                allowEditing: false
            }
        ];

        var gridPopup = new ej2.grids.Grid({
            dataSource: [],
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            }
        });
        gridPopup.appendTo('#GridPopup');

        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.teamDataBound = teamDataBound;
        vm.teamSelect = teamSelect;
        vm.openCropDialog = openImgDialog;
        vm.deleteImg = deleteImg;
        vm.createResultReport = createResultReport;
        vm.modifyResultReport = modifyResultReport;
        vm.saveResultReport = saveResultReport;
        vm.oldTeamId = 0;
        vm.addAthletesToResultReport = addAthletesToResultReport;
        vm.removeAthletesFromResultReport = removeAthletesFromResultReport;
        vm.atjatszoAgeGroupSelect = atjatszoAgeGroupSelect;

        activate();

        function activate() {
            generateUploadbox();
            $q.all([paramHelper.getParams([], ['resultReportTeam.Id']),
                authenticationService.getRight('MODCSERJEL'),
                authenticationService.getRight('UJCSERJEL'),
                authenticationService.getRight('MEGTCSERJEL')])
                .then(function (results) {
                    vm.MODCSERJEL = results[1];
                    vm.UJCSERJEL = results[2];
                    vm.MEGTCSERJEL = results[3];

                    if (!vm.MEGTCSERJEL) {
                        log.permissionError(true);
                        return;
                    }
                    vm.CanCreateNew = ((!vm.editable) && vm.UJCSERJEL);
                    vm.CanModify = ((!vm.editable) && vm.MODCSERJEL);
                    //létezőt nyit vissza
                    if (results[0]['resultReportTeam.Id'] && results[0]['resultReportTeam.Id'] !== -1) {
                        dataservice.resultReportTeamSelectById(results[0]['resultReportTeam.Id'])
                            .then(function (data) {
                                $timeout(function () {
                                    vm.editedReportId = results[0]['resultReportTeam.Id'];
                                    paramHelper.removeParam('resultReportTeam.Id');
                                    vm.editedReportResult = data.item;
                                    vm.editedReportResult.SzakosztalyId = data.item.SzakosztalyId;
                                    vm.editedReportResult.KorcsoportId = data.item.KorcsoportId;
                                    vm.editedReportResult.CsapatId = data.item.CsapatId;
                                    if (!vm.editedReportResult.Foto || vm.editedReportResult.Foto == 'noImage.png' || vm.editedReportResult.Foto == 'noImg.jpg') {
                                        vm.editedReportResult.Foto = DOMAIN.baseURL + DOMAIN.noImage;
                                    } else {
                                        vm.editedReportResult.Foto = DOMAIN.baseURL + DOMAIN.resultReportTeamImgBaseUrl + vm.editedReportResult.Foto;
                                    }
                                    gridAthletes.dataSource = data.item.Sportolok;
                                }, 0);
                                getSections();
                                getFiles(results[0]['resultReportTeam.Id']);
                            });
                    } else {
                        createResultReport();
                        getSections();
                    }
                });
        }

        function generateUploadbox() {
            angular.element('#UploadDefault').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUpload',
                removeUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileRemove',
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                autoUpload: true,
                enabled: false,
                buttonText: {
                    browse: gettextCatalog.getString('ChooseFile'),
                    upload: gettextCatalog.getString('UploadFile'),
                    cancel: gettextCatalog.getString('CancelUpload')
                },
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {
                    title: gettextCatalog.getString('UploadedFilesTitle'),
                    name: gettextCatalog.getString('FileName'),
                    size: gettextCatalog.getString('FileSize'),
                    status: gettextCatalog.getString('FileStatus')
                },
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf,.xls, .mp4, .avi',
                complete: function (args) {
                    if (args.responseText === 'true') {
                        log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                        getFiles(vm.editedReportResult.Id);
                    } else {
                        log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + args.error);
                    }
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc,.docx,.pdf,.xls,.mp4,.avi extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdfXls');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: 'JelentokCsapat', tablaId: vm.editedReportResult.Id};
                }
            });
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(0)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.editedReportResult.SzakosztalyId);
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.editedReportResult.SzakosztalyId = args.value;
            getAgeGroups(args.value);
            getAgeGroupsForAtjatszo(args.value);
            getCharacterOfACompetitionList(args.value);
        }

        //korcsoportok lekérése
        function getAgeGroups(szakosztalyId) {
            return dataservice.ageGroupDropDownList(szakosztalyId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.editedReportResult.KorcsoportId);
            }
        }

        function ageGroupSelect(args) {
            vm.editedReportResult.KorcsoportId = args.value;

            // $('#maCsapat').ejDropDownList('clearText');
            vm.oldTeamId = -1;
            getTeams(args.value);
        }

        function getTeams(KorcsoportId) {
            var CsapatId = $('#maKorosztaly').ejDropDownList('getSelectedValue');
            dataservice.teamDropDownListSelect(vm.editedReportResult.SzakosztalyId, KorcsoportId, CsapatId).then(function (data) {
                vm.teamList = data.itemsList;
            });
        }

        function teamDataBound() {
            if (vm.teamList.length === 1) {
                angular.element('#maCsapat').ejDropDownList('selectItemByValue', vm.teamList[0].value);
            } else {
                angular.element('#maCsapat').ejDropDownList('selectItemByValue', vm.editedReportResult.CsapatId);
            }
        }

        function teamSelect(args) {
            if (!vm.editedReportId || vm.editedReportId === -1 || vm.oldTeamId !== -1) {
                if (vm.oldTeamId === args.value) {
                    return;
                }
                vm.oldTeamId = args.value;
                dataservice.teamAthletesListSelect(args.value).then(function (athletesList) {
                    gridAthletes.dataSource = athletesList.itemsList;

                    swal({
                        title: gettextCatalog.getString('AtjatszoSportolok'),
                        text: gettextCatalog.getString('AreYouSureYouWantToAddAtjatszoAthletes'),
                        type: 'question',
                        showCancelButton: true,
                        confirmButtonText: gettextCatalog.getString('Yes'),
                        cancelButtonText: gettextCatalog.getString('No')
                    }).then(function (isConfirm) {
                        if (isConfirm.value) {
                            dataservice.EredmenyJelentoAtjatszoSportoloi(args.value).then(function (args) {
                                for (var i = 0; i < args.itemsList.length; i++) {
                                    gridAthletes.dataSource.push(args.itemsList[i]);
                                }
                                gridAthletes.refresh();
                            });
                        }
                    });
                });
                dataservice.getCoachUsers([vm.editedReportResult.SzakosztalyId]).then(function (args) {
                    vm.atjatszoEdzoList = args.itemsList;
                });
            }
            getAtjatszoSportolok(vm.editedReportResult.SzakosztalyId, 0, 0, args.value);
        }

        function getAgeGroupsForAtjatszo(szakosztalyId) {
            return dataservice.ageGroupDropDownList(szakosztalyId)
                .then(function (data) {
                    vm.AtjatszoAgeGroupList = data.itemsList;
                    vm.AtjatszoAgeGroupList.unshift({value: '0', text: gettextCatalog.getString('All')});
                });
        }

        function atjatszoAgeGroupSelect(arg) {
            getAtjatszoSportolok(vm.editedReportResult.SzakosztalyId, arg.value, 0, vm.editedReportResult.CsapatId);
        }

        function getAtjatszoSportolok(section, agegroup, coach, exceptTeam) {
            dataservice.AtjatszoSportoloLista(section, agegroup, coach, exceptTeam).then(function (response) {
                gridAtjatszo.dataSource = $filter('dateStringFilter')(response.itemsList, 'Szuletes');
            });
        }

        function addAtjatszok() {
            vm.selectedAtjatszok = $rootScope.getSelectedItems(gridAtjatszo);
            if (!vm.selectedAtjatszok || vm.selectedAtjatszok.length == 0) {
                log.errorMsg('PleaseSelectAthlete');
                return;
            }
            vm.selectedAtjatszok.forEach(function (atjatszo) {
                addAthletesToResultReport(atjatszo.SportoloID, atjatszo.edzoneve, atjatszo.edzoid);
                if (gridAtjatszo.dataSource.includes(atjatszo)) {
                    vm.splicedAthletes.push(atjatszo);
                    gridAtjatszo.dataSource.splice(gridAtjatszo.dataSource.indexOf(atjatszo), 1);
                }
            });
            gridAtjatszo.refresh();
        }

        function addAthletesToResultReport(atjatszoSportoloID, edzoNeve, edzoId) {
            if (!atjatszoSportoloID) {
                log.errorMsg('PleaseSelectAthlete');
                return;
            }
            var id = -1;
            for (var i = 0; i < gridAthletes.dataSource.length; i++) {
                if (gridAthletes.dataSource[i].SportolokID == atjatszoSportoloID) {
                    log.errorMsg('AthleteExistsInResultReport');
                    return;
                }
                if (gridAthletes.dataSource[i].Id < id) {
                    id = gridAthletes.dataSource[i].Id;
                }
            }
            dataservice.getTeamResultAthleteData(atjatszoSportoloID).then(function (args) {
                var rec = {};
                rec.SportolokID = args.SportolokID;
                rec.Id = id - 1;
                rec.Nev = args.Nev;
                rec.KorcsoportNeve = args.KorcsoportNeve;
                rec.KorcsoportId = args.Korcsoport;
                rec.EdzoId = edzoId;
                rec.EdzoNeve = edzoNeve;
                rec.SzuletesiIdo = args.SzuletesiIdo;
                rec.SzakosztalyId = args.IdSzakosztaly;
                rec.Atjatszo = true;
                rec.atjatszoCsapatId = vm.editedReportResult.CsapatId;
                rec.Kiemelt = false;
                rec.EredmenySzoveg = '';
                rec.EredmenyPont = '';
                rec.EredmenyIdo = '';
                rec.EredmenyTavolsag = '';
                gridAthletes.dataSource.push(rec);
                gridAthletes.refresh();
            });
        }

        function getCharacterOfACompetitionList(szakosztalyId) {
            var csapat = true;
            return dataservice.getCharacterOfACompetitionList(csapat, szakosztalyId)
                .then(function (data) {
                    vm.competitionList = data.itemsList;
                });
        }

        function goToOlderAnnualSeasonPlanReports() {
            if (!vm.editedReportResult.SzakosztalyId || vm.editedReportResult.SzakosztalyId < 1) {
                log.errorMsg(gettextCatalog.getString('LetHimGrantAtLeastASection'));
                return;
            } else {
                vm.selectedRowIndexPopup = -1;

                angular.element('#searchWindow').data('ejDialog').open();
                var KorcsoportId = angular.element('#maKorosztaly').ejDropDownList('getSelectedValue');
                dataservice.resultReportAthletesOlderReports(vm.editedReportResult.SzakosztalyId,
                    KorcsoportId, false).then(function (args) {
                    gridPopup.dataSource = args.itemsList;
                });
            }
        }

        function selectEarlierResultReportTeamWindow() {
            vm.selectedRowIndexPopup = gridPopup.selectedRowIndex;
            if (vm.selectedRowIndexPopup > -1) {
                var vmi = gridPopup.currentViewData[vm.selectedRowIndexPopup];
                if (vmi) {
                    dataservice.resultReportTeamSelectById(vmi.Id)
                        .then(function (data) {
                            vm.editedReportResult = data.item;
                            vm.editedReportResult.Id = -1;
                            for (var i = 0; i < vm.editedReportResult.Sportolok.length; i++) {
                                vm.editedReportResult.Sportolok[i].Id = -1 * (i + 1);
                            }
                        });
                    angular.element('#searchWindow').data('ejDialog').close();
                }
            }
        }

        function removeAthletesFromResultReport() {
            vm.selectedRowIndex = $rootScope.gridSelectedRowIndex(gridAthletes);
            if (vm.selectedRowIndex === undefined || vm.selectedRowIndex == null || vm.selectedRowIndex.length < 0) {
                log.errorMsg('PleaseSelectAthlete');
                return;
            }
            swal({
                title: gettextCatalog.getString('DeleteAthlete'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteSelectedAthlete'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    grid.endEdit();
                    vm.selectedRowIndex.forEach(function () {
                        var ath = gridAthletes.getSelectedRecords();
                        vm.splicedAthletes.forEach(elem => {
                            if (elem.SportoloID == ath[0].SportolokID) {
                                gridAtjatszo.dataSource.push(elem);
                                vm.splicedAthletes.splice(elem, 1);
                            }
                        });
                        gridAthletes.dataSource.splice(vm.selectedRowIndex, 1);
                    });
                    gridAthletes.refresh();
                    gridAtjatszo.refresh();
                }
            });
        }

        function closeEarlierResultReportTeamWindow() {
            angular.element('#searchWindow').data('ejDialog').close();
        }

        function createResultReport() {
            vm.editedReportResult = {};
            vm.editedReportResult.DatumIg = null;
            vm.editedReportResult.DatumTol = null;
            vm.editedReportResult.EdzoId = null;
            vm.editedReportResult.EdzoNeve = null;
            vm.editedReportResult.Eredmeny = 3;
            vm.editedReportResult.EredmenyIdo = null;
            vm.editedReportResult.EredmenySzoveg = null;
            vm.editedReportResult.EredmenyTavolsag = null;
            vm.editedReportResult.Ertekeles = null;
            vm.editedReportResult.Foto = vm.noImage;
            vm.editedReportResult.Helyezes = null;
            vm.editedReportResult.Helyszin = null;
            vm.editedReportResult.Hivatalos = false;
            vm.editedReportResult.Jellege = null;
            vm.editedReportResult.KorcsoportNeve = null;
            vm.editedReportResult.SportoloId = null;
            vm.editedReportResult.SportoloNev = null;
            vm.editedReportResult.SzakosztalyNeve = null;
            vm.editedReportResult.SzuletesiIdo = null;
            vm.editedReportResult.TarsEredmenySportolo = null;
            vm.editedReportResult.VersenyLeiras = null;
            vm.editedReportResult.fileData = null;
            vm.editedReportResult.fileName = null;
            vm.editedReportResult.Id = -1;
            gridAthletes.dataSource = [];
            grid.dataSource = [];
            grid.editSettings.allowDeleting = true;
            gridAthletes.editSettings.allowDeleting = true;
            gridAthletes.editSettings.allowEditing = true;
            //vm.editable = true;
            angular.element('#UploadDefault').ejUploadbox('enable');
            // angular.element('#maSzakosztaly').ejDropDownList('clearText');
            // angular.element('#maKorosztaly').ejDropDownList('clearText');
            // angular.element('#maSportolo').ejDropDownList('clearText');
            // angular.element('#maTarsSportolo').ejDropDownList('clearText');

            vm.editedReportResult.SzakosztalyId = 0;
            vm.editedReportResult.KorcsoportId = 0;

            vm.editedReportId = -1;
            $timeout(function () {
                vm.editable = true;
                vm.CanCreateNew = false;
                vm.CanModify = false;
                $scope.$apply();
            }, 0);
        }

        function modifyResultReport() {
            dataservice.getCoachUsers(vm.editedReportResult.SzakosztalyId).then(function (args) {
                vm.atjatszoEdzoList = args.itemsList;
                grid.editSettings.allowDeleting = true;
                gridAthletes.editSettings.allowDeleting = true;
                gridAthletes.editSettings.allowEditing = true;
                vm.editable = true;
                angular.element('#UploadDefault').ejUploadbox('enable');
                vm.CanCreateNew = false;
                vm.CanModify = false;
            });
        }

        function saveResultReport() {
            $scope.$broadcast('submitted');
            grid.endEdit();
            //grid.editModule.batchSave();

            gridAthletes.endEdit();
            //gridAthletes.editModule.batchSave();
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            vm.editedReportResult.Sportolok = gridAthletes.dataSource;
            // kép küldése, ha választott

            if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                vm.editedReportResult.fileData = vm.cropper.croppedImage;
                vm.editedReportResult.fileName = angular.element('#fileName').val();
            }
            dataservice.saveResultReportTeams(vm.editedReportResult)
                .then(function (args) {
                    if (args.res > 0) {
                        vm.editedReportId = args.res;
                        log.successMsg('ResultReportSaved');
                        vm.editedReportResult.Id = args.res;
                        angular.element('#UploadDefault').ejUploadbox('disable');
                        vm.CanCreateNew = vm.UJCSERJEL;
                        vm.CanModify = vm.MODCSERJEL;
                        vm.editable = false;
                        // $timeout(function () {
                        //     $scope.$apply();
                        // }, 100);
                    }
                });
        }

        function deleteImg() {
            swal({
                title: gettextCatalog.getString('DeleteProfileImg'),
                text: gettextCatalog.getString('AreYouSureYouWantToDeleteTheImage'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: gettextCatalog.getString('Yes'),
                cancelButtonText: gettextCatalog.getString('No')
            }).then(function (isConfirm) {
                if (isConfirm.value) {
                    angular.element('#fileName').val('');
                    vm.editedReportResult.Foto = vm.noImage;
                    vm.editedReportResult.fileName = 'oldDeleted';
                    $scope.$apply();
                }
            });
        }

        function getFiles(args) {
            dataservice.getFilesList('JelentokCsapat', args).then(
                function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        function openImgDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.editedReportResult.Foto = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }
    }
})();
