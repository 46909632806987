(function () {
    'use strict';

    angular
        .module('app.reports.weeklyTravelReport')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.weeklyTravelReport',
                config: {
                    url: '/weeklyTravelReport',
                    templateUrl: 'app/reports/weeklyTravelReport/weeklyTravelReport.html',
                    controller: 'WeeklyTravelReportController',
                    controllerAs: 'vm',
                    title: 'WeeklyTravelReport',
                    settings: {
                        navId: 2174,
                        level: 2,
                        order: 17,
                        orderTitle_hu: 'Utazási összesítő kimutatás',
                        orderTitle_en: 'Travel Report',
                        parentId: 4,
                        content: 'WeeklyTravelReport',
                        activatorPermission: ['"UTAZTERVMEGT"']
                    }
                }
            }
        ];
    }
})();
