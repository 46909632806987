(function () {
    'use strict';

    angular
        .module('app.reports.weeklyTrainingReport')
        .controller('weeklyTrainingReportController', weeklyTrainingReportController);

    function weeklyTrainingReportController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $filter, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('weeklyTrainingReport');

        vm.place = '';
        vm.sectionList = [];
        vm.places = [];
        vm.sectionId = 0;
        vm.ageGroupList = [];
        vm.ageGroupId = 0;
        vm.coachList = [];
        vm.coachId = 0;
        vm.showWeeks = false;
        vm.onlyStartDate = false;
        vm.selectdateFrom = getMonday(new Date(), 0);
        vm.selectdateTo = getMonday(new Date(), 6);

        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.openAthlete = openAthlete;
        vm.openCoach = openCoach;
        vm.show = show;
        vm.openPlan = openPlan;
        vm.showWeeksChange = showWeeksChange;
        vm.savedParams = {sectionId: 0, ageGroupId: 0, coachId: 0};

        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;

        vm.columns = [
            {field: 'Link', headerText: '', template: '#wtro', textAlign: 'center', width: 60},
            {field: 'szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 125},
            {field: 'korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 115},
            {field: 'edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150},
            {field: 'helyszin', headerText: gettextCatalog.getString('Place'), textAlign: 'center', width: 200},
            {field: 'hetszama', headerText: gettextCatalog.getString('FelkHetSzam'), textAlign: 'center', width: 125},
            {field: 'nap', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125},
            {field: 'tol', headerText: gettextCatalog.getString('From'), textAlign: 'center', width: 125},
            {field: 'ig', headerText: gettextCatalog.getString('To'), textAlign: 'center', width: 125},
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            rowSelected: function (args) {
                vm.selectedRowId = args.data.Id;
                vm.delEnabled = vm.MODHETIEDZTERV;
                $scope.$apply();
            },
            rowDeselected: function () {
                vm.delEnabled = false;
                vm.selectedRowId = -1;
                $scope.$apply();
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        function getMonday(d, plusDay) {
            d = new Date(d);
            var day = d.getDay();
            var diff = d.getDate() - day + (day == 0 ? -6 : 1) + plusDay; // adjust when day is sunday
            return new Date(d.setDate(diff));
        }

        activate();

        function activate() {
            authenticationService.getRight('MEGTHETIEDZTERVKIMUT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                    getPlaces();
                    setDropDowns();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'wtr.sectionId',
                'wtr.ageGroupId',
                'wtr.coachId',
                'wtr.showWeeks',
                'wtr.place'
            ])];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['wtr.sectionId'];
                vm.ageGroupId = results[0]['wtr.ageGroupId'];
                vm.coachId = results[0]['wtr.coachId'];
                vm.place = results[0]['wtr.place'];
                angular.element('#ShowWeeks').ejCheckBox({checked: results[0]['wtr.showWeeks']});
            });
        }

        function initColumns(weekly) {
            if (weekly) {
                grid.columns = [
                    {field: 'Link', headerText: '', template: '#wtro', textAlign: 'center', width: 60},
                    {field: 'szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 125},
                    {field: 'korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 125},
                    {field: 'edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150},
                    {field: 'helyszin', headerText: gettextCatalog.getString('Place'), textAlign: 'center', width: 250},
                    {field: 'hetszama', headerText: gettextCatalog.getString('FelkHetSzam'), textAlign: 'center', width: 125},
                    {field: 'nap', headerText: gettextCatalog.getString('Monday'), textAlign: 'center', width: 125},
                ];
                grid.refreshColumns();
            } else {
                grid.columns = [
                    {field: 'Link', headerText: '', template: '#wtro', textAlign: 'center', width: 60},
                    {field: 'szakosztaly', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 125},
                    {field: 'korosztaly', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 125},
                    {field: 'edzo', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150},
                    {field: 'helyszin', headerText: gettextCatalog.getString('Place'), textAlign: 'center', width: 250},
                    {field: 'hetszama', headerText: gettextCatalog.getString('FelkHetSzam'), textAlign: 'center', width: 125},
                    {field: 'nap', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125},
                    {field: 'tol', headerText: gettextCatalog.getString('From'), textAlign: 'center', width: 100},
                    {field: 'ig', headerText: gettextCatalog.getString('To'), textAlign: 'center', width: 100},
                ];
                grid.refreshColumns();
            }
        }

        function getPlaces() {
            dataservice.WeeklyTrainingPlaces().then(function (data) {
                vm.places = data.data;
            });
        }

        function getSections() {
            dataservice.sectionDropDownList(0)
                .then(function (data) {
                    if (data.itemsList.length > 1) {
                        data.itemsList.unshift({value: 0, text: gettextCatalog.getString('All')});
                    }
                    vm.sectionList = data.itemsList;
                });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            getAgeGroups(args.value);
            getActualCoachesList(0);
        }

        function getAgeGroups(sectionId) {
            return dataservice.ageGroupDropDownList(sectionId).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.ageGroupList = data.itemsList;
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
                if (vm.ageGroupId) {
                    getActualCoachesList(0);
                }
            }
        }

        function ageGroupSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(ageGroupId) {
            return dataservice.coachDropDownList(vm.sectionId, ageGroupId, vm.selectdateFrom, vm.selectdateTo).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: 0});
                }
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
        }

        function show() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            if (vm.place == null) {
                vm.place = '';
            }
            if (vm.showWeeks == null) {
                vm.showWeeks = false;
            }
            paramHelper.setParams([{
                'wtr.sectionId': vm.sectionId,
                'wtr.ageGroupId': vm.ageGroupId,
                'wtr.coachId': vm.coachId,
                'wtr.selectdateFrom': vm.selectdateFrom,
                'wtr.selectdateTo': vm.selectdateTo,
                'wtr.showWeeks': vm.showWeeks,
                'wtr.place': vm.place
            }]);
            dataservice.getWeeklyTrainingList(vm.sectionId, vm.ageGroupId, vm.coachId, vm.selectdateFrom, vm.selectdateTo, vm.showWeeks, vm.place).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'nap');
                initColumns(vm.showWeeks);
            });
        }

        function openAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function openPlan(trainingPlanId) {
            authenticationService.getRight('MEGTHETIEDZTERV').then(function (result) {
                if (result !== true) {
                    log.permissionError();
                } else {
                    dataservice.weeklyTrainingPlanSelectByIdNew(trainingPlanId).then(function (data) {
                        paramHelper.setParams([
                            {'weeklyTrainingPlan.edzoId': data.retValue.IdEdzo},
                            {'weeklyTrainingPlan.szakosztalyId': data.retValue.IdSzakosztaly},
                            {'weeklyTrainingPlan.korosztalyId': data.retValue.IdKorosztaly},
                            {'weeklyTrainingPlan.ev': 0},
                            {'weeklyTrainingPlan.trainingPlanId': trainingPlanId},
                            {'weeklyTrainingPlan.edzoNev': data.retValue.EdzoNev},
                            {'weeklyTrainingPlan.szakosztalyNev': data.retValue.SzakosztalyNev},
                            {'weeklyTrainingPlan.korosztalyNev': data.retValue.KorosztalyNev},
                            {'weeklyTrainingPlan.szoveg': ''}
                        ]);
                        $state.go('administration.weeklyTrainingPlan.weeklyTrainingPlanReport');
                    });
                }
            });
        }

        function showWeeksChange(arg) {
            vm.showWeeks = arg.value;
        }

        function dateFromChange(args) {
            vm.selectdateFrom = args.value;
            getActualCoachesList(0);
        }

        function dateToChange(args) {
            vm.selectdateTo = args.value;
            getActualCoachesList(0);
        }
    }
})();
