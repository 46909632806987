(function () {
    'use strict';

    angular
        .module('app.reports.monthlyCompetitivePlans')
        .controller('MonthlyCompetitivePlansController', MonthlyCompetitivePlansController);

    function MonthlyCompetitivePlansController(log, dataservice, $rootScope, gettextCatalog, authenticationService, paramHelper, $state, $scope, $q, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('MonthlyCompetitivePlans');

        // Create variables
        vm.coachList = [];
        vm.sectionList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 3));
        vm.savedSectionId = 0;
        vm.savedCoachId = 0;
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToMonthlyCompetitivePlan = goToMonthlyCompetitivePlan;
        vm.openCoach = openCoach;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;
        // Grid's variables
        vm.columns = [
            {field: 'SportoloId', isPrimaryKey: true, visible: false},
            {field: 'Link', headerText: '', textAlign: 'center', width: 60, template: '#moncompplacolumnTemplate1'},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 150},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: 150,
                template: '#monthlyCompetitivePlanscolumnTemplate1'
            },
            {field: 'ErvenyesDatum', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125},
            {field: 'AlairtaDatum', headerText: gettextCatalog.getString('SignedDate'), textAlign: 'center', width: 125},
            {
                field: 'JovahagytaDatum', headerText: gettextCatalog.getString('ApprovalDate'), textAlign: 'center',
                width: 125
            },
            {
                field: 'JovahagytaNeve',
                headerText: gettextCatalog.getString('Approval'),
                textAlign: 'center',
                width: 150,
                template: '#monthlyCompetitivePlanscolumnTemplate2'
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            gridLines: 'Both',
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,

            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('KMHAVIVERSTERV').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([authenticationService.getRight('MEGTHAVIVERST'), paramHelper.getParams([], ['mcp.coach', 'mcp.section'])]).then(function (results) {
                        vm.MEGTHAVIVERST = results[0];
                        vm.sectionId = results[1]['mcp.section'];
                        vm.coachId = results[1]['mcp.coach'] === null ? -1 : results[1]['mcp.coach'];
                    });
                    setDropDowns();
                    getSections();
                }
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'mcp.section',
                'mcp.coach',
                'mcp.from',
                'mcp.to',
            ])
            ];
            $q.all(promises).then(function (results) {
                if (results[0]['mcp.section'] > 0) {
                    vm.savedSectionId = results[0]['mcp.section'];
                    vm.savedCoachId = results[0]['mcp.coach'];
                    vm.dateFromValue = results[0]['mcp.from'];
                    vm.dateToValue = results[0]['mcp.to'];
                }
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.savedSectionId > 0) {
                vm.sectionId = vm.savedSectionId;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            } else {
                if (vm.sectionList.length === 1) {
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                } else {
                    angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
                }
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0, vm.dateFromValue, vm.dateToValue).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.savedCoachId > 0) {
                vm.coachId = vm.savedCoachId;
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            } else {
                if (vm.coachList.length === 1) {
                    angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                } else {
                    angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
                }
            }
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
                {'mcp.section': vm.sectionId},
                {'mcp.coach': vm.coachId},
                {'mcp.from': vm.dateFromValue},
                {'mcp.to': vm.dateToValue}
            ]);
            dataservice.getMonthlyCompetitivePlans(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'ErvenyesDatum');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'AlairtaDatum');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'JovahagytaDatum');
                grid.dataSource = data.itemsList;
            });
        }

        function goToMonthlyCompetitivePlan(planId) {
            var result = $.grep(grid.currentViewData, function (e) {
                return e.planId == planId;
            });
            if (!vm.MEGTHAVIVERST) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([
                {
                    'competitiveMonthlyPlan.edzoId': result[0].EdzoId
                },
                {
                    'competitiveMonthlyPlan.edzoNev': result[0].EdzoNeve
                },
                {
                    'competitiveMonthlyPlan.szakosztalyNev': result[0].szakosztalyNev
                },
                {
                    'competitiveMonthlyPlan.szakosztalyId': result[0].SzakosztalyId
                },
                {
                    'competitiveMonthlyPlan.competitivePlanId': result[0].planId
                },
                {
                    'competitiveMonthlyPlan.egyeni': result[0].egyeni
                }
            ]);
            $state.go('administration.competitiveMonthlyPlan.competitiveMonthlyPlanReport');
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function dateFromChange(args) {
            vm.dateFromValue = args.value;
            getActualCoachesList(vm.sectionId);
        }

        function dateToChange(args) {
            vm.dateToValue = args.value;
            getActualCoachesList(vm.sectionId);
        }
    }
})();
