(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.annualSeasonPlan.annualSeasonPlanList',
                config: {
                    url: '/annualSeasonPlanList',
                    templateUrl: 'app/administration/annualSeasonPlan/annualSeasonPlanList/annualSeasonPlanList.html',
                    controller: 'AnnualSeasonPlanListController',
                    controllerAs: 'vm',
                    title: 'AnnualSeasonPlanList',
                    settings: {
                        navId: 284,
                        level: 2,
                        order: 8,
                        orderTitle_hu: 'Éves felkészítési és versenyeztetési terv lista',
                        orderTitle_en: 'Annual Preparation and Competition Plan List',
                        parentId: 4,
                        content: 'AnnualSeasonPlanList',
                        activatorPermission: ['"MEGTEVVERST"']
                    }
                }
            }
        ];
    }
})();
