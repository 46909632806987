(function () {
    'use strict';

    angular
        .module('app.reports.duplicatedAthletes')
        .controller('DuplicatedAthletesController', DuplicatedAthletesController);

    function DuplicatedAthletesController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $filter, $scope, paramHelper, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('DuplicatedAthletesByNameAndSSN');

        vm.columns = [
            {field: 'SportolokID', isPrimaryKey: true, visible: false},
            {field: 'Nev', headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 150, templat: '#duplicatedcolumnTemplate1'},
            {field: 'SzuletesiIdo', headerText: gettextCatalog.getString('Birthdate'), textAlign: 'center', width: 150},
            {field: 'AnyjaNeve', headerText: gettextCatalog.getString('MothersName'), textAlign: 'center', width: 150},
            {field: 'RegisztraciosSzam', headerText: gettextCatalog.getString('Regisztracios'), textAlign: 'center', width: 150},
            {field: 'TajSzam', headerText: gettextCatalog.getString('SocialSecurityNumber'), textAlign: 'center', width: 150},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150, template: '#duplicatedcolumnTemplate2'},
            {field: 'Aktiv', headerText: gettextCatalog.getString('Active'), type: 'boolean', textAlign: 'center', width: 100, displayAsCheckBox: true}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        vm.goToAthlete = goToAthlete;
        vm.goToUser = goToUser;
        vm.InactivesChange = InactivesChange;
        vm.InactivesToo = false;

        // Activate page
        activate();

        function activate() {
            authenticationService.getRight('DUPLIKALTMEGT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    getDuplicatedAthletes();
                }
            });
        }

        function InactivesChange(arg) {
            getDuplicatedAthletes(arg.value);
        }

        function getDuplicatedAthletes(inactivesToo) {
            if (inactivesToo !== true) {
                inactivesToo = false;
            }
            dataservice.getDuplicatedAthletes(inactivesToo).then(function(data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToUser(edzoId) {
            authenticationService.getRight('MEGTFELH').then(function (result) {
                if (result !== true) {
                    log.permissionError();
                    return;
                }
                paramHelper.setParams([{'newUser.userId': edzoId}]);
                $state.go('settings.userManagement.modifyUser');
            });
        }
    }
})();
