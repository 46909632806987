(function () {
    'use strict';

    angular
        .module('app.athletes.teams.addTeamMember')
        .controller('AddTeamMemberController', AddTeamMember);

    function AddTeamMember(log, $state, dataservice, $q, gettextCatalog, paramHelper, $scope, authenticationService, $rootScope, $filter) {
        var vm = this; $rootScope.vm = vm;

        // Page title
        $rootScope.title = gettextCatalog.getString('AddTeamMember');

        vm.columns = [
            {type: 'checkbox', textAlign: 'center', headerTextAlign: 'center', width: 35},
            {
                field: 'Name',
                headerText: gettextCatalog.getString('Name'),
                headerTextAlign: 'center',
                textAlign: 'center',
                template: '#adtemetemplateDataColumn1',
                width: 150
            },
            {field: 'BirthDate', headerText: gettextCatalog.getString('BirthDate'), width: 125, headerTextAlign: 'center', textAlign: 'center'}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            width: '100%',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.addTeamMemberToTeam = addTeamMemberToTeam;
        vm.goToNewAthlete = goToNewAthlete;

        activate();

        function activate() {
            authenticationService.getRight('MODCSOPORT').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([paramHelper.getParams(['teams.sectionId', 'teams.ageGroupId', 'teams.team']), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                        vm.csapat = results[0]['teams.team'];
                        vm.korosztaly = results[0]['teams.ageGroupId'];
                        vm.szakosztaly = results[0]['teams.sectionId'];
                        vm.MEGTSPORTLIST = results[1];
                        getPossibleTeamMembersList();
                    });
                }
            });
        }

        function getPossibleTeamMembersList() {
            return dataservice.getPossibleTeamMembersList(vm.csapat, vm.szakosztaly, vm.korosztaly)
                .then(function (data) {
                    grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'BirthDate');
                });
        }

        function addTeamMemberToTeam() {
            var selectedRows = $rootScope.getSelectedItems(grid);
            if (selectedRows.length !== 0) {
                var out = [];
                for (var i = 0; i < selectedRows.length && selectedRows[i]; i++) {
                    out.push(selectedRows[i].Id);
                }
                dataservice.addTeamMemberToTeam(out, vm.csapat).then(function (data) {
                    data = data.retValue;
                    if (data === true) {
                        getPossibleTeamMembersList();
                        log.successMsg('TeamMemberAddedToTeam');
                    }
                });
            } else {
                log.errorMsg('YouHaveToSelectLeastAthlete');
            }
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                paramHelper.setParams([{'athleteModify.sportoloId': Id}, {'athleteModify.selectedSectionId': vm.szakosztaly}, {'athleteModify.selectedAgeGroupId': vm.korosztaly}]);
                $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            } else {
                log.permissionError();
            }
        }
    }
})();
