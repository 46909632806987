(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athleteFees')
        .controller('AthleteFeesController', AthleteFeesController);

    function AthleteFeesController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, $scope, authenticationService, $state) {
        var vm = this;
        $rootScope.vm = vm;

        // Create variables
        // Dropdownlist's sources
        vm.sectionList = [];
        vm.coachList = [];
        vm.yearList = dataservice.range(new Date().getFullYear() - 4, new Date().getFullYear() + 1);
        vm.priceType = [{text: 'NK', value: 'NK'}, {text: 'K', value: 'K'}, {text: 'B', value: 'B'}, {
            text: 'EP',
            value: 'EP'
        }, {text: 'MT', value: 'MT'}];

        vm.selectedCoach = vm.coachId = 0;
        vm.selectedSection = 0;

        // Grid's variables
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {
                headerText: gettextCatalog.getString('General'),
                columns: [
                    {field: 'Ev', headerText: gettextCatalog.getString('Year'), width: 70, textAlign: 'center'},
                    {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 150, textAlign: 'center'},
                    {
                        field: 'EdzoNeve',
                        headerText: gettextCatalog.getString('CoachName'), textAlign: 'center', width: 150, textAlign: 'center',
                        template: '#feearrearscolumnTemplate2'
                    },
                    {
                        field: 'VersenyzoNeve',
                        headerText: gettextCatalog.getString('Name'), textAlign: 'center', width: 150, textAlign: 'center',
                        template: '#feearrearscolumnTemplate1'
                    },
                    {
                        field: 'Dsi_Igazolas',
                        headerText: gettextCatalog.getString('Regisztracios'),
                        textAlign: 'center',
                        width: 150
                    }
                ],
                textAlign: 'center'
            },
            {
                field: 'OsszesHatralek',
                headerText: gettextCatalog.getString('Sum'),
                textAlign: 'center',
                width: 100
            },
            {
                headerText: gettextCatalog.getString('Month'),
                columns: [
                    {field: 'Honap_1', headerText: gettextCatalog.getString('January'), textAlign: 'center', width: 75},
                    {field: 'Honap_2', headerText: gettextCatalog.getString('February'), textAlign: 'center', width: 75},
                    {field: 'Honap_3', headerText: gettextCatalog.getString('March'), textAlign: 'center', width: 75},
                    {field: 'Honap_4', headerText: gettextCatalog.getString('April'), textAlign: 'center', width: 75},
                    {field: 'Honap_5', headerText: gettextCatalog.getString('May'), textAlign: 'center', width: 75},
                    {field: 'Honap_6', headerText: gettextCatalog.getString('June'), textAlign: 'center', width: 75},
                    {field: 'Honap_7', headerText: gettextCatalog.getString('July'), textAlign: 'center', width: 75},
                    {field: 'Honap_8', headerText: gettextCatalog.getString('August'), textAlign: 'center', width: 75},
                    {field: 'Honap_9', headerText: gettextCatalog.getString('September'), textAlign: 'center', width: 75},
                    {field: 'Honap_10', headerText: gettextCatalog.getString('October'), textAlign: 'center', width: 75},
                    {field: 'Honap_11', headerText: gettextCatalog.getString('November'), textAlign: 'center', width: 75},
                    {field: 'Honap_12', headerText: gettextCatalog.getString('December'), textAlign: 'center', width: 75}
                ],
                textAlign: 'center'
            }
    ]
        ;
        vm.rowIndexUnderEditing = -1;

        // Public functions
        vm.getAthletes = getFees;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            var promises = [paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])];
            $q.all(promises).then(function (results) {
                vm.sportoloId = results[0]['athleteModify.sportoloId'];
                vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('AthleteFees');
                getFees();
            });
        }

        function getFees() {
            return dataservice.getAthleteFees(vm.sportoloId).then(function (data) {
                grid.dataSource = data.itemsList;
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
