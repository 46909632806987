(function () {
    'use strict';

    angular
        .module('app.reports.assetInventoryReportByType')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.assetInventoryReportByType',
                config: {
                    url: '/assetInventoryReportByType',
                    templateUrl: 'app/reports/assetInventoryReportByType/AssetInventoryReportByType.html',
                    controller: 'AssetInventoryReportByTypeController',
                    controllerAs: 'vm',
                    title: 'AssetInventoryReportByType',
                    settings: {
                        navId: 322224,
                        level: 3,
                        order: 2,
                        orderTitle_hu: 'Eszköz nyilvántartó (Típus)',
                        orderTitle_en: 'Asset Inventory (Type)',
                        parentId: 2224,
                        content: 'AssetInventoryReportByType',
                        activatorPermission: ['"ESZKNYILVMEGT"']
                    }
                }
            }
        ];
    }
})();
