(function () {
    'use strict';

    angular
        .module('app.reports.athleteTransfer')
        .controller('AthleteTransferController2', AthleteTransferController);

    function AthleteTransferController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $state, $scope, $filter) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AthleteTransfer');

        // Create variables
        vm.coachList = [];
        vm.sectionList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // Grid's variables
        vm.columns = [
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', width: 150, template: '#attrcolumnTemplate1'},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), textAlign: 'center', width: 150, template: '#columnTemplate2'},
            {field: 'Kerveny_Datum', headerText: gettextCatalog.getString('Kervenyezve'), textAlign: 'center', width: 150},
            {field: 'Elfogadva', type: 'boolean', displayAsCheckBox: true, headerText: gettextCatalog.getString('Accepted'), textAlign: 'center', width: 100},
            {field: 'Lezarva', type: 'boolean', displayAsCheckBox: true, headerText: gettextCatalog.getString('Closed'), textAlign: 'center', width: 100},
            {field: 'Lezarva_datum', headerText: gettextCatalog.getString('ClosedDate'), textAlign: 'center', width: 125},
            {field: 'KorcsoportValtas', type: 'boolean', displayAsCheckBox: true, headerText: gettextCatalog.getString('KorcsoportValtas'), textAlign: 'center', width: 125},
            {field: 'KerelmezoNeve', headerText: gettextCatalog.getString('Kervenyezo'), textAlign: 'center', width: 150},
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowSorting: true,
            allowTextWrap: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToNewUser = goToNewUser;
        vm.goToNewAthlete = goToNewAthlete;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('KMEDZOVALTAS')];
            $q.all(promises).then(function (res) {
                if (!res[0]) {
                    log.permissionError(true);
                } else {
                    $q.all([authenticationService.getRight('KMEDZOVALTAS'), paramHelper.getParams([], ['athleteTransferList.coachId', 'athleteTransferList.szakosztalyId'])]).then(function (results) {
                        vm.KMEDZOVALTAS = results[0];
                        vm.sectionId = results[1]['athleteTransferList.szakosztalyId'] === null ? -1 : results[1]['athleteTransferList.szakosztalyId'];
                        vm.coachId = results[1]['athleteTransferList.coachId'] === null ? -1 : results[1]['athleteTransferList.coachId'];
                    });
                    getSections();
                    setDropDowns();
                }
            })
            .catch(function () {
                log.permissionError(true);
            });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
            'at.section',
            'at.coach'
            ])
            ];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['at.section'];
                vm.coachId = results[0]['at.coach'];
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value);
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function search() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            paramHelper.setParams([
            {'at.section': vm.sectionId},
            {'at.coach': vm.coachId}
            ]);
            dataservice.getAthleteTransferList(vm.sectionId, vm.coachId).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'EdzoLezartaDatum');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzakosztalyVezetoJovahagyva');
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'KeszitesDatum');
            });
        }

        function goToNewUser(edzoId) {
            paramHelper.setParams([{'newUser.userId': edzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }

        function goToNewAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
