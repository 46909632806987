(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.trainingVisitReport')
        .controller('TrainingVisitReportController', TrainingVisitReportController);

    function TrainingVisitReportController(log, dataservice, $q, $rootScope, gettextCatalog, $scope, paramHelper, $state) {
        var vm = this;
        $rootScope.vm = vm;

        //Évek (2010-től az aktuális évig)
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev + ' / ' + (ev + 1));
        }
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear() + ' / ' + (new Date().getFullYear() + 1);
        vm.stackedHeaderRows = [];

        vm.sportoloId = undefined;
        vm.korcsoportId = undefined;
        vm.szakosztalyId = undefined;
        vm.columns = [];

        vm.columns = [
            {
                field: 'Sorszam',
                headerText: gettextCatalog.getString('RowNumber'),
                textAlign: 'center',
                width: 80,
                allowEditing: false
            }, {
                field: 'VersenyzoNeve',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'center',
                headerTextAlign: 'center',
                width: 150,
                template: '#AthleteColumnTemplate',
                allowEditing: false
            },
            {
                field: 'SzulEv',
                headerText: gettextCatalog.getString('BirthYear'),
                textAlign: 'center',
                width: 90,
                allowEditing: false
            },
            {
                headerText: gettextCatalog.getString('August'), columns:
                    [
                        {
                            field: 'AugEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'AugJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'AugHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'AugSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('September'), columns:
                    [
                        {
                            field: 'SzeptEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'SzeptJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'SzeptHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'SzeptSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('October'), columns:
                    [
                        {
                            field: 'OktEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'OktJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'OktHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'OktSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('November'), columns:
                    [
                        {
                            field: 'NovEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'NovJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'NovHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'NovSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('December'), columns:
                    [
                        {
                            field: 'DecEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'DecJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'DecHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'DecSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('January'), columns:
                    [
                        {
                            field: 'JanEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JanJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'JanHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JanSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('February'), columns:
                    [
                        {
                            field: 'FebEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'FebJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'FebHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'FebSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('March'), columns:
                    [
                        {
                            field: 'MarcEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'MarcJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'MarcHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'MarcSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('April'), columns:
                    [
                        {
                            field: 'AprEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'AprJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'AprHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'AprSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('May'), columns:
                    [
                        {
                            field: 'MajEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'MajJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'MajHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'MajSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('June'), columns:
                    [
                        {
                            field: 'JunEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JunJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'JunHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JunSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('July'), columns:
                    [
                        {
                            field: 'JulEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JulJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'JulHianyzik',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'JulSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('12month'), columns:
                    [
                        {
                            field: 'OsszEdzes',
                            headerText: gettextCatalog.getString('Sum'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'OsszJelen',
                            headerText: gettextCatalog.getString('Attendance'),
                            textAlign: 'center',
                            width: 100,
                            allowEditing: false
                        },
                        {
                            field: 'OsszJelen',
                            headerText: gettextCatalog.getString('Miss'),
                            textAlign: 'center',
                            width: 80,
                            allowEditing: false
                        },
                        {
                            field: 'OsszSzazalek',
                            headerText: gettextCatalog.getString('AttendancePercent'),
                            textAlign: 'center',
                            width: 90,
                            allowEditing: false
                        }
                    ],
                textAlign: 'center'
            }
        ];

        // Create variables

        // Public functions
        vm.reportSelect = reportSelect;
        vm.modifyAthletes = modifyAthletes;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function activate() {
            $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])]).then(function (results) {
                vm.sportoloId = results[0]['athleteModify.sportoloId'];
                vm.sportoloNev = results[0]['athleteModify.sportoloNev'];
                $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('trainingVisitReport');
            });
        }

        function reportSelect() {
            dataservice.getTrainingVisitReport(vm.selectedYear.split('/')[0], vm.sportoloId)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                });
        }

        /**
         * Public modifyAthletes(SportolokId)
         */
        function modifyAthletes(VersenyzoId, SzakosztalyId, KorosztalyId) {
            console.log('Versenyzo: ', VersenyzoId, ', SzakosztalyId: ', SzakosztalyId);
            //if (vm.MODSPORTOLO || vm.MEGTSPORTLIST) { // check permission
            paramHelper.setParams([
                {'athleteList.selectedSectionId': SzakosztalyId},
                {'athleteList.selectedAgeGroupId': KorosztalyId},
                {'athleteModify.sportoloId': VersenyzoId}
            ]);
            $state.go('athletes.athletesManagement.athleteModify.dataSheet');
            /*} else {
                log.errorMsg(gettextCatalog.getString('PermissionDenied'));
            }*/
        }
    }
})();
