(function () {
    'use strict';

    angular
        .module('app.administration.sportSpecificTest.newSportSpecificTestList')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.sportSpecificTest.newSportSpecificTestList',
                config: {
                    url: '/newSportSpecificTestList',
                    templateUrl: 'app/administration/sportSpecificTest/newSportSpecificTestList/newSportSpecificTestList.html',
                    controller: 'NewSportSpecificTestListController',
                    controllerAs: 'vm',
                    title: 'SportSpecificTestList2',
                    settings: {
                        navId: 242,
                        level: 2,
                        order: 4,
                        orderTitle_hu: 'Sportágspecifikus felmérők',
                        orderTitle_en: 'Sport Specific Tests',
                        parentId: 2,
                        content: 'NewSportSpecificTestList',
                        activatorPermission: ['"MEGTSPFELM"']
                    }
                }
            }
        ];
    }
})();
