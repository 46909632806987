(function () {
    'use strict';

    angular
        .module('app.search.user.searchuser')
        .controller('SearchUsersController', SearchUsersController);

    function SearchUsersController(log, dataservice, $q, $rootScope, gettextCatalog, $filter, authenticationService, paramHelper, $state, $scope) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('SearchUsers');

        // Create variables
        vm.userList = [];
        vm.yesno = [
            {text: gettextCatalog.getString(''), value: null},
            {text: gettextCatalog.getString('Yes'), value: true},
            {text: gettextCatalog.getString('No'), value: false}
        ];
        vm.genderList = [
            {text: gettextCatalog.getString(''), value: undefined},
            {text: gettextCatalog.getString('Male'), value: 'Male'},
            {text: gettextCatalog.getString('Female'), value: 'Female'}
        ];
        vm.columns = [
            {field: 'Id', headerText: gettextCatalog.getString('Id'), textAlign: 'center', width: '90', visible: false},
            {field: 'Nev', headerText: gettextCatalog.getString('LoginName'), textAlign: 'center', width: '115', template: '#searchusergotouser'},
            {field: 'TeljesNev', headerText: gettextCatalog.getString('FullName'), textAlign: 'center', width: '150'},
            {field: 'SzulIdo', headerText: gettextCatalog.getString('BirthDate'), textAlign: 'center', width: '120', type: 'date', format: 'yMd'},
            {field: 'Lakcim', headerText: gettextCatalog.getString('Address'), textAlign: 'center', width: '150'},
            {field: 'FelhasznaloCsoport', headerText: gettextCatalog.getString('Usergroup'), textAlign: 'center', width: '115'},
            {field: 'Elerhetosege', headerText: gettextCatalog.getString('Contact'), textAlign: 'center', width: '150'},
            {field: 'Aktiv', headerText: gettextCatalog.getString('Active'), textAlign: 'center', width: '60', type: 'boolean', displayAsCheckBox: true}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            editSettings: {allowEditing: false, allowAdding: false, allowDeleting: false},
            allowTextWrap: true,
            allowSorting: true,
            allowScrolling: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.search = search;
        vm.reset = reset;
        vm.goToUser = goToUser;

        vm.formData = {};
        vm.usergroups = [];

        resetQueryDetails ();

        function resetQueryDetails () {
            vm.formData.nev = undefined;
            vm.formData.loginNev = undefined;
            vm.formData.idCsoport = undefined;
            vm.formData.szulHelyOrszag = undefined;
            vm.formData.szulHelyVaros = undefined;
            vm.formData.szulTol = undefined;
            vm.formData.szulIg = undefined;
            vm.formData.email = undefined;
            vm.formData.lakcim = undefined;
            vm.formData.elerhetoseg = undefined;
            vm.formData.neme = undefined;
            vm.formData.allamp = undefined;
            vm.formData.anyjaNeve = undefined;
            vm.formData.edzKep = undefined;
            vm.formData.foallMh = undefined;
            vm.formData.magTol = undefined;
            vm.formData.magIg = undefined;
            vm.formData.testTol = undefined;
            vm.formData.testIg = undefined;
            vm.formData.szemSzam = undefined;
            vm.formData.tajSzam = undefined;
            vm.formData.utlevel = undefined;
            vm.formData.aktiv = undefined;
            vm.formData.egyeniSport = false;
            vm.formData.csapatSport = false;
        }

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('EDZOKERESO'), paramHelper.getParams([], ['search.user.formdata'])];
            getUserGroups();
            $q.all(promises)
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    }
                    if (results[1] && results[1]['search.user.formdata']) {
                        vm.formData = results[1]['search.user.formdata'];
                        search();
                    }
                });
        }

        function getUserGroups() {
            return dataservice.userGroupList()
                .then(function (data) {
                    vm.usergroups = data.itemsList;
                    vm.usergroups.unshift({value: undefined, text: ''});
                });
        }

        function search() {
            paramHelper.setParams([{'search.user.formdata': vm.formData}]);
            vm.userList = [];
            dataservice.searchUser(vm.formData).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzulIdo');
                vm.userList = data.itemsList;
                grid.dataSource = vm.userList;
                grid.refresh();
            });
        }

        function goToUser(edzoId) {
            authenticationService.getRight('MEGTFELH').then(function (result) {
                if (result !== true) {
                    log.permissionError();
                    return;
                }
                paramHelper.setParams([{'newUser.userId': edzoId}]);
                $state.go('settings.userManagement.modifyUser');
            });
        }

        function reset() {
            resetQueryDetails();
        }

        vm.userGroupDatabound = userGroupDatabound;
        function userGroupDatabound () {
            if (vm.usergroups.length === 1) {
                angular.element('#usergroup').ejDropDownList('selectItemByValue', vm.usergroups[0].value);
                vm.formData.idCsoport = vm.usergroups[0].value;
            } else {
                angular.element('#usergroup').ejDropDownList('selectItemByValue', vm.formData.idCsoport);
            }
        }
    }
})();
