(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.anthropometry')
        .controller('AnthropometryController', AnthropometryController);

    function AnthropometryController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $filter, $scope, DOMAIN, $timeout) {
        var vm = this;
        $rootScope.vm = vm;

        // Create variables
        vm.felmeresId = 0;
        vm.noImg = DOMAIN.baseURL + DOMAIN.userImage;
        vm.REGIFELMERES = false;
        vm.fileName = null;
        vm.oldAnthropometryTestTitle = gettextCatalog.getString('OldAnthropometryTests');
        vm.formData = {
            athletesName: '',
            athletesAddress: '',
            birthDate: '',
            endOfPuberty: '',
            chosenSports: '',
            TM: '',
            TS: '',
            HUS: '',
            DS: '',
            VAS: '',
            MKS: '',
            MMG: '',
            CRS: '',
            MKK: '',
            FFK: '',
            FK: '',
            AKK: '',
            CUK: '',
            KZK: '',
            COK: '',
            ASK: '',
            BOK: '',
            BR: '',
            HR: '',
            R: '',
            COR: '',
            LR: '',
            MSR: '',
            CSR: '',
            Felmeres: '',
            Csoport: '',
            MeresDatuma: '',
            VizsgalatotKeroNeveCime: ''
        };
        vm.selectedRowIndexPopup = -1;
        vm.columnsPopup = [{
            field: 'Id',
            visible: false,
            isPrimaryKey: true,
            isIdentity: true
        },
            {
                field: 'Felmeres',
                headerText: gettextCatalog.getString('Felmeres'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'Csoport',
                headerText: gettextCatalog.getString('Csoport'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            },
            {
                field: 'MeresDatuma',
                headerText: gettextCatalog.getString('MeresDatuma'),
                textAlign: 'center',
                width: 100,
                allowEditing: false
            }
        ];

        // Image cropper variables
        vm.cropper = {};
        vm.cropper.sourceImage = null;
        vm.cropper.croppedImage = null;
        vm.bounds = {};
        vm.bounds.left = 0;
        vm.bounds.right = 0;
        vm.bounds.top = 0;
        vm.bounds.bottom = 0;
        vm.cropDialogTitle = gettextCatalog.getString('ChooseImageTitle');

        vm.reportParameters = {};
        var printOpenedE = false;

        // Public functions
        vm.openCropDialog = openCropDialog;
        vm.doneCropDialog = doneCropDialog;
        vm.closeCropDialog = closeCropDialog;
        vm.saveForm = saveForm;
        vm.deleteImg = deleteImg;
        vm.newAnthropometryTest = newAnthropometryTest;
        vm.oldAnthropometryTest = oldAnthropometryTest;
        vm.closeWindow = closeWindow;
        vm.selectTest = selectTest;
        vm.print = print;
        vm.focusOut = focusOut;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowTextWrap: true,
            allowSorting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columnsPopup,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#GridPopup');

        // Activate page
        activate();

        function activate() {
            initKeyEvents();
            var promises = [authenticationService.getRight('MEGTANTFELM'), authenticationService.getRight('MODANTFELM'), authenticationService.getRight('ANTRKIERT')];
            $q.all(promises).then(function (results) {
                if (results[0] !== true) {
                    log.permissionError(true);
                } else {
                    vm.MODANTFELM = results[1];
                    vm.ANTRKIERT = results[2];
                    $q.all([paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'])]).then(function (data) {
                        vm.sportoloId = data[0]['athleteModify.sportoloId'];
                        vm.sportoloNev = data[0]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('Anthropometry');
                        getAthleteForAnthropometry(vm.sportoloId);
                    });
                }
            });
        }

        function getAthleteForAnthropometry(sportolo, felmeresId) {
            if (felmeresId === undefined) {
                felmeresId = 0;
            }
            dataservice.getAthleteForAnthropometry(sportolo, felmeresId).then(function (data) {
                data.ant.SzuletesiIdo = $filter('datetimeFilter')(data.ant.SzuletesiIdo);
                data.ant.MeresDatuma = $filter('datetimeFilter')(data.ant.MeresDatuma);
                data.ant.PubertaskorVege = $filter('datetimeFilter')(data.ant.PubertaskorVege);
                vm.formData = data.ant;
                vm.formData.IdSportolo = vm.sportoloId;
                vm.felmeresId = vm.formData.Id;
                if (vm.formData.Id !== 0 && vm.formData.Id !== '0') {
                    vm.REGIFELMERES = true;
                }
                if (data.ant.LogoEleresiUt && data.ant.LogoEleresiUt != 'noImg.jpg') {
                    vm.photoUrl = DOMAIN.baseURL + DOMAIN.anthropometryPhotosUrl + data.ant.LogoEleresiUt;
                } else {
                    vm.photoUrl = DOMAIN.baseURL + DOMAIN.exerciseBaseUrl + 'default.png';
                }
            });
        }

        function saveForm() {
            if (!vm.MODANTFELM) {
                log.permissionError();
                return;
            }
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg('RequiredFieldsError');
                $scope.$apply();
            } else {
                if (angular.element('#fileName').val() !== '' && vm.cropper.croppedImage !== null) {
                    vm.fileData = vm.cropper.croppedImage;
                    vm.fileName = angular.element('#fileName').val();
                } else if (vm.photoUrl === undefined) {
                    vm.fileName = 'noImg.jpg';
                }
                dataservice.saveAnthropometry(vm.formData, vm.fileData, vm.fileName).then(function (response) {
                    getAthleteForAnthropometry(response.data.sportoloId, response.data.Id);
                    if (response.data.sikeresSzinkron) {
                        log.successMsg(response.data.hibauzenet);
                    } else if (response.data.hibauzenet && response.data.hibauzenet.length > 0) {
                        log.warningMsg(response.data.hibauzenet, true);
                    }
                    log.successMsg('Saved');
                });
            }
        }

        function openCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'visible');
            angular.element('#cropDialog').ejDialog('open');
        }

        function closeCropDialog() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            angular.element('#cropDialog').ejDialog('close');
        }

        function doneCropDialog() {
            vm.photoUrl = vm.cropper.croppedImage;
            angular.element('#cropDialog').ejDialog('close');
        }

        function deleteImg() {
            angular.element('#fileName').val('');
            vm.fileName = 'oldDeleted';
            vm.photoUrl = vm.noImg;
        }

        function newAnthropometryTest() {
            vm.formData.Id = 0;
            vm.formData.Felmeres = '';
            vm.formData.Csoport = '';
            vm.formData.MeresDatuma = null;
            vm.formData.TTM = '';
            vm.formData.TTS = '';
            vm.formData.HUS = '';
            vm.formData.TDS = '';
            vm.formData.VAS = '';
            vm.formData.MKS = '';
            vm.formData.MMG = '';
            vm.formData.CRS = '';
            vm.formData.MKK = '';
            vm.formData.FFK = '';
            vm.formData.FK = '';
            vm.formData.AKK = '';
            vm.formData.CUK = '';
            vm.formData.KZK = '';
            vm.formData.COK = '';
            vm.formData.ASK = '';
            vm.formData.BOK = '';
            vm.formData.BR = '';
            vm.formData.HR = '';
            vm.formData.TR = '';
            vm.formData.COR = '';
            vm.formData.LR = '';
            vm.formData.MSR = '';
            vm.formData.CSR = '';
            vm.formData.Endomorfia = '';
            vm.formData.Mezomorfia = '';
            vm.formData.Ektomorfia = '';
            vm.formData.MetrikusConradIndex = '';
            vm.formData.PlasztikusConradIndex = '';
            vm.formData.DFDC = '';
            vm.formData.DFTZSSzazalek = '';
            vm.formData.DFTTS = '';
            vm.formData.DFDelta = '';
            vm.formData.DFBZS = '';
            vm.formData.DFBCS = '';
            vm.formData.DFBI = '';
            vm.formData.DFBZSR = '';
            vm.formData.DFBF = '';
            vm.formData.DFDCBF = '';
            vm.formData.DFVTM = '';
            vm.formData.DFBTTS = '';
            vm.formData.DFDELTASzazalek = '';
            vm.formData.DFBZSSzazalek = '';
            vm.formData.DFBCSSzazalek = '';
            vm.formData.DFBISzazalek = '';
            vm.formData.DFBZSRSzazalek = '';
            vm.formData.Testalkat = '';
            vm.formData.Sulyfelesleg = '';
            vm.formData.Erosithetoseg = '';
            vm.formData.VarhatoTestmagassag = '';
            vm.formData.SzakertoiVelemeny = '';
            vm.formData.AjanlottSportagak = '';
            $scope.$apply();
        }

        // Grid popup
        function oldAnthropometryTest() {
            vm.selectedRowIndexPopup = -1;
            angular.element('#dialogContainer').css('visibility', 'visible');
            var dialogObj = angular.element('#oldAnthropometryTestsWindow').data('ejDialog');
            dialogObj.open();

            dataservice.getOldAnthropometryTest(vm.sportoloId).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'MeresDatuma');
            });
        }

        function closeWindow() {
            angular.element('#dialogContainer').css('visibility', 'hidden');
            var dialogObj = angular.element('#oldAnthropometryTestsWindow').data('ejDialog');
            dialogObj.close();
        }

        function selectTest() {
            var row = grid.getSelectedRecords()[0];
            if (!row) {
                vm.selectedRowIndexPopup = -1;
            } else {
                vm.selectedRowIndexPopup = row.Id;
            }
            getAthleteForAnthropometry(vm.sportoloId, vm.selectedRowIndexPopup);
            closeWindow();
        }

        function print() {
            $timeout(function () {
                vm.reportParameters = {
                    'Tomeges': vm.felmeresId + ',' + vm.sportoloId,
                    'SportoloId': 0,
                    'AntropometriaId': 0,
                    'SportiskolaLogo': vm.photoUrl
                };

                if (printOpenedE) {
                    angular.element('#printDialoganthtest2').ejDialog('open');
                } else {
                    printOpenedE = true;
                    angular.element('#printDialoganthtest2').ejDialog({
                        height: '95%',
                        width: '95%',
                        enablemodal: 'true',
                        'position-x': '0',
                        'position-y': '0',
                        target: '#mainContent',
                        enableresize: 'false'
                    });
                }

                //angular.element('#printDialoganthtest2').ejDialog('open');
            }, 0);
        }

        var tizedesvesszo = false;
        var voltLostFocus = false;

        function initKeyEvents() {
            angular.element('body').on('keyup', 'button', function () {
                $(this).submit();
            });

            angular.element('body').on('keyup', 'select, textarea, input', function (e) {
                var form = $(this).parents('form:eq(0)');
                var focusable;
                var next;
                if (e.keyCode == 13) {
                    var tab = $((this)).attr('tabIndex');
                    tab++;
                    $('[tabindex=\'' + tab + '\']').focus();

                    return false;
                }
            });

            $('body').on('keyup', 'input', function (e) {
                var form = $(this).parents('form:eq(0)');
                var focusable;
                var next;
                var keyCode = e.keyCode;
                //volt már tizedesvessző, és számot adott meg, következő mezőre lépés
                if (tizedesvesszo === true && ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105))) {
                    tizedesvesszo = false;
                    var tab = $(this).attr('tabindex');
                    tab++;
                    $('[tabindex=\'' + tab + '\']').focus();

                    return false;
                }
                //ha még nem volt tizedesvessző, és .-ot, vagy ,-t nyomott le
                if (!tizedesvesszo && (keyCode === 110 || keyCode === 188)) {
                    tizedesvesszo = true;
                }
                //ha volt tizedesvessző, de BackSpace-t nyom le
                if (tizedesvesszo && keyCode === 8) {
                    tizedesvesszo = false;
                }
            });
        }

        function focusOut(args) {
            if (voltLostFocus || args.value == null) {
                return;
            }
            if (!validate(args.model.minValue, args.model.maxValue, args.value)) {
                voltLostFocus = true;
                var audio2 = new Audio('/sounds/error.mp3');
                audio2.play();
                args.cancel = true;
                document.getElementsByName(args.model.name)[0].children[1].children[0].children[0].children[0].focus();
                voltLostFocus = false;
                return true;
            } else {
                if (args.model.name === 'MSR') {
                    var audio = new Audio('/sounds/save.mp3');
                    audio.play();
                } else {
                    var audio = new Audio('/sounds/focusout.mp3');
                    audio.play();
                }
            }
        }

        function validate(min, max, value) {
            return value >= min && value <= max;
        }
    }
})();
