(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesByAge')
        .controller('ActiveAthletesByAgeController', ActiveAthletesByAgeController);

    function ActiveAthletesByAgeController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('ActiveAthletesByAge');
        vm.sectionList = [];

        vm.columnWith = 105;
        vm.columns = [
            {field: 'SzuletesiEv', headerText: gettextCatalog.getString('BirthYear'), width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_year', textAlign: 'center'},
            {field: 'Szam', headerText: gettextCatalog.getString('NumberOfAthletes'), width: vm.columnWith, cssClass: 'rotate90degreegridheader4', textAlign: 'center'},
            {field: 'SzamFiu', headerText: gettextCatalog.getString('NumberOfBoys'), width: vm.columnWith, cssClass: 'rotate90degreegridheader4', textAlign: 'center'},
            {field: 'SzamLany', headerText: gettextCatalog.getString('NumberOfGirls'), width: vm.columnWith, cssClass: 'rotate90degreegridheader4', textAlign: 'center'}
        ];

        vm.Date = new Date();
        vm.selectedSection = 0;
        vm.openSection = openSection;
        vm.openBirthYear = openBirthYear;

        vm.showEachSections = false;
        vm.accessibleSections = [];
        vm.accessibleSectionsStr = [];
        vm.checkboxChanged = checkboxChanged;
        vm.checkboxEnabled = true;
        vm.checked = false;
        vm.userId = -1;
        vm.isSectionLeader = false;
        vm.isAdmin = false;
        vm.sectionList = [];
        vm.griddata = [];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('KMSPORTELETKOR').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    getSectionList().then(function () {
                        initColumns();
                        getNumbers(vm.Date);
                    });
                }
            });
        }

        function getSectionList() {
            return dataservice.sectionDropDownListAll()
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        function getNumbers(date) {
            dataservice.activeAthletesByAge(date, vm.selectedSection)
                .then(function (data) {
                    grid.dataSource = data.itemsList;
                    vm.userId = data.userId;
                    vm.isAdmin = data.isAdmin;
                    vm.isSectionLeader = data.isSectionLeader;
                    vm.accessibleSections = data.felhasznaloSzakosztalyai;
                    vm.accessibleSectionsStr = data.felhasznaloSzakosztalyaiStr;
                    vm.yearListFull = data.itemsList;
                });
        }

        function openSection(sectionID, year) {
            var sectionBelongsToUser = false;
            for (var i = 0; i < vm.accessibleSections.length; i++) {
                if (vm.accessibleSections[i] == sectionID) {
                    sectionBelongsToUser = true;
                    break;
                }
            }
            if (sectionBelongsToUser || vm.isAdmin) {
                var yearint = parseInt(year);
                paramHelper.setParams([
                    {'sectionID': sectionID},
                    {'year': yearint}
                ]);
                $state.go('reports.statisticalReports.athletesByBirthYear');
            } else {
                log.warningMsg('You do not have permission to access the selected section details!');
            }
        }

        function openBirthYear(year) {
            paramHelper.setParams([
                {'year': year}
            ]);
            $state.go('reports.statisticalReports.athletesByYear');
        }

        function checkboxChanged(args) {
            vm.showEachSections = args.value;
            filterAthletes();
        }

        function initColumns() {
            for (var i = 0; i < vm.sectionList.length; i++) {
                switch (vm.sectionList[i].value) {
                    case 1:
                        vm.columns.push({field: '_sz_01', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops01', textAlign: 'center'});
                        break;
                    case 2:
                        vm.columns.push({field: '_sz_02', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops02', textAlign: 'center'});
                        break;
                    case 3:
                        vm.columns.push({field: '_sz_03', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops03', textAlign: 'center'});
                        break;
                    case 4:
                        vm.columns.push({field: '_sz_04', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops04', textAlign: 'center'});
                        break;
                    case 5:
                        vm.columns.push({field: '_sz_05', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops05', textAlign: 'center'});
                        break;
                    case 6:
                        vm.columns.push({field: '_sz_06', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops06', textAlign: 'center'});
                        break;
                    case 7:
                        vm.columns.push({field: '_sz_07', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops07', textAlign: 'center'});
                        break;
                    case 8:
                        vm.columns.push({field: '_sz_08', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops08', textAlign: 'center'});
                        break;
                    case 9:
                        vm.columns.push({field: '_sz_09', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops09', textAlign: 'center'});
                        break;
                    case 10:
                        vm.columns.push({field: '_sz_10', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops10', textAlign: 'center'});
                        break;
                    case 11:
                        vm.columns.push({field: '_sz_11', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops11', textAlign: 'center'});
                        break;
                    case 12:
                        vm.columns.push({field: '_sz_12', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops12', textAlign: 'center'});
                        break;
                    case 13:
                        vm.columns.push({field: '_sz_13', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops13', textAlign: 'center'});
                        break;
                    case 14:
                        vm.columns.push({field: '_sz_14', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops14', textAlign: 'center'});
                        break;
                    case 15:
                        vm.columns.push({field: '_sz_15', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops15', textAlign: 'center'});
                        break;
                    case 16:
                        vm.columns.push({field: '_sz_16', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops16', textAlign: 'center'});
                        break;
                    case 17:
                        vm.columns.push({field: '_sz_17', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops17', textAlign: 'center'});
                        break;
                    case 18:
                        vm.columns.push({field: '_sz_18', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops18', textAlign: 'center'});
                        break;
                    case 19:
                        vm.columns.push({field: '_sz_19', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops19', textAlign: 'center'});
                        break;
                    case 20:
                        vm.columns.push({field: '_sz_20', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops20', textAlign: 'center'});
                        break;
                    case 21:
                        vm.columns.push({field: '_sz_21', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops21', textAlign: 'center'});
                        break;
                    case 22:
                        vm.columns.push({field: '_sz_22', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops22', textAlign: 'center'});
                        break;
                    case 23:
                        vm.columns.push({field: '_sz_23', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops23', textAlign: 'center'});
                        break;
                    case 24:
                        vm.columns.push({field: '_sz_24', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops24', textAlign: 'center'});
                        break;
                    case 25:
                        vm.columns.push({field: '_sz_25', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops25', textAlign: 'center'});
                        break;
                    case 26:
                        vm.columns.push({field: '_sz_26', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops26', textAlign: 'center'});
                        break;
                    case 27:
                        vm.columns.push({field: '_sz_27', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops27', textAlign: 'center'});
                        break;
                    case 28:
                        vm.columns.push({field: '_sz_28', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops28', textAlign: 'center'});
                        break;
                    case 29:
                        vm.columns.push({field: '_sz_29', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops29', textAlign: 'center'});
                        break;
                    case 30:
                        vm.columns.push({field: '_sz_30', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops30', textAlign: 'center'});
                        break;
                    case 31:
                        vm.columns.push({field: '_sz_31', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops31', textAlign: 'center'});
                        break;
                    case 32:
                        vm.columns.push({field: '_sz_32', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops32', textAlign: 'center'});
                        break;
                    case 33:
                        vm.columns.push({field: '_sz_33', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops33', textAlign: 'center'});
                        break;
                    case 34:
                        vm.columns.push({field: '_sz_34', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops34', textAlign: 'center'});
                        break;
                    case 35:
                        vm.columns.push({field: '_sz_35', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops35', textAlign: 'center'});
                        break;
                    case 36:
                        vm.columns.push({field: '_sz_36', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops36', textAlign: 'center'});
                        break;
                    case 37:
                        vm.columns.push({field: '_sz_37', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops37', textAlign: 'center'});
                        break;
                    case 38:
                        vm.columns.push({field: '_sz_38', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops38', textAlign: 'center'});
                        break;
                    case 39:
                        vm.columns.push({field: '_sz_39', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_ops39', textAlign: 'center'});
                        break;
                    case vm.columnWith:
                        vm.columns.push({field: '_sz_vm.columnWith', headerText: vm.sectionList[i].text, width: vm.columnWith, cssClass: 'rotate90degreegridheader4', template: '#aaba_opsvm.columnWith'});
                        break;
                }
            }
            grid.refreshColumns();
        }
    }
})();
