(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.weeklyTrainingPlans')
        .controller('WeeklyTrainingPlansController', WeeklyTrainingPlansController);

    function WeeklyTrainingPlansController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $state, $scope, $filter, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('WeeklyTrainingPlans');

        // Create variables
        vm.coachList = [];
        vm.sectionList = [];

        vm.dateToValue = getMonday(new Date(), 6);
        vm.dateFromValue = getMonday(new Date(), 0);
        // Grid's variables
        vm.columns = [
            {field: 'Link', headerText: '', template: '#wetrplcolumnTemplate1', textAlign: 'center', width: 60},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), textAlign: 'center', width: 150},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), textAlign: 'center', template: '#wetrplcolumnTemplate2', width: 150},
            {field: 'KorcsoportNeve', headerText: gettextCatalog.getString('AgeGroup'), textAlign: 'center', width: 125},
            {field: 'HetSzama', headerText: gettextCatalog.getString('WeekNumber'), textAlign: 'center', width: 115},
            {field: 'Makrociklus', headerText: gettextCatalog.getString('MacroCycleNumber'), textAlign: 'center', width: 150},
            {field: 'Hetfo', headerText: gettextCatalog.getString('Monday'), textAlign: 'center', width: 115},
            {field: 'Vasarnap', headerText: gettextCatalog.getString('Sunday'), textAlign: 'center', width: 115}
        ];

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.search = search;
        vm.goToWeeklyTrainingPlan = goToWeeklyTrainingPlan;
        vm.openCoach = openCoach;
        vm.coachSelect = coachSelect;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Activate page
        activate();

        function getMonday(d, plusDay) {
            d = new Date(d);
            var day = d.getDay();
            var diff = d.getDate() - day + (day == 0 ? -6 : 1) + plusDay; // adjust when day is sunday
            return new Date(d.setDate(diff));
        }

        function activate() {
            authenticationService.getRight('VEZETOIHE').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    Promise.all([authenticationService.getRight('MEGTHETIEDZTERV'), paramHelper.getParams([], ['managementReports.weeklyTrainingPlans.szakosztalyId', 'managementReports.weeklyTrainingPlans.coachId', 'managementReports.weeklyTrainingPlans.dateFrom', 'managementReports.weeklyTrainingPlans.dateTo'])])
                        .then((results) => {
                            vm.MEGTHETIEDZTERV = results[0];
                            vm.sectionId = results[1]['managementReports.weeklyTrainingPlans.szakosztalyId'];
                            vm.coachId = results[1]['managementReports.weeklyTrainingPlans.coachId'];
                            vm.dateFromValue = results[1]['managementReports.weeklyTrainingPlans.dateFrom'] ? results[1]['managementReports.weeklyTrainingPlans.dateFrom'] : vm.dateFromValue;
                            vm.dateToValue = results[1]['managementReports.weeklyTrainingPlans.dateTo'] ? results[1]['managementReports.weeklyTrainingPlans.dateTo'] : vm.dateToValue;
                            $timeout(function () {
                                angular.element('#datepickerFrom').ejDatePicker({value: vm.dateFromValue});
                                angular.element('#datepickerTo').ejDatePicker({value: vm.dateToValue});
                            }, 0);
                            getSections();
                        });
                }
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        vm.dateFromDB = function () {
            console.log('vm.dateFromDB');
        };

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after section selected
        function sectionSelect(args) {
            getActualCoachesList(args.value, vm.dateFromValue, vm.dateToValue);
        }

        vm.dateChange = function (args) {
            getActualCoachesList(vm.sectionId, args.value, vm.dateToValue);
        };

        vm.dateChange2 = function (args) {
            getActualCoachesList(vm.sectionId, vm.dateFromValue, args.value);
        };

        function getActualCoachesList(sectionId, dateFrom, dateTo) {
            return dataservice.coachDropDownList(sectionId, 0, dateFrom, dateTo).then(function (data) {
                if (data.itemsList.length > 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            search();
        }

        function search() {
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getWeeklyTrainingPlans(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Hetfo');
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'Vasarnap');
            });
            paramHelper.setParams([
                {'weeklyTrainingPlans.szakosztalyId': vm.sectionId},
                {'weeklyTrainingPlans.coachId': vm.coachId},
                {'managementReports.weeklyTrainingPlans.szakosztalyId': vm.sectionId},
                {'managementReports.weeklyTrainingPlans.coachId': vm.coachId},
                {'managementReports.weeklyTrainingPlans.dateFrom': vm.dateFromValue},
                {'managementReports.weeklyTrainingPlans.dateTo': vm.dateToValue}
            ]);
        }

        function goToWeeklyTrainingPlan(trainingPlanId) {
            if (!vm.MEGTHETIEDZTERV) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([
                {
                    'weeklyTrainingPlan.trainingPlanId': trainingPlanId
                },
                {
                    'weeklyTrainingPlans.szakosztalyId': vm.sectionId
                },
                {
                    'weeklyTrainingPlans.coachId': vm.coachId
                }
            ]);
            $state.go('administration.weeklyTrainingPlan.weeklyTrainingPlanReport');
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
