(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.joinedAthletesByCoach')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports.joinedAthletesByCoach',
                config: {
                    url: '/joinedAthletesByCoach',
                    templateUrl: 'app/reports/statisticalReports/joinedAthletesByCoach/joinedAthletesByCoach.html',
                    controller: 'JoinedAthletesByCoachController',
                    controllerAs: 'vm',
                    title: 'JoinedAthletesByCoach',
                    settings: {
                        navId: 36214,
                        level: 3,
                        order: 6,
                        orderTitle_hu: 'Belépők száma edzőnként',
                        orderTitle_en: 'Number of New Athletes by Coaches',
                        parentId: 214,
                        content: 'JoinedAthletesByCoach',
                        activatorPermission: ['"KMBELEPEDZO"']
                    }
                }
            }
        ];
    }
})();
