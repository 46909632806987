(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports',
                config: {
                    title: 'statisticalReports',
                    url: '/statisticalReports',
                    templateUrl: 'app/reports/statisticalReports/statisticalReports.html',
                    settings: {
                        navId: 214,
                        level: 2,
                        order: 1,
                        orderTitle_hu: 'Statisztikai kimutatások',
                        orderTitle_en: 'Statistical Reports',
                        parentId: 4,
                        content: 'StatisticalReports',
                        activatorPermission: [
                            '"KMSPORTELETKOR"',
                            '"KMSPORTEDZO"',
                            '"KMSPORTALAK"',
                            '"KMSPORTSZAK"',
                            '"KMSPORTALAKSZO"',
                            '"SPJELENLETINELK"',
                            '"KMBELEPEDZO"',
                            '"KMBELSZAK"',
                            '"KMKILEPEDZO"',
                            '"KMKILSZAK"'
                        ]
                    }
                }
            }
        ];
    }
})();
