(function () {
    'use strict';

    angular
        .module('app.administration.annualSeasonPlan.annualSeasonPlanReportPage1')
        .controller('AnnualSeasonPlanReportPage1Controller', AnnualSeasonPlanReportPage1Controller);

    function AnnualSeasonPlanReportPage1Controller($rootScope, gettextCatalog, dataservice, log, $q, authenticationService, paramHelper, $scope, $state, $timeout) {
        var vm = this;
        $rootScope.vm = vm; // jshint ignore: line

        // Page title
        $rootScope.title = gettextCatalog.getString('AnnualSeasonPlan');
        vm.columns = [];
        vm.egyeni = false;
        vm.maKorabbiEditable = false;
        vm.annualSeasonPlanReportId = 0;
        vm.loadedById = false;

        //Évek (2010-től az aktuális évig)
        vm.years = [];
        for (var ev = 2010; ev <= new Date().getFullYear(); ev++) {
            vm.years.push(ev + ' / ' + (ev + 1));
        }
        //Kiválasztott év (alapból az aktuális)
        vm.selectedYear = new Date().getFullYear() + ' / ' + (new Date().getFullYear() + 1);
        //Edzők listája, kiválasztott edző
        vm.coachList = [];
        vm.selectedCoach = -1;
        vm.coachContact = '';
        vm.coachEmail = '';
        //Szakosztályok listája, kiválasztott szakosztály
        vm.sectionList = [];
        vm.selectedSection = -1;
        //Korcsoportok listája, kiválasztott korcsoport
        vm.ageGroupList = [];
        vm.selectedAgeGroup = -1;
        // coach dropdown
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        // section dropdown
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        // ageGroup dropdown
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        // year dropdown
        vm.yearSelect = yearSelect;
        // jump page
        vm.JumpPageList = [
            '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers'),
            '2 - ' + gettextCatalog.getString('PreparationAndCompetitionPast'),
            '3 - ' + gettextCatalog.getString('OutstandingResults'),
            '4 - ' + gettextCatalog.getString('CapitalObjectives'),
            '5 - ' + gettextCatalog.getString('CompetitionPlansAndObjectives'),
            '6 - ' + gettextCatalog.getString('YearlyTablePlan')
        ];

        vm.noAnnualSeasonPlan = false;
        vm.hasAnnualSeasonPlan = false;
        vm.hasSignedAnnualSeasonPlan = false;
        vm.hasAcceptedAnnualSeasonPlan = false;

        vm.data = [];
        vm.autoSelect = false;

        // functions
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.annualSeasonPlanReportSelect = annualSeasonPlanReportSelect;
        vm.goToNewAthlete = goToNewAthlete;
        vm.refreshData = refreshData;
        vm.goToNextPage = goToNextPage;
        vm.annualSeasonPlanReportCreate = annualSeasonPlanReportCreate;
        vm.goToOlderAnnualSeasonPlanReports = goToOlderAnnualSeasonPlanReports;
        vm.jumpPageDataBound = jumpPageDataBound;
        vm.jumpPageSelect = jumpPageSelect;

        initGrid();

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false},
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([paramHelper.getParams([],
                [
                    'AnnualSeasonPlanReport.Id',
                    'AnnualSeasonPlanReport.sectionId',
                    'AnnualSeasonPlanReport.ageGroupId',
                    'AnnualSeasonPlanReport.coachId',
                    'AnnualSeasonPlanReport.year'
                ]),
                    authenticationService.getRight('EDZO'),
                    authenticationService.getRight('ADMIN'),
                    authenticationService.getRight('SZAKOSZTVEZ'),
                    authenticationService.getRight('TECHIGAZGATO'),
                    authenticationService.getRight('EVVERSTALAIR1'),
                    authenticationService.getRight('EVVERSTALAIR2'),
                    authenticationService.getRight('UJEVVERST'),
                    authenticationService.getRight('MEGTSPORTLIST'),
                    authenticationService.getRight('MEGTEVVERST')])
                .then(function (results) {
                    vm.EDZO = results[1];
                    vm.ADMIN = results[2];
                    vm.SZAKOSZTVEZ = results[3];
                    vm.TECHIGAZGATO = results[4];
                    vm.EVVERSTALAIR1 = results[5];
                    vm.EVVERSTALAIR2 = results[6];
                    vm.UJEVVERST = results[7];
                    vm.MEGTSPORTLIST = results[8];
                    vm.MEGTEVVERST = results[9];
                    if (!vm.MEGTEVVERST) {
                        log.permissionError(true);
                        return;
                    }
                    console.log('results', results[0]);
                    vm.selectedSection = results[0]['AnnualSeasonPlanReport.sectionId'];
                    vm.selectedAgeGroup = results[0]['AnnualSeasonPlanReport.ageGroupId'];
                    vm.selectedCoach = results[0]['AnnualSeasonPlanReport.coachId'];
                    if (results[0]['AnnualSeasonPlanReport.year'] != null) {
                        vm.selectedYear = results[0]['AnnualSeasonPlanReport.year'];
                    }
                    if (results[0]['AnnualSeasonPlanReport.Id'] && results[0]['AnnualSeasonPlanReport.Id'] !== -1) {
                        dataservice.annualSeasonPlanReportSelectById(results[0]['AnnualSeasonPlanReport.Id'])
                            .then(function (data) {
                                $timeout(function () {
                                    vm.maKorabbiEditable = true;
                                    vm.jumpedPage = '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers');
                                    vm.defaultJumpPage = '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers');
                                    $scope.$apply();
                                }, 100);

                                if (data.EdzoLezarta) {
                                    vm.hasSignedAnnualSeasonPlan = true;
                                } else if (data.SzakosztalyvezetoJovahagyta) {
                                    vm.hasAcceptedAnnualSeasonPlan = true;
                                } else {
                                    vm.hasAnnualSeasonPlan = true;
                                }
                                vm.selectedCoach = data.IdEdzo;
                                vm.selectedSection = data.IdSzakosztaly;
                                vm.selectedAgeGroup = data.IdKorosztaly;
                                vm.loadedById = true;
                                $timeout(function () {
                                    angular.element('#maEv').ejDropDownList('selectItemByValue', new Date(data.ErvenyessegTol).getFullYear() + ' / ' + new Date(data.ErvenyessegIg).getFullYear());
                                    getCoaches();
                                    vm.autoSelect = true;
                                    viewAnnualSeasonPlanReport(data);
                                    annualSeasonPlanReportSelect();
                                    paramHelper.removeParam('AnnualSeasonPlanReport.Id');
                                }, 333);
                            });
                    } else {
                        getCoaches();
                    }
                });
        }

        //edzők lekérése
        function getCoaches() {
            return dataservice.coachSelect().then(
                function (data) {
                    vm.coachList = data.itemsList;
                }
            );
        }

        // Run after data bounded
        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#maEdzo').ejDropDownList('selectItemByValue', vm.selectedCoach);
            }
        }

        // Run after selection
        function coachSelect(args) {
            vm.selectedCoach = args.value;
            vm.sectionList = [];
            vm.ageGroupList = [];
            getSections();
            dataservice.getTrainerEmailContact(vm.selectedCoach)
                .then(function (data) {
                    vm.coachContact = data.retValue.contact;
                    vm.coachEmail = data.retValue.email;
                });
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownList(vm.selectedCoach, true)
                .then(function (data) {
                    vm.sectionList = data.itemsList;
                });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#maSzakosztaly').ejDropDownList('selectItemByValue', vm.selectedSection);
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.selectedSection = args.value;
            getAgeGroups();
            dataservice.sectionIndividualOrTeam(vm.selectedSection)
                .then(function (data) {
                    vm.egyeni = data.retValue;
                });
        }

        //korcsoportok lekérése
        function getAgeGroups() {
            return dataservice.ageGroupSelectYear(vm.selectedCoach, vm.selectedSection, vm.selectedYear.split('/')[0])
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        // Run after data bounded
        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#maKorosztaly').ejDropDownList('selectItemByValue', vm.selectedAgeGroup);
                var vmi = angular.element('#maKorosztaly').data('ejDropDownList');
                if (vm.autoSelect && vmi.selectedIndexValue >= 0) {
                    annualSeasonPlanReportSelect();
                }
            }
        }

        // Run after selection
        function ageGroupSelect(args) {
            vm.selectedAgeGroup = args.value;
        }

        // Run after selection
        function yearSelect(args) {
            vm.selectedYear = args.value;
            if (vm.loadedById === false) {
                getAgeGroups();
            } else {
                vm.loadedById = false;
            }
        }

        function jumpPageDataBound() {
            angular.element('#maKorabbi').ejDropDownList('selectItemByValue', vm.defaultJumpPage);
            vm.selectedJumpPage = vm.defaultJumpPage;
        }

        function jumpPageSelect(args) {
            vm.selectedJumpPage = args.value;
            var sorszam = vm.selectedJumpPage.split('-')[0];
            if (sorszam === '1 ') {
                return;
            }

            if (!vm.hasSignedAnnualSeasonPlan) {
                dataservice.saveAnnualSeasonPlanMembers(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                switch (sorszam) {
                    case '1 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage1');
                        break;
                    case '2 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
                        break;
                    case '3 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage3');
                        break;
                    case '4 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage4');
                        break;
                    case '5 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage5');
                        break;
                    case '6 ':
                        $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage6');
                        break;
                }
            }, 100);
        }

        function annualSeasonPlanReportSelect() {
            vm.annualSeasonPlanReportId = 0;
            vm.coachContact = '';
            vm.coachEmail = '';
            vm.data = [];
            grid.dataSource = vm.data;
            // a syncfusion direktívák miatt
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }

            paramHelper.setParams([{'AnnualSeasonPlanReport.coachId': vm.selectedCoach}]);
            paramHelper.setParams([{'AnnualSeasonPlanReport.sectionId': vm.selectedSection}]);
            paramHelper.setParams([{'AnnualSeasonPlanReport.ageGroupId': vm.selectedAgeGroup}]);
            paramHelper.setParams([{'AnnualSeasonPlanReport.year': vm.selectedYear}]);

            dataservice.getTrainerEmailContact(vm.selectedCoach)
                .then(function (data) {
                    if (data.retValue.contact) {
                        vm.coachContact = data.retValue.contact;
                    }
                    if (data.retValue.email) {
                        vm.coachEmail = data.retValue.email;
                    }
                });
            vm.data = [];
            dataservice.annualSeasonPlanReportSelect(vm.selectedSection, vm.selectedAgeGroup, vm.selectedCoach, vm.selectedYear.split('/')[0], vm.egyeni)
                .then(function (args) {
                    showMessages(args);
                    $timeout(function () {
                        vm.maKorabbiEditable = true;
                        vm.jumpedPage = '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers');
                        vm.defaultJumpPage = '1 - ' + gettextCatalog.getString('TheMostImportantDataOfAGroupsMembers');
                        $scope.$apply();
                    }, 100);
                });
        }

        function showMessages(args) {
            if (args.Id === 0) {
                vm.noAnnualSeasonPlan = true;
                vm.hasAnnualSeasonPlan = false;
                vm.hasSignedAnnualSeasonPlan = false;
                vm.hasAcceptedAnnualSeasonPlan = false;
            } else {
                vm.noAnnualSeasonPlan = false;
                vm.hasAnnualSeasonPlan = false;
                vm.hasSignedAnnualSeasonPlan = false;
                vm.hasAcceptedAnnualSeasonPlan = false;
                if (args.EdzoLezarta) {
                    vm.hasSignedAnnualSeasonPlan = true;
                } else if (args.SzakosztalyvezetoJovahagyta) {
                    vm.hasAcceptedAnnualSeasonPlan = true;
                } else {
                    vm.hasAnnualSeasonPlan = true;
                }
                viewAnnualSeasonPlanReport(args);
            }
        }

        function viewAnnualSeasonPlanReport(args) {
            vm.annualSeasonPlanReportId = args.Id;
            vm.coachContact = args.EdzoMobil;
            vm.coachEmail = args.EdzoEmail;
            if (vm.annualSeasonPlanReportId) {
                dataservice.annualSeasonPlanReportMembersDataByIdSelect(vm.annualSeasonPlanReportId)
                    .then(function (data) {
                        vm.data = data.itemsList;
                        grid.dataSource = vm.data;
                    });
            }
        }

        function initGrid() {
            vm.columns = [];
            vm.columns = [
                {
                    field: 'Id',
                    visible: false,
                    isPrimaryKey: true,
                    isIdentity: true
                },
                {
                    field: 'Sorszam',
                    headerText: gettextCatalog.getString('RowNumber'),
                    textAlign: 'center',
                    width: 80,
                    allowEditing: false
                },
                {
                    field: 'SportoloNeve',
                    headerText: gettextCatalog.getString('AtlethesName'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false,
                    template: '#anseplrepa1templateDataColumn1'
                },
                {
                    field: 'SzuletesiIdo',
                    headerText: gettextCatalog.getString('BirthDate'),
                    textAlign: 'center',
                    width: 125,
                    allowEditing: false
                },
                {
                    field: 'SITagsagKezdete',
                    headerText: gettextCatalog.getString('StartMemberDate'),
                    textAlign: 'center',
                    width: 125,
                    allowEditing: false
                },
                {
                    field: 'IskolaNeveCime',
                    headerText: gettextCatalog.getString('SchoolNameAddress'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                },
                {
                    field: 'TestneveloTanarNeve',
                    headerText: gettextCatalog.getString('SportingTeacherName'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                },
                {
                    field: 'Lakcim',
                    headerText: gettextCatalog.getString('Address'),
                    textAlign: 'center',
                    width: 150,
                    allowEditing: false
                }
            ];
        }

        function goToNewAthlete(Id) {
            if (vm.MEGTSPORTLIST) {
                swal({
                    title: gettextCatalog.getString('AreYouSureGo'),
                    text: gettextCatalog.getString('AreYouSureYouWantToGoToAthlete'),
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: gettextCatalog.getString('GoToAthlete')
                }).then(function (isConfirmed) {
                    if (isConfirmed.value) {
                        paramHelper.setParams([
                            {'athleteModify.sportoloId': Id}
                        ]);
                        $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                    }
                });
            } else {
                log.permissionError();
            }
        }

        function refreshData() {
            if (vm.annualSeasonPlanReportId) {
                dataservice.annualSeasonPlanDeleteMembers(vm.annualSeasonPlanReportId)
                    .then(function () {
                        dataservice.coachAthletesByAgeGroupForAnnualSeasonPlan(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup, vm.annualSeasonPlanReportId)
                            .then(function (args) {
                                vm.data = [];
                                vm.data = args.itemsList;
                                grid.dataSource = vm.data;
                                log.successMsg(gettextCatalog.getString('RefreshComplete'));
                                if (!vm.hasSignedAnnualSeasonPlan) {
                                    dataservice.saveAnnualSeasonPlanMembers(vm.data)
                                        .then(function () {
                                            log.successMsg('SaveCompleted');
                                        });
                                }
                            });
                    });
            } else {
                dataservice.coachAthletesByAgeGroupForAnnualSeasonPlan(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup, vm.annualSeasonPlanReportId)
                    .then(function (args) {
                        vm.data = [];
                        vm.data = args.itemsList;
                        grid.dataSource = vm.data;
                        log.successMsg(gettextCatalog.getString('RefreshComplete'));
                    });
            }
        }

        function goToNextPage() {
            if (!vm.hasSignedAnnualSeasonPlan) {
                dataservice.saveAnnualSeasonPlanMembers(vm.data)
                    .then(function () {
                        log.successMsg('SaveCompleted');
                    });
            }
            $timeout(function () {
                paramHelper.setParams([{'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId}]);
                $state.go('administration.annualSeasonPlan.annualSeasonPlanReportPage2');
            }, 100);
        }

        function annualSeasonPlanReportCreate() {
            vm.fej = {};
            vm.fej.DSIVezetoJovahagyta = false;
            vm.fej.EdzoEmail = vm.coachEmail;
            vm.fej.EdzoLezarta = false;
            vm.fej.EdzoMobil = vm.coachContact;
            vm.fej.EdzoNeve = angular.element('#maEdzo').data('ejDropDownList').getSelectedItem()[0].innerText;
            vm.fej.EgyebCelkituzesek = '';
            vm.fej.Egyeni = vm.egyeni;
            vm.fej.ErvenyessegIg = moment({y: vm.selectedYear.split('/')[1].trim(), M: 6, d: 31}).format('YYYY-MM-DD');
            vm.fej.ErvenyessegTol = moment({y: vm.selectedYear.split('/')[0].trim(), M: 7, d: 1}).format('YYYY-MM-DD');
            vm.fej.IdEdzo = vm.selectedCoach;
            vm.fej.IdKorosztaly = vm.selectedAgeGroup;
            vm.fej.IdSzakosztaly = vm.selectedSection;
            vm.fej.KeszitesDatum = moment().format('YYYY-MM-DD');
            vm.fej.KorosztalyNeve = angular.element('#maKorosztaly').data('ejDropDownList').getSelectedItem()[0].innerText;
            vm.fej.SzakosztalyNeve = angular.element('#maSzakosztaly').data('ejDropDownList').getSelectedItem()[0].innerText;
            dataservice.saveAnnualSeasonPlanHead(vm.fej)
                .then(function (args) {
                    vm.annualSeasonPlanReportId = args.retValue;
                    vm.fej.Id = args.retValue;
                    dataservice.coachAthletesByAgeGroupForAnnualSeasonPlan(vm.selectedCoach, vm.selectedSection, vm.selectedAgeGroup, vm.fej.Id)
                        .then(function (args) {
                            vm.data = [];
                            vm.data = args.itemsList;
                            grid.dataSource = vm.data;
                            log.successMsg(gettextCatalog.getString('AnnualSeasonPlanCreated'));
                        });
                    showMessages(vm.fej);
                });
        }

        function goToOlderAnnualSeasonPlanReports() {
            paramHelper.setParams([{
                'AnnualSeasonPlanReport.Id': vm.annualSeasonPlanReportId
            }]);
            $state.go('administration.annualSeasonPlan.annualSeasonPlanList');
        }
    }
})();
