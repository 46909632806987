(function () {
    'use strict';

    angular
        .module('app.reports.anthropometryTestPrinting')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.anthropometryTestPrinting',
                config: {
                    url: '/anthropometryTestPrinting',
                    templateUrl: 'app/reports/anthropometryTestPrinting/anthropometryTestPrinting.html',
                    controller: 'AnthropometryTestPrintingController',
                    controllerAs: 'vm',
                    title: 'AnthropometryTestPrinting',
                    settings: {
                        navId: 2124,
                        level: 2,
                        order: 12,
                        orderTitle_hu: 'Antropometria tömeges nyomtatás',
                        orderTitle_en: 'Anthropometry Bulk Printing',
                        parentId: 4,
                        content: 'AnthropometryTestPrinting',
                        activatorPermission: ['"TomAntr"']
                    }
                }
            }
        ];
    }
})();
