(function () {
    'use strict';

    angular
        .module('app.search.athletes.simple')
        .controller('SimpleController', SimpleController);

    function SimpleController(log, dataservice, $q, $rootScope, gettextCatalog, VIEW_DATA, $filter, authenticationService, paramHelper, $state, $scope, $timeout) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('SearchAthletesSimple');

        // Create variables
        vm.athleteList = [];
        vm.sexList = [
            {
                text: gettextCatalog.getString('Man'),
                value: true
            },
            {
                text: gettextCatalog.getString('Woman'),
                value: false
            }
        ];
        vm.countyList = VIEW_DATA.dropDownList.counties;
        vm.courseList = VIEW_DATA.dropDownList.course;
        vm.classList = VIEW_DATA.dropDownList.class;
        vm.betterHandList = [
            {
                text: gettextCatalog.getString('Left'),
                value: 1
            },
            {
                text: gettextCatalog.getString('Right'),
                value: 2
            },
            {
                text: gettextCatalog.getString('Both'),
                value: 3
            }
        ];
        vm.betterFootList = [
            {
                text: gettextCatalog.getString('Left'),
                value: 1
            },
            {
                text: gettextCatalog.getString('Right'),
                value: 2
            },
            {
                text: gettextCatalog.getString('Both'),
                value: 3
            }
        ];
        vm.columns = [
            {
                field: 'Nev',
                headerText: gettextCatalog.getString('Name'),
                textAlign: 'center',
                width: '150',
                template: '#simplecolumnTemplate1'
            },
            {
                field: 'SzakosztalyNeve',
                headerText: gettextCatalog.getString('Section'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                textAlign: 'center',
                width: '150',
                template: '#simplecolumnTemplate2'
            },
            {
                field: 'SzuletesiIdo',
                headerText: gettextCatalog.getString('BirthDate'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'KorcsoportNeve',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                width: '150'
            },
            {field: 'Megjegyzes', headerText: gettextCatalog.getString('Comment'), textAlign: 'center', width: '150'},
            {
                field: 'Tagdijas',
                headerText: gettextCatalog.getString('SportServiceFeeAthlete'),
                textAlign: 'center',
                width: '200',
                type: 'boolean',
                displayAsCheckBox: true
            },
            {field: 'Tagdij', headerText: gettextCatalog.getString('SportServiceFee'), textAlign: 'center', width: '200'},
            {
                field: 'Aktiv',
                headerText: gettextCatalog.getString('Active'),
                textAlign: 'center',
                width: '100',
                type: 'boolean',
                displayAsCheckBox: true
            },
            {
                field: 'SzuletesiHelyOrszag',
                headerText: gettextCatalog.getString('BirthCountry'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SzuletesiHelyVaros',
                headerText: gettextCatalog.getString('BirthCity'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'Allampolgarsag',
                headerText: gettextCatalog.getString('Citizenship'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'VersSportKezd',
                headerText: gettextCatalog.getString('CompetitiveSportStart'),
                textAlign: 'center',
                width: '175'
            },
            {field: 'LakcimMegye', headerText: gettextCatalog.getString('County'), textAlign: 'center', width: '150'},
            {field: 'LakcimIrSzam', headerText: gettextCatalog.getString('ZipCode'), textAlign: 'center', width: '150'},
            {field: 'LakcimVaros', headerText: gettextCatalog.getString('City'), textAlign: 'center', width: '150'},
            {field: 'LakcimUtca', headerText: gettextCatalog.getString('Street'), textAlign: 'center', width: '150'},
            {
                field: 'LakcimHazszam',
                headerText: gettextCatalog.getString('StreetNumber'),
                textAlign: 'center',
                width: '125'
            },
            {field: 'Elerhetoseg', headerText: gettextCatalog.getString('Contact'), textAlign: 'center', width: '150'},
            {field: 'IskolaNeve', headerText: gettextCatalog.getString('SchoolName'), textAlign: 'center', width: '150'},
            {
                field: 'IskolaIrSzam',
                headerText: gettextCatalog.getString('SchoolZipCode'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'IskolaVaros',
                headerText: gettextCatalog.getString('SchoolCity'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'IskolaUtca',
                headerText: gettextCatalog.getString('SchoolStreet'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'IskolaHazszam',
                headerText: gettextCatalog.getString('SchoolStreetNumber'),
                textAlign: 'center',
                width: '125'
            },
            {
                field: 'IskolaIgazgatoNeve',
                headerText: gettextCatalog.getString('Director'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'TestneveloNeve',
                headerText: gettextCatalog.getString('GymTeacher'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'TestneveloElerhetosege',
                headerText: gettextCatalog.getString('GymTeacherContact'),
                textAlign: 'center',
                width: '175'
            },
            {
                field: 'TestneveloEmail',
                headerText: gettextCatalog.getString('GymTeacherEmail'),
                textAlign: 'center',
                width: '175'
            },
            {
                field: 'IskolaEvfolyam',
                headerText: gettextCatalog.getString('SchoolCourse'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'IskolaOsztaly',
                headerText: gettextCatalog.getString('SchoolClass'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'OsztalyFonokNeve',
                headerText: gettextCatalog.getString('ClassMaster'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'OfEmail',
                headerText: gettextCatalog.getString('ClassMasterEmail'),
                textAlign: 'center',
                width: '175'
            },
            {
                field: 'OfTel',
                headerText: gettextCatalog.getString('ClassMasterTel'),
                textAlign: 'center',
                width: '200'
            },
            {
                field: 'AnyjaNeve',
                headerText: gettextCatalog.getString('MothersName'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'Szulo1Elerhetosege',
                headerText: gettextCatalog.getString('MotherContact'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'AnyaEmail',
                headerText: gettextCatalog.getString('MotherEmail'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'AnyaMunkahely',
                headerText: gettextCatalog.getString('MothersWorkplace'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'AnyjaTestmagassaga',
                headerText: gettextCatalog.getString('MotherHeight'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SportoloiMultAnya',
                headerText: gettextCatalog.getString('MotherSportHistory'),
                textAlign: 'center',
                width: '175'
            },
            {field: 'SzuloNeve', headerText: gettextCatalog.getString('FathersName'), textAlign: 'center', width: '150'},
            {
                field: 'Szulo2Elerhetosege',
                headerText: gettextCatalog.getString('FatherContact'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'ApaEmail',
                headerText: gettextCatalog.getString('FatherEmail'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'ApaMunkahely',
                headerText: gettextCatalog.getString('FathersWorkplace'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'ApjaTestmagassaga',
                headerText: gettextCatalog.getString('FatherHeight'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SportoloiMultApa',
                headerText: gettextCatalog.getString('FatherSportHistory'),
                textAlign: 'center',
                width: '175'
            },
            {field: 'Testmagassag', headerText: gettextCatalog.getString('Height'), textAlign: 'center', width: '100'},
            {field: 'Testsuly', headerText: gettextCatalog.getString('Weight'), textAlign: 'center', width: '100'},
            {
                field: 'UgyesebbKez',
                headerText: gettextCatalog.getString('BetterHand'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'UgyesebbLab',
                headerText: gettextCatalog.getString('BetterFoot'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SzemIgSzam',
                headerText: gettextCatalog.getString('IdentityNumber'),
                textAlign: 'center',
                width: '200'
            },
            {
                field: 'TAJSzam',
                headerText: gettextCatalog.getString('SocialSecurityNumber'),
                textAlign: 'center',
                width: '150'
            },
            {field: 'AdoSzam', headerText: gettextCatalog.getString('TaxNumber'), textAlign: 'center', width: '150'},
            {
                field: 'UtlevelSzam',
                headerText: gettextCatalog.getString('PassportNumber'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'PapucsMerete',
                headerText: gettextCatalog.getString('SlipperSize'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'MelegitoMerete',
                headerText: gettextCatalog.getString('JoggingSuitSize'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'FurdokopenyMerete',
                headerText: gettextCatalog.getString('BathrobeSize'),
                textAlign: 'center',
                width: '160'
            },
            {field: 'KabatMerete', headerText: gettextCatalog.getString('CoatSize'), textAlign: 'center', width: '150'},
            {
                field: 'PancelNadragMerete',
                headerText: gettextCatalog.getString('ArmorPantSize'),
                textAlign: 'center',
                width: '175'
            },
            {
                field: 'PoloMerete',
                headerText: gettextCatalog.getString('TShirtSize'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'SortMerete',
                headerText: gettextCatalog.getString('ShortDressSize'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'CipoMerete',
                headerText: gettextCatalog.getString('ShoeSize'),
                textAlign: 'center',
                width: '150'
            },
            {
                field: 'UszoNadragMerete',
                headerText: gettextCatalog.getString('SwimmingPantSize'),
                textAlign: 'center',
                width: '150'
            }
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            editSettings: {allowEditing: false, allowAdding: false, allowDeleting: false},
            allowTextWrap: true,
            allowSorting: true,
            allowScrolling: true,
            allowPaging: true,
            allowExcelExport: true,
            pageSettings: $rootScope.pageSettings,
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.search = search;
        vm.reset = reset;
        vm.clearSection = clearSection;
        vm.clearCoach = clearCoach;
        vm.clearAgeGroup = clearAgeGroup;
        vm.clearCounty = clearCounty;
        vm.clearCourse = clearCourse;
        vm.clearClass = clearClass;
        vm.cleaSex = cleaSex;
        vm.goToAthlete = goToAthlete;
        vm.goToUser = goToUser;

        vm.formData = {
            active: true,
            inactive: false,
            sportServiceFee: true,
            educationalFee: true
        };

        // Activate page
        activate();

        function activate() {
            var promises = [
                authenticationService.getRight('SPORTOLOKERESO'),
                paramHelper.getParams([], ['search.simple.formdata'])
            ];
            $q.all(promises)
                .then(function (results) {
                    if (results[0] !== true) {
                        log.permissionError(true);
                    } else {
                        if (results[1] && results[1]['search.simple.formdata']) {
                            $timeout(function () {
                                vm.formData = results[1]['search.simple.formdata'];
                                $scope.$apply();
                            }, 500);
                            $timeout(function () {
                                getSections();
                                getAgeGroups();
                                getCoaches();
                                $timeout(function () {
                                    search();
                                }, 500);
                            }, 500);
                        }
                    }
                });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionId').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.formData.sectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionId').ejDropDownList('selectItemByValue', vm.formData.sectionId);
            }
        }

        function sectionSelect(args) {
            vm.formData.sectionId = args.value;
            getAgeGroups(args.value);
        }

        function getAgeGroups(sectionId) {
            if (!sectionId) {
                sectionId = 0;
            }
            return dataservice.ageGroupDropDownListForSearch(sectionId)
                .then(function (data) {
                    vm.ageGroupList = data.itemsList;
                });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupId').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
                vm.formData.ageGroupId = vm.ageGroupList[0].value;
            } else {
                angular.element('#ageGroupId').ejDropDownList('selectItemByValue', vm.formData.ageGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.formData.ageGroupId = args.value;
            getCoaches();
        }

        function getCoaches() {
            return dataservice.coachSelect()
                .then(function (data) {
                    vm.coachList = data.itemsList;
                });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachId').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.formData.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachId').ejDropDownList('selectItemByValue', vm.formData.coachId);
            }
        }

        function coachSelect(args) {
            vm.formData.coachId = args.value;
        }

        function search() {
            console.log('vm.formData SEARCH', vm.formData);
            paramHelper.setParams([{'search.simple.formdata': vm.formData}]);
            dataservice.searchAthlete(vm.formData).then(function (data) {
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'SzuletesiIdo');
                data.itemsList = $filter('dateStringFilter')(data.itemsList, 'VersSportKezd');
                vm.athleteList = data.itemsList;
                grid.dataSource = vm.athleteList;
            });
        }

        function reset() {
            vm.formData = {};
        }

        function cleaSex() {
            vm.formData.sex = undefined;
            $('#sex').ejDropDownList('clearText');
        }

        function clearSection() {
            vm.formData.sectionId = undefined;
            $('#sectionId').ejDropDownList('clearText');
            getAgeGroups(0);
            getAgeGroups(0);
        }

        function clearAgeGroup() {
            vm.formData.ageGroupId = undefined;
            $('#ageGroupId').ejDropDownList('clearText');
        }

        function clearCoach() {
            vm.formData.coachId = undefined;
            $('#coachId').ejDropDownList('clearText');
        }

        function clearCounty() {
            vm.formData.county = undefined;
            $('#county').ejDropDownList('clearText');
        }

        function clearCourse() {
            vm.formData.course = undefined;
            $('#course').ejDropDownList('clearText');
        }

        function clearClass() {
            vm.formData.class = undefined;
            $('#class').ejDropDownList('clearText');
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function goToUser(edzoId) {
            authenticationService.getRight('MEGTFELH').then(function (result) {
                if (result !== true) {
                    log.permissionError();
                    return;
                }
                paramHelper.setParams([{'newUser.userId': edzoId}]);
                $state.go('settings.userManagement.modifyUser');
            });
        }
    }
})();
