(function () {
    'use strict';

    angular
        .module('app.reports.statisticalReports.activeAthletesByMonth')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'reports.statisticalReports.activeAthletesByMonth',
                config: {
                    url: '/activeAthletesByMonth',
                    templateUrl: 'app/reports/statisticalReports/activeAthletesByMonth/activeAthletesByMonth.html',
                    controller: 'ActiveAthletesByMonthController',
                    controllerAs: 'vm',
                    title: 'ActiveAthletesByMonth',
                    settings: {
                        navId: 38214,
                        level: 3,
                        order: 8,
                        orderTitle_hu: 'Sportolók számának alakulása',
                        orderTitle_en: 'Number of Active Athletes by Month',
                        parentId: 214,
                        content: 'ActiveAthletesByMonth',
                        activatorPermission: ['"KMSPORTALAK"']
                    }
                }
            }
        ];
    }
})();
