(function () {
    'use strict';

    angular
        .module('app.reports.sportSpecificReport')
        .controller('SportSpecificReportController', SportSpecificReportController);

    function SportSpecificReportController(log, dataservice, $rootScope, gettextCatalog, authenticationService, $scope, $filter, $state, paramHelper, $q) {
        var vm = this;
        $rootScope.title = gettextCatalog.getString('SportSpecificReport');
        vm.dateFromValue = new Date(new Date().getFullYear(), 0, 1);
        vm.dateToValue = new Date(new Date().getFullYear(), 11, 31);
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.tipusList = [];
        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.tipusId = 0;
        vm.querycellinfo = querycellinfo;
        vm.sectionDataBound = sectionDataBound;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.tipusDataBound = tipusDataBound;
        vm.sectionSelect = sectionSelect;
        vm.ageGroupSelect = ageGroupSelect;
        vm.tipusSelect = tipusSelect;
        vm.getData = getData;
        vm.dataList = [];
        vm.dataFelmeroElem = [];
        vm.testdata = [];
        vm.test = [];
        vm.columns = [
            {field: 'SportoloID', headerText: 'Id', textAlign: 'center', isPrimaryKey: true, visible: false},
            {field: 'SportoloNeve', headerText: gettextCatalog.getString('AthleteName'), textAlign: 'center', width: 150},
            {field: 'datum', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125}
        ];

        var Grid2 = new ej2.grids.Grid({
            dataSource: vm.test,
            columns: vm.columns,
            toolbar: $rootScope.toolbarItems,
            allowPaging: false,
            allowSorting: false,
            allowTextWrap: true,
            allowExcelExport: false,
            locale: 'hu-HU',
            gridLines: 'Both',
            queryCellInfo: function (arg) {
                var cellIndex = -1;
                if (arg.column.field) {
                    cellIndex = parseInt(arg.column.field.replace('c', ''));
                    if (!(cellIndex >= 0)) {
                        return;
                    }
                }
                var cell = angular.element(arg.cell);
                if (vm.testdata && vm.testdata.length > 0 && cellIndex >= 0) {
                    var rowIndex = getRowIndex(arg.data.SportoloID, arg.data.datum);
                    if (vm.testdata[rowIndex].Colors[cellIndex] && vm.testdata[rowIndex].Colors[cellIndex].length > 0) {
                        cell.css('background-color', vm.testdata[rowIndex].Colors[cellIndex]);
                    }
                }
            }
        });

        Grid2.appendTo('#Grid2');

        activate();

        function activate() {
            authenticationService.getRight('MEGTSPFELM').then(function (result) {
                if (result !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([
                        getSections(),
                        authenticationService.getRight('MEGTSPFELM'),
                        paramHelper.getParams([], ['sportSpecificReport.SportoloID'])
                    ]).then(function (results) {
                        console.log('results', results);
                    }).catch(function () {
                        log.unknownError();
                    });
                }
            }).catch(function () {
                log.unknownError();
            });
        }

        function resetColumns() {
            vm.columns = [
                {field: 'SportoloID', headerText: 'Id', textAlign: 'center', isPrimaryKey: true, visible: false},
                {field: 'SportoloNeve', headerText: gettextCatalog.getString('Athlete'), textAlign: 'center', width: 125},
                {field: 'datum', headerText: gettextCatalog.getString('Date'), textAlign: 'center', width: 125}
            ];
        }

        function getData() {
            if (!(vm.tipusId > 0)) {
                return;
            }
            vm.testdata = [];
            vm.test = [];
            vm.dataFelmeroElem = [];
            dataservice.getFelmeroElemList(vm.tipusId).then(function (elemResponse) {
                vm.dataFelmeroElem = elemResponse.itemsList;
                console.log('getFelmeroElemList() elemResponse', elemResponse);
                resetColumns();
                dataservice.getSportagSpecReport(vm.tipusId, vm.ageGroupId, vm.sectionId, vm.dateFromValue, vm.dateToValue).then(function(data) {
                    vm.testdata = data.itemsList;
                    console.log('getSportagSpecReport() data', data);
                    vm.test = [];
                    for (var i = 0; i < vm.testdata.length; i++) {
                        var line = {};
                        line.SportoloID = vm.testdata[i].SportoloID;
                        line.SportoloNeve = vm.testdata[i].SportoloNeve;
                        line.datum = vm.testdata[i].datum;
                        for (var c = 0; c < vm.dataFelmeroElem.length; c++) {
                            var fieldName = 'c' + c;
                            line[fieldName] = '';
                        }
                        vm.test.push(line);
                    }
                    var strArr = [];
                    resetColumns();
                    for (var i = 0; i < vm.dataFelmeroElem.length; i++) {
                        var fieldName = vm.dataFelmeroElem[i].fieldName;
                        strArr.push(fieldName);
                        vm.columns.push({field: vm.dataFelmeroElem[i].fieldName, headerText: vm.dataFelmeroElem[i].Nev, textAlign: 'center', width: 150});
                        for (var j = 0; j < vm.test.length; j++) {
                            vm.test[j][strArr[i]] = vm.testdata[j].Adatok1D[i];
                        }
                    }
                    Grid2.dataSource = vm.test;
                    Grid2.columns = vm.columns;
                    Grid2.refreshColumns();
                    Grid2.refresh();
                });
            });
        }

        function querycellinfo(arg) {
            var $element = angular.element(arg.cell);
            if (vm.testdata && vm.testdata.length > 0 && arg.cell.cellIndex >= 3) {
                var rowIndex = getRowIndex(arg.data.SportoloID, arg.data.datum);
                if (vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 3] && vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 3].length > 0) {
                    $element.css('background-color', vm.testdata[rowIndex].Colors[arg.cell.cellIndex - 3]);
                }
            }
        }

        function getRowIndex(SportoloID, datum) {
            //console.log('getRowIndex('+SportoloID+','+datum+') vm.testdata' , vm.testdata);
            for (var i = 0; i < vm.testdata.length; i++) {
                if (vm.testdata[i].SportoloID == SportoloID && vm.testdata[i].datum == datum) {
                    return i;
                }
            }
            return -1;
        }

        function getSections() {
            return dataservice.sectionDropDownList().then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        function getAgeGroups() {
            return dataservice.ageGroupDropDownList(vm.sectionId).then(function (data) {
                vm.ageGroupList = data.itemsList;
                vm.ageGroupList.unshift({text: gettextCatalog.getString('All'), value: 0});
            });
        }

        function getTipusList() {
            return dataservice.felmeroElemFejDropDownList(vm.sectionId).then(function(data) {
                vm.tipusList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                vm.sectionId = vm.sectionList[0].value;
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function tipusDataBound() {
            if (vm.tipusList.length === 1) {
                angular.element('#tipusDropDown').ejDropDownList('selectItemByValue', vm.tipusList[0].value);
            } else {
                angular.element('#tipusDropDown').ejDropDownList('selectItemByValue', vm.tipusId);
            }
        }

        function sectionSelect(args) {
            vm.sectionId = args.value;
            vm.tipusId = -1;
            getAgeGroups();
            getTipusList();
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
            getData();
        }

        function tipusSelect(args) {
            vm.tipusId = args.value;
            getData();
        }
    }
})();
