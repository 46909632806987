(function () {
    'use strict';

    angular
        .module('app.other.files')
        .controller('FilesController', FilesController);

    function FilesController(log, dataservice, $q, $rootScope, gettextCatalog, $scope, paramHelper, DOMAIN) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = $rootScope.title + gettextCatalog.getString('Contracts');
        vm.title = gettextCatalog.getString('Contracts');

        //grid adatai
        vm.data = [];
        vm.columns = [{
                field: 'Id',
                visible: false,
                isPrimaryKey: true,
                isIdentity: true
            },
            {
                field: 'Filenev',
                headerText: gettextCatalog.getString('Filename'),
                textAlign: 'center',
                allowEditing: false,
                template: '#fitemplateDataColumn1',
                width: 150
            },
            {
                field: 'Datum',
                headerText: gettextCatalog.getString('Date'),
                textAlign: 'center',
                allowEditing: false,
                width: 125
            }
        ];

        vm.selectedRow = -1;
        vm.deleteFile = deleteFile;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            allowDeleting: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            selectedRowIndex: vm.selectedRow,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile(angular.element('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            angular.element('#UploadDefault').ejUploadbox({
                saveUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileUpload',
                removeUrl: DOMAIN.baseURL + DOMAIN.webService + 'FileRemove',
                uploadName: 'Uploadbox',
                allowDragAndDrop: false,
                autoUpload: true,
                buttonText: {browse: gettextCatalog.getString('ChooseFile'), upload: gettextCatalog.getString('UploadFile'), cancel: gettextCatalog.getString('CancelUpload')},
                dialogAction: {modal: true, closeOnComplete: true, resize: false, drag: false},
                dialogText: {title: gettextCatalog.getString('UploadedFilesTitle'), name: gettextCatalog.getString('FileName'), size: gettextCatalog.getString('FileSize'), status: gettextCatalog.getString('FileStatus')},
                dropAreaText: gettextCatalog.getString('DropFileToUpload'),
                extensionsAllow: '.doc,.docx,.pdf,.xls,.mp4,.avi',
                complete: function () {
                    log.successMsg(gettextCatalog.getString('UploadSuccessed'));
                    getFiles();
                },
                error: function (args) {
                    var err = '';
                    if (args.error === 'Only files with .doc, .docx, .pdf, .xls, .mp4, .avi extensions are allowed.') {
                        err = gettextCatalog.getString('OnlyDocPdfXls');
                    } else if (args.error === '500 Internal Server Error') {
                        err = gettextCatalog.getString('ToLongFile');
                    }
                    log.htmlError(gettextCatalog.getString('UploadError') + '<br>' + err);
                },
                begin: function (args) {
                    args.data = {tipus: vm.tipus, tablaId: vm.tablaId};
                }
            });

            var promises = [paramHelper.getParams([], ['files.tipus', 'files.tablaId', 'weeklyTrainingPlan.edzoId', 'weeklyTrainingPlan.szakosztalyId', 'weeklyTrainingPlan.korosztalyId', 'weeklyTrainingPlan.ev', 'weeklyTrainingPlan.trainingPlanId', 'weeklyTrainingPlan.edzoNev', 'weeklyTrainingPlan.szakosztalyNev', 'weeklyTrainingPlan.korosztalyNev'])];

            return $q.all(promises).then(function (results) {
                if (angular.isDefined(results[0]['files.tipus'])) {
                    vm.tipus = results[0]['files.tipus'];
                }
                if (angular.isDefined(results[0]['files.tablaId'])) {
                    vm.tablaId = results[0]['files.tablaId'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.edzoId'])) {
                    vm.selectedCoach = results[0]['weeklyTrainingPlan.edzoId'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.edzoNev'])) {
                    vm.coachName = results[0]['weeklyTrainingPlan.edzoNev'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.szakosztalyId'])) {
                    vm.selectedSection = results[0]['weeklyTrainingPlan.szakosztalyId'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.szakosztalyNev'])) {
                    vm.sectionName = results[0]['weeklyTrainingPlan.szakosztalyNev'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.korosztalyId'])) {
                    vm.selectedAgeGroup = results[0]['weeklyTrainingPlan.korosztalyId'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.korosztalyNev'])) {
                    vm.ageGroupName = results[0]['weeklyTrainingPlan.korosztalyNev'];
                }
                if (angular.isDefined(results[0]['weeklyTrainingPlan.ev'])) {
                    vm.selectedYear = results[0]['weeklyTrainingPlan.ev'];
                }
                getFiles();
            });
        }

        function getFiles() {
            dataservice.getFilesList(vm.tipus, vm.tablaId).then(
                function (data) {
                    vm.data = data.itemsList;
                    grid.dataSource = vm.data;
                });
        }

        function deleteFile() {
            if (grid.getSelectedRowIndexes().size === 0) {
                log.errorMsg('SelectFile');
                return;
            }

            swal({title: gettextCatalog.getString('Are you sure you want to delete the selected file?'),
                text: gettextCatalog.getString('You will not be able to recover it!'),
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                confirmButtonText: gettextCatalog.getString('Yes, delete this file'),
                cancelButtonText: gettextCatalog.getString('Cancel'),
                closeOnConfirm: false,
                closeOnCancel: false
            }).then(function(isConfirm) {
                if (isConfirm.value) {
                    return dataservice.deleteFiles(vm.data[grid.getSelectedRowIndexes()[0]].Id).then(
                            function() {
                                log.successMsg(gettextCatalog.getString('FileDeleted'));
                                getFiles();
                            }
                        );
                }
            });
        }
    }
})();
