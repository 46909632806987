(function () {
    'use strict';

    angular
        .module('app.administration.exercises')
        .run(appRun);

    function appRun(routerHelper) {
        routerHelper.configureStates(getStates());
    }

    function getStates() {
        return [
            {
                state: 'administration.exercises',
                config: {
                    url: '/exercises',
                    templateUrl: 'app/administration/exercises/exercises.html',
                    settings: {
                        activatorPermission: ['"MODGYAKORLAT"']
                    }
                }
            }
        ];
    }
})();
