(function () {
    'use strict';

    angular
        .module('app.business.annualSportServiceFeeByOneSection')
        .controller('AnnualSportServiceFeeByOneSectionController', AnnualSportServiceFeeByOneSectionController);

    function AnnualSportServiceFeeByOneSectionController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, $scope, $state, paramHelper) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('AnnualSportServiceFeeByOneSection');

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'Elvart',
                    groupFooterTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0',
                    textAlign: 'center',
                    headerTextAlign: 'center',
                    width: 120
                },
                {
                    type: 'Sum',
                    field: 'Tenyleges',
                    groupFooterTemplate: gettextCatalog.getString('Sum') + ': ${Sum}',
                    format: 'N0',
                    textAlign: 'center',
                    headerTextAlign: 'center',
                    width: 120
                }
            ]
        }];
        vm.columns = [
            {field: 'Id', isPrimaryKey: true, visible: false},
            {
                field: 'EdzoNeve',
                headerText: gettextCatalog.getString('Coach'),
                width: 125,
                template: '#annualsportservicefeebyonesectioncolumnTemplate',
                textAlign: 'center',
                headerTextAlign: 'center',
            },
            {
                field: 'Elvart',
                headerText: gettextCatalog.getString('Elvart'),
                width: 125,
                groupFooterTemplate: 'Total freight: ${Sum}',
                format: 'N0',
                textAlign: 'center',
                headerTextAlign: 'center'
            },
            {
                field: 'Tenyleges',
                headerText: gettextCatalog.getString('Tenyleges'),
                width: 125,
                format: 'N0',
                textAlign: 'center',
                headerTextAlign: 'center',
            },
            {field: 'Year', headerText: gettextCatalog.getString('Year'), visible: false}
        ];

        var grid = new ej2.grids.Grid({
            dataSource: [],
            allowExcelExport: true,
            allowTextWrap: true,
            allowPaging: true,
            allowSorting: true,
            allowGrouping: true,
            toolbar: $rootScope.toolbarItems,
            groupSettings: {showDropArea: false, columns: ['Year']},
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            locale: 'hu-HU',
            aggregates: vm.summaryRows,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'searching' || args.requestType === 'refresh') {
                    $rootScope.compile(grid, $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        // Public functions
        vm.sectionSelect = sectionSelect;
        vm.openCoach = openCoach;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('SZAKOSPDIJBEF')];
            $q.all(promises).then(function (result) {
                if (result[0] !== true) {
                    log.permissionError(true);
                } else {
                    getSections();
                }
            });
        }

        // Get sections' list
        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                vm.sectionList = data.itemsList;
            });
        }

        // Run after section selected
        function sectionSelect(args) {
            vm.sectionId = args.value;
            getDatas(args.value);
        }

        function getDatas(sectionId) {
            dataservice.getAnnualSportServiceFeeByOneSection(sectionId).then(function (data) {
                console.log('data.itemsList: ' + data.itemsList.length);
                grid.dataSource = data.itemsList;
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }
    }
})();
