(function () {
    'use strict';

    angular
        .module('app.business.educationalFeeRegister')
        .controller('EducationalFeeRegisterController', EducationalFeeRegisterController);

    function EducationalFeeRegisterController(log, dataservice, $q, $rootScope, gettextCatalog, paramHelper, authenticationService, $scope, $state, $compile, $timeout, $filter) {
        var vm = this;
        $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('EducationalFeeRegister');

        // Create variables
        // Dropdownlist's sources
        vm.AthleteUnderEditing = '';
        vm.AthleteUnderEditingText = gettextCatalog.getString('ActualAthlete');
        vm.kedvezmenyNevek = [];
        vm.sectionList = [];
        vm.coachList = [];
        vm.yearList = dataservice.range(new Date().getFullYear() - 4, new Date().getFullYear() + 1);
        vm.rowUnderEditing = [];
        vm.priceType = [{text: 'NK', value: 'NK'}, {text: 'B', value: 'B'}, {text: 'EP', value: 'EP'}];
        vm.year = new Date().getFullYear();
        vm.modifiedRows = [];

        vm.maskObject;
        vm.elem;

        vm.dateTimeEditorTemplate =
            {
                create: function () {
                    var txt = document.createElement('input');
                    txt.style.width = '100%';
                    txt.style.float = 'left';
                    vm.elem = txt;
                    vm.elem.addEventListener('focus', function () {
                        this.select();
                    });
                    return vm.elem;
                },
                read: function () {
                    return vm.maskObject.getMaskedValue();
                },
                destroy: function () {
                    if (vm.maskObject != null) {
                        vm.maskObject.destroy();
                    }
                },
                write: function (args) {
                    var s = args.element.id.toString().replace('Grid', '');
                    vm.maskObject = new ej2.inputs.MaskedTextBox({
                        minWidth: '100%',
                        mask: '>0000-00-00',
                        value: args.rowData !== angular.isUndefined ? args.rowData[s] : ''.replace(' ', ''),
                        readOnly: vm.readOnlyAttendance,
                    });
                    vm.editedRowId = args.rowData['Id'];
                    $compile(args.element[0])($scope);
                    vm.maskObject.appendTo(vm.elem);
                }
            };

        vm.dropDownListEditorTemplate =
            {
                create: function () {
                    vm.elem = document.createElement('input');
                    return vm.elem;
                },
                read: function () {
                    var text = '';
                    switch (vm.maskObject.value) {
                        case 'NK':
                            text = 'NK';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'NK';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.rowUnderEditing['KirottTeljesAr' + vm.monthUnderEditing];
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.rowUnderEditing['KirottTeljesAr' + vm.monthUnderEditing];
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = false;
                            break;
                        case 'K1':
                            text = vm.priceType.find(function (value) {
                                return value.value === 'K1';
                            }).text;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'K1';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.sectionFees[0].KedvezmenyesOD;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.sectionFees[0].KedvezmenyesOD;
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = true;
                            vm.rowUnderEditing['KedvezmenyTipus' + vm.monthUnderEditing] = 'K1';
                            break;
                        case 'K2':
                            text = vm.priceType.find(function (value) {
                                return value.value === 'K2';
                            }).text;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'K2';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.sectionFees[0].KedvezmenyesOD2;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.sectionFees[0].KedvezmenyesOD2;
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = true;
                            vm.rowUnderEditing['KedvezmenyTipus' + vm.monthUnderEditing] = 'K2';
                            break;
                        case 'K3':
                            text = vm.priceType.find(function (value) {
                                return value.value === 'K3';
                            }).text;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'K3';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.sectionFees[0].KedvezmenyesOD3;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.sectionFees[0].KedvezmenyesOD3;
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = true;
                            vm.rowUnderEditing['KedvezmenyTipus' + vm.monthUnderEditing] = 'K3';
                            break;
                        case 'K4':
                            text = vm.priceType.find(function (value) {
                                return value.value === 'K4';
                            }).text;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'K4';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.sectionFees[0].KedvezmenyesOD4;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.sectionFees[0].KedvezmenyesOD4;
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = true;
                            vm.rowUnderEditing['KedvezmenyTipus' + vm.monthUnderEditing] = 'K4';
                            break;
                        case 'K5':
                            text = vm.priceType.find(function (value) {
                                return value.value === 'K5';
                            }).text;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'K5';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = vm.sectionFees[0].KedvezmenyesOD5;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = vm.sectionFees[0].KedvezmenyesOD5;
                            vm.rowUnderEditing['Kedvezmenyes' + vm.monthUnderEditing] = true;
                            vm.rowUnderEditing['KedvezmenyTipus' + vm.monthUnderEditing] = 'K5';
                            break;
                        case 'B':
                            text = 'B';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'B';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = 0;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = 0;
                            break;
                        case 'EP':
                            text = 'EP';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'EP';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = 0;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = 0;
                            break;
                        case 'ODM':
                            text = 'ODM';
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing] = 0;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_fizetendo'] = 0;
                            vm.rowUnderEditing['Honap_' + vm.monthUnderEditing + '_kedvezmenyes'] = 'ODM';
                            break;
                    }
                    // grid.refresh();
                    return text;
                },
                destroy: function () {
                    vm.maskObject.destroy();
                },
                write: function (args) {
                    vm.monthUnderEditing = args.column.field[6] + (args.column.field[7] && args.column.field[7] !== '_' ? args.column.field[7] : '');
                    new ej2.data.DataManager(vm.data).executeQuery(new ej2.data.Query().where('Id', 'equal', args.rowData.Id)).then(function (result) {
                        vm.rowUnderEditing = result.result[0];
                    });

                    var arr = vm.priceType.slice(0);
                    if (args.rowData.mentessegAdhato === true) {
                        arr.push({text: 'ODM', value: 'ODM'});
                    }
                    vm.maskObject = new ej2.dropdowns.DropDownList({
                        value: args.rowData[args.column.field.slice(0, -5)],
                        dataSource: arr,
                        popupWidth: 150,
                        fields: {
                            value: 'value',
                            text: 'text'
                        }
                    });
                    vm.maskObject.appendTo(vm.elem);
                }
            };

        vm.monthList =
            [{text: gettextCatalog.getString('All'), value: -1},
                {text: gettextCatalog.getString('January'), value: 0},
                {text: gettextCatalog.getString('February'), value: 1},
                {text: gettextCatalog.getString('March'), value: 2},
                {text: gettextCatalog.getString('April'), value: 3},
                {text: gettextCatalog.getString('May'), value: 4},
                {text: gettextCatalog.getString('June'), value: 5},
                {text: gettextCatalog.getString('July'), value: 6},
                {text: gettextCatalog.getString('August'), value: 7},
                {text: gettextCatalog.getString('September'), value: 8},
                {text: gettextCatalog.getString('October'), value: 9},
                {text: gettextCatalog.getString('November'), value: 10},
                {text: gettextCatalog.getString('December'), value: 11}];

        // Grid's variables
        vm.osszesFizetendo = function (args) {
            return ej.sum(args, 'osszesFizetendo');
        };
        vm.osszesFizetett = function (args) {
            return ej.sum(args, 'osszesFizetett');
        };

        vm.summaryRows = [{
            columns: [
                {
                    type: 'Sum',
                    field: 'Honap_1',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_2',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_3',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_4',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_5',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_6',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_7',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_8',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_9',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_10',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_11',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Sum',
                    field: 'Honap_12',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Sum}'
                },
                {
                    type: 'Custom',
                    customAggregate: vm.osszesFizetendo,
                    columnName: 'osszesFizetendo',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Custom}'
                },
                {
                    type: 'Custom',
                    customAggregate: vm.osszesFizetett,
                    columnName: 'osszesFizetett',
                    footerTemplate: gettextCatalog.getString('Sum') + ': ${Custom}'
                }
            ]
        }];

        vm.rowIndexUnderEditing = -1;
        vm.fizetveWidth = 80;
        vm.columns = [
            {
                headerText: gettextCatalog.getString('General'),
                columns: [
                    {field: 'Id', isPrimaryKey: true, visible: false},
                    {
                        allowEditing: false,
                        headerText: gettextCatalog.getString('Name'),
                        textAlign: 'center',
                        headerTextAlign: 'center',
                        template: '#educationalfeeregistercolumnTemplate1',
                        field: 'Versenyzo_Nev',
                        width: 150
                    },
                    {
                        field: 'SzuletesiIdo',
                        allowEditing: false,
                        headerText: gettextCatalog.getString('BirthDate'),
                        textAlign: 'center',
                        headerTextAlign: 'center',
                        width: 100
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('General'),
                columns: [
                    {
                        field: 'SzakosztalyNeve',
                        allowEditing: false,
                        headerText: gettextCatalog.getString('Section'),
                        textAlign: 'center',
                        headerTextAlign: 'center',
                        width: 115
                    },
                    {
                        allowEditing: false,
                        headerText: gettextCatalog.getString('CoachName'),
                        textAlign: 'center',
                        headerTextAlign: 'center',
                        template: '#educationalfeeregistercolumnTemplate2',
                        field: 'EdzoNeve',
                        width: 150
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('January'),
                columns: [
                    {
                        field: 'Honap_1_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay1'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                        /*
                        editType: 'dropdownedit',
                        edit: { params: { fields: {value: 'value', text: 'text'},
                                dataSource: vm.priceType,
                                query : new ej.data.Query()
                               }
                         */
                    },
                    {
                        field: 'Honap_1',
                        headerText: gettextCatalog.getString('Payed1'),
                        textAlign: 'center',
                        // editType: 'numericedit',
                        // edit: { params: { showSpinButton: false } },
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_1_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber1'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_1_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate1'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('February'),
                columns: [
                    {
                        field: 'Honap_2_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay2'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_2',
                        headerText: gettextCatalog.getString('Payed2'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_2_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber2'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_2_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate2'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('March'),
                columns: [
                    {
                        field: 'Honap_3_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay3'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_3',
                        headerText: gettextCatalog.getString('Payed3'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_3_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber3'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_3_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate3'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('April'),
                columns: [
                    {
                        field: 'Honap_4_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay4'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_4',
                        headerText: gettextCatalog.getString('Payed4'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_4_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber4'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_4_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate4'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('May'),
                columns: [
                    {
                        field: 'Honap_5_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay5'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_5',
                        headerText: gettextCatalog.getString('Payed5'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_5_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber5'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_5_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate5'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('June'),
                columns: [
                    {
                        field: 'Honap_6_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay6'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_6',
                        headerText: gettextCatalog.getString('Payed6'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_6_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber6'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_6_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate6'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('July'),
                columns: [
                    {
                        field: 'Honap_7_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay7'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_7',
                        headerText: gettextCatalog.getString('Payed7'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_7_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber7'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_7_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate7'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('August'),
                columns: [
                    {
                        field: 'Honap_8_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay8'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_8',
                        headerText: gettextCatalog.getString('Payed8'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_8_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber8'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_8_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate8'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('September'),
                columns: [
                    {
                        field: 'Honap_9_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay9'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_9',
                        headerText: gettextCatalog.getString('Payed9'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_9_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber9'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_9_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate9'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('October'),
                columns: [
                    {
                        field: 'Honap_10_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay10'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_10',
                        headerText: gettextCatalog.getString('Payed10'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_10_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber10'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_10_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate10'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('November'),
                columns: [
                    {
                        field: 'Honap_11_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay11'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_11',
                        headerText: gettextCatalog.getString('Payed11'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_11_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber11'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_11_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate11'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('December'),
                columns: [
                    {
                        field: 'Honap_12_kedvezmenyes_Text',
                        headerText: gettextCatalog.getString('NeedPay12'),
                        textAlign: 'center',
                        width: 90,
                        edit: vm.dropDownListEditorTemplate
                    },
                    {
                        field: 'Honap_12',
                        headerText: gettextCatalog.getString('Payed12'),
                        textAlign: 'center',
                        type: 'number',
                        width: vm.fizetveWidth
                    },
                    {
                        field: 'Honap_12_kozlemeny',
                        headerText: gettextCatalog.getString('ReceiptNumber12'),
                        textAlign: 'center',
                        width: 125
                    },
                    {
                        field: 'Honap_12_FizDatum',
                        headerText: gettextCatalog.getString('PaymentDate12'),
                        textAlign: 'center',
                        editType: ej.Grid.EditingType.Date,
                        allowEditing: true,
                        edit: vm.dateTimeEditorTemplate,
                        width: 125
                    }
                ],
                textAlign: 'center'
            },
            {
                headerText: gettextCatalog.getString('Summaries'),
                columns: [
                    {
                        field: 'osszesFizetendo',
                        headerText: gettextCatalog.getString('SumNeedPay'),
                        textAlign: 'center',
                        allowEditing: false,
                        width: 125
                    },
                    {
                        field: 'osszesFizetett',
                        headerText: gettextCatalog.getString('SumPayed'),
                        textAlign: 'center',
                        allowEditing: false,
                        width: 125
                    }
                ],
                textAlign: 'center'
            }
        ];

        function dataBound() {
            setTimeout(function () {
                var grid = document.getElementById('Grid').ej2_instances[0];
                var el = grid.element.querySelector('.e-frozencontent');
                if (el !== null) {
                    el.style.height = grid.element.querySelector('.e-movablecontent').getBoundingClientRect().height + 'px';
                }
            }, 100);
        }

        var grid = new ej2.grids.Grid({
            dataSource: [],
            // allowTextWrap: true,
            toolbar: $rootScope.toolbarItemsWithSearch,
            allowPaging: true,
            // frozenColumns: 3,
            pageSettings: $rootScope.pageSettings,
            editSettings: {allowEditing: true, mode: 'Batch', showConfirmDialog: false, showDeleteConfirmDialog: false},
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            columns: vm.columns,
            aggregates: vm.summaryRows,
            // dataBound: dataBound,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'searching' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            },
            queryCellInfo: querycellinfo,
            cellEdit: cellEdit,
            cellSave: cellSave
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        var fut = false;
        $('#monthDropDownList').ejDropDownList({
            dataSource: vm.monthList,
            fields: {text: 'text'},
            showCheckbox: true,
            width: '100%',
            checkChange: function (args) {
                var DropDownListObj = $('#monthDropDownList').data('ejDropDownList');
                if (fut) {
                    return;
                }
                fut = true;
                if (args.selectedValue < 0) {
                    $('#monthDropDownList').ejDropDownList('unselectItemsByIndices', '1,2,3,4,5,6,7,8,9,10,11,12,13');
                    $('#monthDropDownList').ejDropDownList('selectItemByValue', -1);
                } else {
                    $('#monthDropDownList').ejDropDownList('unselectItemsByIndices', 0);
                    $('#monthDropDownList').ejDropDownList((args.isChecked ? 'selectItemByValue' : 'unselectItemByValue'), args.selectedValue);
                }
                fut = false;
            },
            create: function () {
                $('#monthDropDownList').ejDropDownList('selectItemByValue', -1);
            },
            multiSelectMode: ej.MultiSelectMode.Delimiter,
        });

        // Public functions
        vm.sectionDataBound = sectionDataBound;
        vm.sectionSelect = sectionSelect;
        vm.coachDataBound = coachDataBound;
        vm.coachSelect = coachSelect;
        vm.getAthletes = getAthletesClick;
        vm.yearDataBound = yearDataBound;
        vm.monthDataBound = monthDataBound;
        vm.yearSelect = yearSelect;
        vm.saveWithNumbers = saveWithNumbers;
        vm.saveWithoutNumbers = saveWithoutNumbers;
        vm.goToDailyPayments = goToDailyPayments;
        vm.goToAthlete = goToAthlete;
        vm.openCoach = openCoach;
        vm.getReducedPriceTypes = getReducedPriceTypes;

        // Activate page
        activate();

        function activate() {
            var promises = [authenticationService.getRight('MEGTTAGDNYILV'), authenticationService.getRight('MEGTOKTDNYILV'), paramHelper.getParams([], ['educationalFeeRegister.sectionId', 'educationalFeeRegister.year', 'educationalFeeRegister.coachId', 'educationalFeeRegister.ageGroupId']), authenticationService.getRight('UJOKTDNYILV'), authenticationService.getRight('UJOKTDNYILV')];
            $q.all(promises).then(function (results) {
                if (results[0] !== true || results[1] !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([getcoaches(), getSections(), getReducedPriceTypes()]).then(function () {
                        if (results[2]['educationalFeeRegister.sectionId'] && results[2]['educationalFeeRegister.coachId'] && results[2]['educationalFeeRegister.year']) {
                            $timeout(function () {
                                if (results[2]['educationalFeeRegister.ageGroupId']) {
                                    var DropDownListObj = $('#monthDropDownList').data('ejDropDownList');
                                    DropDownListObj.selectItemByValue(JSON.parse(results[2]['educationalFeeRegister.ageGroupId']));
                                }
                                showHideColumns();
                                getAthletes(results[2]['educationalFeeRegister.sectionId'], results[2]['educationalFeeRegister.coachId'], results[2]['educationalFeeRegister.year']);
                            }, 0);
                        }
                    });

                    vm.UJOKTDNYILV = results[3];
                    vm.UJOKTDNYILV = results[4];
                }
            });
        }

        function getReducedPriceTypes() {
            return dataservice.getReducedEducationalFeeTypes(authenticationService.getLanguage()).then(function (data) {
                vm.priceType = vm.priceType.concat(data.itemsList);
                for (var i = 0; i < data.itemsList.length; i++) {
                    vm.kedvezmenyNevek.push(data.itemsList[i].text);
                }
            });
        }

        function getFeeDatas() {
            return dataservice.getFeeDatas(vm.sectionId).then(function (data) {
                vm.sectionFees = data.itemsList;
            });
        }

        function getcoaches() {
            return dataservice.coachSelect(vm.sectionId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
                vm.coachId = vm.coachList[0].value;
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
            }
        }

        function coachSelect(args) {
            vm.coachId = args.value;
            getSections();
        }

        //szakosztályok lekérése
        function getSections() {
            return dataservice.sectionDropDownListWithFees(vm.coachId).then(function (data) {
                data.itemsList.unshift({value: '0', text: gettextCatalog.getString('All')});
                vm.sectionList = data.itemsList;
            });
        }

        // Run after data bounded
        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
                vm.sectionId = vm.sectionList[0].value;
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        // Run after selection
        function sectionSelect(args) {
            vm.EducationalFee = vm.sectionList[args.itemId].OktatasiDij !== null ? vm.sectionList[args.itemId].OktatasiDij : 0;
            vm.ReducedEducationalFee = vm.sectionList[args.itemId].KedvezmenyesOktatasiDij !== null ? vm.sectionList[args.itemId].KedvezmenyesOktatasiDij : 0;
            vm.sectionId = args.value;
        }

        function yearDataBound() {
            if (vm.yearList.length === 1) {
                angular.element('#yearDropDownList').ejDropDownList('selectItemByValue', vm.yearList[0].value);
                vm.year = vm.yearList[0].value;
            }
        }

        function monthDataBound() {
            if (vm.monthList.length === 1) {
                angular.element('#monthDropDownList').ejDropDownList('selectItemByValue', vm.monthList[0].value);
                vm.year = vm.yearList[0].value;
            }
        }

        function yearSelect(args) {
            vm.year = args.value;
        }

        function getAthletesClick() {
            showHideColumns();
            getAthletes();
        }

        function showHideColumns() {
            $timeout(function () {
                var DropDownListObj = $('#monthDropDownList').data('ejDropDownList');
                var vmi = DropDownListObj.getSelectedValue();
                if (vmi.includes(-1)) {
                    grid.frozenColumns = 3;
                } else {
                    grid.frozenColumns = null;
                }
                for (var m = 2; m < 14; m++) {
                    if (vmi.includes(m - 2) || vmi.includes(-1)) {
                        for (var j = 0; j < 4; j++) {
                            vm.columns[m].columns[j].visible = true;
                        }
                    } else {
                        for (var j = 0; j < 4; j++) {
                            vm.columns[m].columns[j].visible = false;
                        }
                    }
                }
                grid.refreshColumns();
                $scope.$apply();
            }, 0);
        }

        function getAthletes(sectionId, coachId, year) {
            if (sectionId !== undefined) {
                vm.sectionId = sectionId;
                vm.coachId = coachId;
                vm.year = year;
            } else {
                // a syncfusion direktívák miatt
                $scope.$broadcast('submitted');
                if ($scope.form.$valid === false) {
                    log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                    return;
                }
            }
            getFeeDatas();
            paramHelper.setParams([{'educationalFeeRegister.sectionId': vm.sectionId}, {'educationalFeeRegister.year': vm.year}, {'educationalFeeRegister.coachId': vm.coachId}, {'educationalFeeRegister.ageGroupId': JSON.stringify($('#monthDropDownList').data('ejDropDownList').getSelectedValue())}]);
            var DropDownListObj = $('#monthDropDownList').data('ejDropDownList');
            var months = DropDownListObj.getSelectedValue();
            dataservice.getAthletesForEducationalFeeRegister(vm.sectionId, vm.coachId, vm.year, months).then(function (data) {
                $filter('dateStringFilterPromise')(data.itemsList, 'SzuletesiIdo').then(function (result) {
                    $filter('dateStringFilterPromise')(result, 'Honap_1_FizDatum').then(function (result1) {
                        $filter('dateStringFilterPromise')(result1, 'Honap_2_FizDatum').then(function (result2) {
                            $filter('dateStringFilterPromise')(result2, 'Honap_3_FizDatum').then(function (result3) {
                                $filter('dateStringFilterPromise')(result3, 'Honap_4_FizDatum').then(function (result4) {
                                    $filter('dateStringFilterPromise')(result4, 'Honap_5_FizDatum').then(function (result5) {
                                        $filter('dateStringFilterPromise')(result5, 'Honap_6_FizDatum').then(function (result6) {
                                            $filter('dateStringFilterPromise')(result6, 'Honap_7_FizDatum').then(function (result7) {
                                                $filter('dateStringFilterPromise')(result7, 'Honap_8_FizDatum').then(function (result8) {
                                                    $filter('dateStringFilterPromise')(result8, 'Honap_9_FizDatum').then(function (result9) {
                                                        $filter('dateStringFilterPromise')(result9, 'Honap_10_FizDatum').then(function (result10) {
                                                            $filter('dateStringFilterPromise')(result10, 'Honap_11_FizDatum').then(function (result11) {
                                                                $filter('dateStringFilterPromise')(result11, 'Honap_12_FizDatum').then(function (result12) {
                                                                    vm.data = result12;
                                                                    $timeout(function () {
                                                                        vm.AthleteUnderEditing = '';
                                                                        $scope.$apply();
                                                                        grid.dataSource = vm.data;
                                                                    }, 500);
                                                                });
                                                            });
                                                        });
                                                    });
                                                });
                                            });
                                        });
                                    });
                                });
                            });
                        });
                    });
                });
            });
        }

        // cellák színezése és utolsó két oszlopban az összesítés
        function querycellinfo(args) {
            var element = angular.element(args.cell);
            if (args.column.field.indexOf('Honap_') !== -1) {
                switch (args.data['Honap_' + args.column.field[6] + (args.column.field[7] && args.column.field[7] !== '_' ? args.column.field[7] : '') + '_ReadOnly']) {
                    case 'MT':
                        element.css('background-color', '#efdcdc');
                        break;
                    case 'O':
                        element.css('background-color', '#c0c0c0');
                        break;
                    case 'P':
                        element.css('background-color', '#CCC4FF');
                        break;
                }
            }
            if (args.column.field === 'osszesFizetendo') {
                args.data['osszesFizetendo'] = args.data['Honap_1_fizetendo'] + args.data['Honap_2_fizetendo'] + args.data['Honap_3_fizetendo'] + args.data['Honap_4_fizetendo'] + args.data['Honap_5_fizetendo'] + args.data['Honap_6_fizetendo'] + args.data['Honap_7_fizetendo'] + args.data['Honap_8_fizetendo'] + args.data['Honap_9_fizetendo'] + args.data['Honap_10_fizetendo'] + args.data['Honap_11_fizetendo'] + args.data['Honap_12_fizetendo']; //set the datasource value
                element.html(args.data['osszesFizetendo']); //set the element values
            }
            if (args.column.field === 'osszesFizetett') {
                args.data['osszesFizetett'] = (args.data['Honap_1'] !== 'null' ? args.data['Honap_1'] * 1 : 0) + (args.data['Honap_2'] !== 'null' ? args.data['Honap_2'] * 1 : 0) + (args.data['Honap_3'] !== 'null' ? args.data['Honap_3'] * 1 : 0) + (args.data['Honap_4'] !== 'null' ? args.data['Honap_4'] * 1 : 0) + (args.data['Honap_5'] !== 'null' ? args.data['Honap_5'] * 1 : 0) + (args.data['Honap_6'] !== 'null' ? args.data['Honap_6'] * 1 : 0) + (args.data['Honap_7'] !== 'null' ? args.data['Honap_7'] * 1 : 0) + (args.data['Honap_8'] !== 'null' ? args.data['Honap_8'] * 1 : 0) + (args.data['Honap_9'] !== 'null' ? args.data['Honap_9'] * 1 : 0) + (args.data['Honap_10'] !== 'null' ? args.data['Honap_10'] * 1 : 0) + (args.data['Honap_11'] !== 'null' ? args.data['Honap_11'] * 1 : 0) + (args.data['Honap_12'] !== 'null' ? args.data['Honap_12'] * 1 : 0);
                //set the datasource value
                element.html(args.data['osszesFizetett']); //set the element values
            }
        }

        // szerkesztés letiltása cellánként
        function cellEdit(args) {
            if (
                (args.rowData.Honap_1_kedvezmenyes_Text === 'B' || args.rowData.Honap_1_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_1' || args.columnName === 'Honap_1_kozlemeny' || args.columnName === 'Honap_1_FizDatum') ||
                (args.rowData.Honap_2_kedvezmenyes_Text === 'B' || args.rowData.Honap_2_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_2' || args.columnName === 'Honap_2_kozlemeny' || args.columnName === 'Honap_2_FizDatum') ||
                (args.rowData.Honap_3_kedvezmenyes_Text === 'B' || args.rowData.Honap_3_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_3' || args.columnName === 'Honap_3_kozlemeny' || args.columnName === 'Honap_3_FizDatum') ||
                (args.rowData.Honap_4_kedvezmenyes_Text === 'B' || args.rowData.Honap_4_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_4' || args.columnName === 'Honap_4_kozlemeny' || args.columnName === 'Honap_4_FizDatum') ||
                (args.rowData.Honap_5_kedvezmenyes_Text === 'B' || args.rowData.Honap_5_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_5' || args.columnName === 'Honap_5_kozlemeny' || args.columnName === 'Honap_5_FizDatum') ||
                (args.rowData.Honap_6_kedvezmenyes_Text === 'B' || args.rowData.Honap_6_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_6' || args.columnName === 'Honap_6_kozlemeny' || args.columnName === 'Honap_6_FizDatum') ||
                (args.rowData.Honap_7_kedvezmenyes_Text === 'B' || args.rowData.Honap_7_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_7' || args.columnName === 'Honap_7_kozlemeny' || args.columnName === 'Honap_7_FizDatum') ||
                (args.rowData.Honap_8_kedvezmenyes_Text === 'B' || args.rowData.Honap_8_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_8' || args.columnName === 'Honap_8_kozlemeny' || args.columnName === 'Honap_8_FizDatum') ||
                (args.rowData.Honap_9_kedvezmenyes_Text === 'B' || args.rowData.Honap_9_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_9' || args.columnName === 'Honap_9_kozlemeny' || args.columnName === 'Honap_9_FizDatum') ||
                (args.rowData.Honap_10_kedvezmenyes_Text === 'B' || args.rowData.Honap_10_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_10' || args.columnName === 'Honap_10_kozlemeny' || args.columnName === 'Honap_10_FizDatum') ||
                (args.rowData.Honap_11_kedvezmenyes_Text === 'B' || args.rowData.Honap_11_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_11' || args.columnName === 'Honap_11_kozlemeny' || args.columnName === 'Honap_11_FizDatum') ||
                (args.rowData.Honap_12_kedvezmenyes_Text === 'B' || args.rowData.Honap_12_kedvezmenyes_Text === 'EP') && (args.columnName === 'Honap_12' || args.columnName === 'Honap_12_kozlemeny' || args.columnName === 'Honap_12_FizDatum') ||

                args.columnName === 'Honap_1_kedvezmenyes_Text' && (args.rowData.Honap_1_ReadOnly !== 'A' || args.rowData.Tagdijas_1 === true) ||
                args.columnName === 'Honap_2_kedvezmenyes_Text' && (args.rowData.Honap_2_ReadOnly !== 'A' || args.rowData.Tagdijas_2 === true) ||
                args.columnName === 'Honap_3_kedvezmenyes_Text' && (args.rowData.Honap_3_ReadOnly !== 'A' || args.rowData.Tagdijas_3 === true) ||
                args.columnName === 'Honap_4_kedvezmenyes_Text' && (args.rowData.Honap_4_ReadOnly !== 'A' || args.rowData.Tagdijas_4 === true) ||
                args.columnName === 'Honap_5_kedvezmenyes_Text' && (args.rowData.Honap_5_ReadOnly !== 'A' || args.rowData.Tagdijas_5 === true) ||
                args.columnName === 'Honap_6_kedvezmenyes_Text' && (args.rowData.Honap_6_ReadOnly !== 'A' || args.rowData.Tagdijas_6 === true) ||
                args.columnName === 'Honap_7_kedvezmenyes_Text' && (args.rowData.Honap_7_ReadOnly !== 'A' || args.rowData.Tagdijas_7 === true) ||
                args.columnName === 'Honap_8_kedvezmenyes_Text' && (args.rowData.Honap_8_ReadOnly !== 'A' || args.rowData.Tagdijas_8 === true) ||
                args.columnName === 'Honap_9_kedvezmenyes_Text' && (args.rowData.Honap_9_ReadOnly !== 'A' || args.rowData.Tagdijas_9 === true) ||
                args.columnName === 'Honap_10_kedvezmenyes_Text' && (args.rowData.Honap_10_ReadOnly !== 'A' || args.rowData.Tagdijas_10 === true) ||
                args.columnName === 'Honap_11_kedvezmenyes_Text' && (args.rowData.Honap_11_ReadOnly !== 'A' || args.rowData.Tagdijas_11 === true) ||
                args.columnName === 'Honap_12_kedvezmenyes_Text' && (args.rowData.Honap_12_ReadOnly !== 'A' || args.rowData.Tagdijas_12 === true) ||

                // Kérésre nem szerkeszthető, ha nem nulla vagy null van benne
                args.columnName === 'Honap_1_kedvezmenyes_Text' && (args.rowData.Honap_1 !== 0 && args.rowData.Honap_1 !== null) ||
                args.columnName === 'Honap_2_kedvezmenyes_Text' && (args.rowData.Honap_2 !== 0 && args.rowData.Honap_2 !== null) ||
                args.columnName === 'Honap_3_kedvezmenyes_Text' && (args.rowData.Honap_3 !== 0 && args.rowData.Honap_3 !== null) ||
                args.columnName === 'Honap_4_kedvezmenyes_Text' && (args.rowData.Honap_4 !== 0 && args.rowData.Honap_4 !== null) ||
                args.columnName === 'Honap_5_kedvezmenyes_Text' && (args.rowData.Honap_5 !== 0 && args.rowData.Honap_5 !== null) ||
                args.columnName === 'Honap_6_kedvezmenyes_Text' && (args.rowData.Honap_6 !== 0 && args.rowData.Honap_6 !== null) ||
                args.columnName === 'Honap_7_kedvezmenyes_Text' && (args.rowData.Honap_7 !== 0 && args.rowData.Honap_7 !== null) ||
                args.columnName === 'Honap_8_kedvezmenyes_Text' && (args.rowData.Honap_8 !== 0 && args.rowData.Honap_8 !== null) ||
                args.columnName === 'Honap_9_kedvezmenyes_Text' && (args.rowData.Honap_9 !== 0 && args.rowData.Honap_9 !== null) ||
                args.columnName === 'Honap_10_kedvezmenyes_Text' && (args.rowData.Honap_10 !== 0 && args.rowData.Honap_10 !== null) ||
                args.columnName === 'Honap_11_kedvezmenyes_Text' && (args.rowData.Honap_11 !== 0 && args.rowData.Honap_11 !== null) ||
                args.columnName === 'Honap_12_kedvezmenyes_Text' && (args.rowData.Honap_12 !== 0 && args.rowData.Honap_12 !== null) ||

                args.columnName === 'Honap_1' && (args.rowData.Honap_1_ReadOnly !== 'A' || args.rowData.Tagdijas_1 === true) ||
                args.columnName === 'Honap_2' && (args.rowData.Honap_2_ReadOnly !== 'A' || args.rowData.Tagdijas_2 === true) ||
                args.columnName === 'Honap_3' && (args.rowData.Honap_3_ReadOnly !== 'A' || args.rowData.Tagdijas_3 === true) ||
                args.columnName === 'Honap_4' && (args.rowData.Honap_4_ReadOnly !== 'A' || args.rowData.Tagdijas_4 === true) ||
                args.columnName === 'Honap_5' && (args.rowData.Honap_5_ReadOnly !== 'A' || args.rowData.Tagdijas_5 === true) ||
                args.columnName === 'Honap_6' && (args.rowData.Honap_6_ReadOnly !== 'A' || args.rowData.Tagdijas_6 === true) ||
                args.columnName === 'Honap_7' && (args.rowData.Honap_7_ReadOnly !== 'A' || args.rowData.Tagdijas_7 === true) ||
                args.columnName === 'Honap_8' && (args.rowData.Honap_8_ReadOnly !== 'A' || args.rowData.Tagdijas_8 === true) ||
                args.columnName === 'Honap_9' && (args.rowData.Honap_9_ReadOnly !== 'A' || args.rowData.Tagdijas_9 === true) ||
                args.columnName === 'Honap_10' && (args.rowData.Honap_10_ReadOnly !== 'A' || args.rowData.Tagdijas_10 === true) ||
                args.columnName === 'Honap_11' && (args.rowData.Honap_11_ReadOnly !== 'A' || args.rowData.Tagdijas_11 === true) ||
                args.columnName === 'Honap_12' && (args.rowData.Honap_12_ReadOnly !== 'A' || args.rowData.Tagdijas_12 === true) ||

                args.columnName === 'Honap_1_kozlemeny' && (args.rowData.Honap_1_ReadOnly !== 'A' || args.rowData.Tagdijas_1 === true) ||
                args.columnName === 'Honap_2_kozlemeny' && (args.rowData.Honap_2_ReadOnly !== 'A' || args.rowData.Tagdijas_2 === true) ||
                args.columnName === 'Honap_3_kozlemeny' && (args.rowData.Honap_3_ReadOnly !== 'A' || args.rowData.Tagdijas_3 === true) ||
                args.columnName === 'Honap_4_kozlemeny' && (args.rowData.Honap_4_ReadOnly !== 'A' || args.rowData.Tagdijas_4 === true) ||
                args.columnName === 'Honap_5_kozlemeny' && (args.rowData.Honap_5_ReadOnly !== 'A' || args.rowData.Tagdijas_5 === true) ||
                args.columnName === 'Honap_6_kozlemeny' && (args.rowData.Honap_6_ReadOnly !== 'A' || args.rowData.Tagdijas_6 === true) ||
                args.columnName === 'Honap_7_kozlemeny' && (args.rowData.Honap_7_ReadOnly !== 'A' || args.rowData.Tagdijas_7 === true) ||
                args.columnName === 'Honap_8_kozlemeny' && (args.rowData.Honap_8_ReadOnly !== 'A' || args.rowData.Tagdijas_8 === true) ||
                args.columnName === 'Honap_9_kozlemeny' && (args.rowData.Honap_9_ReadOnly !== 'A' || args.rowData.Tagdijas_9 === true) ||
                args.columnName === 'Honap_10_kozlemeny' && (args.rowData.Honap_10_ReadOnly !== 'A' || args.rowData.Tagdijas_10 === true) ||
                args.columnName === 'Honap_11_kozlemeny' && (args.rowData.Honap_11_ReadOnly !== 'A' || args.rowData.Tagdijas_11 === true) ||
                args.columnName === 'Honap_12_kozlemeny' && (args.rowData.Honap_12_ReadOnly !== 'A' || args.rowData.Tagdijas_12 === true) ||

                args.columnName === 'Honap_1_FizDatum' && (args.rowData.Honap_1_ReadOnly !== 'A' || args.rowData.Tagdijas_1 === true) ||
                args.columnName === 'Honap_2_FizDatum' && (args.rowData.Honap_2_ReadOnly !== 'A' || args.rowData.Tagdijas_2 === true) ||
                args.columnName === 'Honap_3_FizDatum' && (args.rowData.Honap_3_ReadOnly !== 'A' || args.rowData.Tagdijas_3 === true) ||
                args.columnName === 'Honap_4_FizDatum' && (args.rowData.Honap_4_ReadOnly !== 'A' || args.rowData.Tagdijas_4 === true) ||
                args.columnName === 'Honap_5_FizDatum' && (args.rowData.Honap_5_ReadOnly !== 'A' || args.rowData.Tagdijas_5 === true) ||
                args.columnName === 'Honap_6_FizDatum' && (args.rowData.Honap_6_ReadOnly !== 'A' || args.rowData.Tagdijas_6 === true) ||
                args.columnName === 'Honap_7_FizDatum' && (args.rowData.Honap_7_ReadOnly !== 'A' || args.rowData.Tagdijas_7 === true) ||
                args.columnName === 'Honap_8_FizDatum' && (args.rowData.Honap_8_ReadOnly !== 'A' || args.rowData.Tagdijas_8 === true) ||
                args.columnName === 'Honap_9_FizDatum' && (args.rowData.Honap_9_ReadOnly !== 'A' || args.rowData.Tagdijas_9 === true) ||
                args.columnName === 'Honap_10_FizDatum' && (args.rowData.Honap_10_ReadOnly !== 'A' || args.rowData.Tagdijas_10 === true) ||
                args.columnName === 'Honap_11_FizDatum' && (args.rowData.Honap_11_ReadOnly !== 'A' || args.rowData.Tagdijas_11 === true) ||
                args.columnName === 'Honap_12_FizDatum' && (args.rowData.Honap_12_ReadOnly !== 'A' || args.rowData.Tagdijas_12 === true)
            ) {
                args.cancel = true;
            } else {
                $timeout(function () {
                    new ej2.data.DataManager(vm.data).executeQuery(new ej2.data.Query().where('Id', 'equal', args.rowData.Id)).then(function (result) {
                        vm.rowUnderEditing = result.result[0];
                    });
                    vm.AthleteUnderEditing = args.rowData.Versenyzo_Nev;
                    $scope.$apply();
                }, 0);
            }
        }

        function saveWithoutNumbers() {
            return save(false);
        }

        function saveWithNumbers() {
            return save(true);
        }

        function save(withNumbers) {
            if (vm.UJOKTDNYILV || vm.UJOKTDNYILV) {
                // batchSave();
                return dataservice.updateOktatasiDijfizetesek(vm.modifiedRows, withNumbers).then(function () {
                    log.successMsg('Saved');
                    vm.modifiedRows = [];
                    return true;
                });
            } else {
                log.permissionError();
            }
        }

        function batchSave() {
            grid.editModule.batchSave();
        }

        function goToDailyPayments() {
            paramHelper.setParams([{'dailyEducationalFeePayments.sectionId': vm.sectionId}, {'dailyEducationalFeePayments.coachId': vm.coachId}, {'dailyEducationalFeePayments.year': vm.year}]);
            saveWithoutNumbers().then(function () {
                $state.go('business.dailyEducationalFeePayments');
            });
        }

        function goToAthlete(id) {
            $q.all([authenticationService.getRight('MEGTSPORTLIST'), authenticationService.getRight('MEGTSPORTLIST')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'athleteModify.sportoloId': id}]);
                    $state.go('athletes.athletesManagement.athleteModify.dataSheet');
                } else {
                    log.permissionError();
                }
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function cellSave(args) {
            if (args.value != null && args.value !== vm.rowUnderEditing[args.columnName]) {
                $timeout(function () {
                    vm.modifiedRows.push(vm.rowUnderEditing);
                    $scope.$apply();
                }, 500);
            }
            vm.AthleteUnderEditing = '';
            new ej2.data.DataManager(vm.data).executeQuery(new ej2.data.Query().where('Id', 'equal', args.rowData.Id)).then(function (result) {
                result.result[0][args.columnName] = args.value;
                grid.refresh();
            });
        }
    }
})();
