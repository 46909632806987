(function () {
    'use strict';

    angular
        .module('app.athletes.athletesManagement.athleteModify.athletesSportSpecificTestList')
        .controller('AthletesSportSpecificTestListController', AthletesSportSpecificTestListController);

    function AthletesSportSpecificTestListController(log, dataservice, authenticationService, $q, $state, $scope, $rootScope, gettextCatalog, paramHelper, $filter) {
        var vm = this; $rootScope.vm = vm;

        vm.sectionId = 0;
        vm.ageGroupId = 0;
        vm.coachId = 0;
        vm.felmeroFejId = 0;
        vm.sportoloId = -1;
        vm.dateTo = new Date();
        vm.dateFrom = new Date(vm.dateTo.getFullYear() - 1, vm.dateTo.getMonth(), 1);

        // Grid's variables
        vm.pageSize = 20;

        vm.columns = [
            {
                field: 'Id',
                headerText: 'Id',
                textAlign: 'center',
                isPrimaryKey: true,
                visible: false
            },
            {
                field: 'FelmeroNeve',
                headerText: gettextCatalog.getString('TestName'),
                textAlign: 'center',
                allowEditing: false,
                width: 125
            },
            {
                field: 'SzakosztalyNev',
                headerText: gettextCatalog.getString('SectionName'),
                textAlign: 'center',
                allowEditing: false,
                width: 125
            }, {
                field: 'EdzoNev',
                headerText: gettextCatalog.getString('CoachName'),
                textAlign: 'center',
                allowEditing: false,
                width: 125,
                template: '#sstlCoach'
            }, {
                field: 'KorcsoportNev',
                headerText: gettextCatalog.getString('AgeGroup'),
                textAlign: 'center',
                allowEditing: false,
                width: 100
            }, {
                field: 'Edzoertekeles',
                headerText: gettextCatalog.getString('CoachRating'),
                textAlign: 'center',
                allowEditing: false,
                width: 150
            }, {
                field: 'Datum',
                headerText: gettextCatalog.getString('Datum'),
                textAlign: 'center',
                allowEditing: false,
                width: 100
            }, {
                headerText: '',
                textAlign: 'center',
                allowEditing: false,
                template: '#sstlOpen',
                width: 80
            }];

        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;
        vm.goToTest = goToTest;
        vm.goToUser = goToUser;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            authenticationService.getRight('MEGTSPFELM').then(function (results) {
                if (results !== true) {
                    log.permissionError(true);
                } else {
                    $q.all([
                        authenticationService.getRight('UJSPFELM'),
                        authenticationService.getRight('MODFELH'),
                        paramHelper.getParams(['athleteModify.sportoloId', 'athleteModify.sportoloNev'], [])
                    ]).then(function (results) {
                        vm.sportoloId = results[2]['athleteModify.sportoloId'];
                        vm.sportoloNev = results[2]['athleteModify.sportoloNev'];
                        $rootScope.title = vm.sportoloNev + ' - ' + gettextCatalog.getString('AthletesSportSpecificTestList');
                        felmeroFejLista();
                        vm.UJSPFELM = results[0];
                        vm.MODFELH = results[1];
                    });
                }
            });
        }

        function felmeroFejLista() {
            return dataservice.felmeroFejLista(0, 0, 0, 0, vm.sportoloId, vm.dateFrom, vm.dateTo)
                .then(function (data) {
                    data.itemsList = $filter('dateStringFilter')(data.itemsList, 'Datum');
                    grid.dataSource = data.itemsList;
                });
        }

        function dateFromChange(arg) {
            vm.dateFrom = arg.value;
            felmeroFejLista();
        }

        function dateToChange(arg) {
            vm.dateTo = arg.value;
            felmeroFejLista();
        }

        function goToTest(SzakosztalyId, KorcsoportId, EdzoId, FelmeroElemFejId, FelmeroFejId) {
            paramHelper.setParams(
                [
                    {'sportSpecificTest.sectionId': SzakosztalyId},
                    {'newSportSpecificTest.sectionId': SzakosztalyId},
                    {'sportSpecificTest.ageGroupId': KorcsoportId},
                    {'sportSpecificTest.coachId': EdzoId},
                    {'sportSpecificTest.felmeroElemFejId': FelmeroElemFejId},
                    {'sportSpecificTest.felmeroFejId': FelmeroFejId},
                    {'sportSpecificTest.highlight': vm.sportoloId}
                ]);

            $state.go('administration.sportSpecificTest.newSportSpecificTest');
        }

        function goToUser(EdzoId) {
            if (!vm.MODFELH) {
                log.errorMsg(gettextCatalog.getString('YouHaveNoPermissionToThisFunction'));
                return;
            }
            paramHelper.setParams([{'newUser.userId': EdzoId}]);
            $state.go('settings.userManagement.modifyUser');
        }
    }
})();
