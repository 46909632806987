(function () {
    'use strict';

    angular
        .module('app.reports.managementReports.trainingDiaryReports')
        .controller('TrainingDiaryReportsController', TrainingDiaryReportsController);

    function TrainingDiaryReportsController(log, dataservice, $q, $rootScope, gettextCatalog, authenticationService, paramHelper, $scope, $filter, $state) {
        var vm = this; $rootScope.vm = vm;
        $rootScope.title = gettextCatalog.getString('TrainingDiaryReports');

        vm.promises = [];
        vm.coachList = [];
        vm.sectionList = [];
        vm.ageGroupList = [];
        vm.dateToValue = new Date();
        vm.dateFromValue = new Date(new Date().setMonth(new Date().getMonth() - 3));

        vm.columns = [
            {field: 'Link', headerText: '', template: '#tdropentd', textAlign: 'center', width: 60},
            {field: 'SzakosztalyNeve', headerText: gettextCatalog.getString('Section'), width: 150, textAlign: 'center'},
            {field: 'KorosztalyNeve', headerText: gettextCatalog.getString('AgeGroup'), width: 100, textAlign: 'center'},
            {field: 'EdzoNeve', headerText: gettextCatalog.getString('Coach'), width: 150, template: '#tdrcoach', textAlign: 'center'},
            {field: 'Datum', headerText: gettextCatalog.getString('Date'), width: 125, textAlign: 'center'},
            {field: 'EdzesNapokSzama', headerText: gettextCatalog.getString('NumberOfTrainingDays'), width: 125, textAlign: 'center'},
            {field: 'Edzo_Alairta', type: 'boolean', headerText: gettextCatalog.getString('CoachSigned'), textAlign: 'center', width: 125, displayAsCheckBox: true},
            {field: 'Szakosztalyvezeto_Alairta', type: 'boolean', headerText: gettextCatalog.getString('SectionLeaderSigned'), textAlign: 'center', width: 150, displayAsCheckBox: true}
        ];

        vm.sectionSelect = sectionSelect;
        vm.sectionDataBound = sectionDataBound;
        vm.coachDataBound = coachDataBound;
        vm.ageGroupSelect = ageGroupSelect;
        vm.ageGroupDataBound = ageGroupDataBound;
        vm.search = search;
        vm.openTrainingDiary = openTrainingDiary;
        vm.openCoach = openCoach;
        vm.coachSelect = coachSelect;
        vm.ageGroupId = -1;
        vm.sectionId = -1;
        vm.coachId = -1;
        vm.dateFromChange = dateFromChange;
        vm.dateToChange = dateToChange;

        var grid = new ej2.grids.Grid({
            dataSource: [],
            toolbar: $rootScope.toolbarItems,
            allowPaging: true,
            allowTextWrap: true,
            allowSorting: true,
            allowExcelExport: true,
            locale: 'hu-HU',
            gridLines: 'Both',
            pageSettings: $rootScope.pageSettings,
            columns: vm.columns,
            beforePrint: $rootScope.beforePrintGrid,
            printComplete: $rootScope.printCompleteGrid,
            rowDataBound: function (args) {
                $rootScope.compile(args.row, $scope);
            },
            actionComplete: function (args) {
                if (args.requestType === 'paging' || args.requestType === 'refresh') {
                    $rootScope.compile($('#Grid'), $scope);
                }
            }
        });
        grid.appendTo('#Grid');
        grid.toolbarClick = function (args) {
            $rootScope.toolbarClick(args, grid);
        };

        activate();

        function activate() {
            $q.all([
                authenticationService.getRight('VEZETOIHJ'),
                authenticationService.getRight('MEGTHAVIJEL'),
                paramHelper.getParams([], [
                    'trainingDiaryReports.dateFromValue',
                    'trainingDiaryReports.dateToValue',
                    'trainingDiaryReports.sectionId',
                    'trainingDiaryReports.coachId',
                    'trainingDiaryReports.ageGroupId'])]).then(function (results) {
                        if (results[0] !== true) {
                            log.permissionError(true);
                        } else {
                            vm.MEGTHAVIJEL = results[1];
                            vm.promises = results[2];
                            if (vm.promises['trainingDiaryReports.dateFromValue']) {
                                vm.dateFromValue = vm.promises['trainingDiaryReports.dateFromValue'];
                            }
                            if (vm.promises['weeklyTrainingPlans.dateToValue']) {
                                vm.dateToValue = vm.promises['trainingDiaryReports.dateToValue'];
                            }

                            getSections();
                            setDropDowns();
                        }
                    });
        }

        function setDropDowns() {
            var promises = [paramHelper.getParams([], [
                'trainingDiaryReports.dateFromValue',
                'trainingDiaryReports.dateToValue',
                'trainingDiaryReports.sectionId',
                'trainingDiaryReports.coachId',
                'trainingDiaryReports.ageGroupId'
            ])];
            $q.all(promises).then(function (results) {
                vm.sectionId = results[0]['trainingDiaryReports.sectionId'];
                vm.ageGroupId = results[0]['trainingDiaryReports.ageGroupId'];
                vm.coachId = results[0]['trainingDiaryReports.coachId'];
                vm.dateFromValue = results[0]['trainingDiaryReports.dateFromValue'];
                vm.dateToValue = results[0]['trainingDiaryReports.dateToValue'];
            });
        }

        function ageGroupDataBound() {
            if (vm.ageGroupList.length === 1) {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupList[0].value);
            } else {
                angular.element('#ageGroupDropDown').ejDropDownList('selectItemByValue', vm.ageGroupId);
            }
        }

        function ageGroupSelect(args) {
            vm.ageGroupId = args.value;
        }

        function getAgeGroups(coachId) {
            dataservice.ageGroupSelectBetweenDates(coachId, vm.sectionId, vm.dateFromValue, vm.dateToValue).then(function (data) {
                vm.ageGroupList = data.itemsList;
                vm.ageGroupList.unshift({text: gettextCatalog.getString('All'), value: -1});
            });
        }

        function openCoach(edzoId) {
            $q.all([authenticationService.getRight('MODFELH'), authenticationService.getRight('MEGTFELH')]).then(function (results) {
                if (results[0] || results[1]) {
                    paramHelper.setParams([{'newUser.userId': edzoId}]);
                    $state.go('settings.userManagement.modifyUser');
                } else {
                    log.permissionError();
                }
            });
        }

        function getSections() {
            return dataservice.sectionDropDownList(0, true).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.sectionList = data.itemsList;
            });
        }

        function sectionDataBound() {
            if (vm.sectionList.length === 1) {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionList[0].value);
            } else {
                angular.element('#sectionDropDown').ejDropDownList('selectItemByValue', vm.sectionId);
            }
        }

        function sectionSelect(args) {
            getActualCoachesList(args.value);
            getAgeGroups(0);
        }

        function getActualCoachesList(sectionId) {
            return dataservice.coachDropDownList(sectionId, 0, vm.dateFromValue, vm.dateToValue).then(function (data) {
                if (data.itemsList.length !== 1) {
                    data.itemsList.unshift({text: gettextCatalog.getString('All'), value: -1});
                }
                vm.coachList = data.itemsList;
            });
        }

        function coachDataBound() {
            if (vm.coachList.length === 1) {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachList[0].value);
            } else {
                angular.element('#coachDropDown').ejDropDownList('selectItemByValue', vm.coachId);
                if (vm.coachId) {
                    getAgeGroups(0);
                }
            }
        }

        function coachSelect(args) {
            getAgeGroups(args.value);
        }

        function search() {
            $scope.$broadcast('submitted');
            if ($scope.form.$valid === false) {
                log.errorMsg(gettextCatalog.getString('RequiredFieldsError'));
                return;
            }
            dataservice.getTrainingDiaryReports(vm.dateFromValue, vm.dateToValue, vm.sectionId, vm.coachId, vm.ageGroupId).then(function (data) {
                grid.dataSource = $filter('dateStringFilter')(data.itemsList, 'Datum');
            });

            paramHelper.setParams(
                [
                    {'trainingDiaryReports.dateFromValue': vm.dateFromValue},
                    {'trainingDiaryReports.dateToValue': vm.dateToValue},
                    {'trainingDiaryReports.sectionId': vm.sectionId},
                    {'trainingDiaryReports.coachId': vm.coachId},
                    {'trainingDiaryReports.ageGroupId': vm.ageGroupId},
                ]);
        }

        function openTrainingDiary(attendanceId) {
            if (!vm.MEGTHAVIJEL) {
                log.permissionError();
                return;
            }
            paramHelper.setParams([{'HaviJelenletiFejId': attendanceId}]);
            $state.go('administration.trainingDiary');
        }

        function dateFromChange(args) {
            vm.dateFromValue = args.value;
            getActualCoachesList(vm.sectionId);
        }

        function dateToChange(args) {
            vm.dateToValue = args.value;
            getActualCoachesList(vm.sectionId);
        }
    }
})();
